/**
 * Button
 */

// Custom
import 'protons';

// Module template
import './_button.twig';

// Import custom sass, includes Bootstrap sass
import './_button.scss';

export const name = 'button';

export function disable() {}

export function enable() {}

export default enable;
