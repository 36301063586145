/**
 * product-gallery
 */

import $ from 'jquery';
import 'slick-carousel';

// Module dependencies
import 'protons';

// Module styles
import './_product-gallery.scss';

// Module template
import './_product-gallery.twig';

export const name = 'product-gallery';

export const defaults = {
  dummyClass: 'js-product-gallery-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable(context) {
  const $productGallery = $('.product-gallery', context);
  const $header = $('.header', context);

  // Bail if our component doesn't exist
  if (!$productGallery.length) {
    return;
  }

  // Hides/shows modal
  const toggleDisplay = ($el, isOpen = false) => {
    switch (isOpen) {
      case true:
        $el.addClass('modal-open');
        $('body', context).addClass('js-modal-is-open');
        break;
      default:
        $el.removeClass('modal-open');
        $('body', context).removeClass('js-modal-is-open');
        break;
    }
  };

  const initModal = ($modal) => {
    const $modalCarousel = $modal.find('.product-gallery__modal');
    $modal.on('click', '.product-gallery__main .trigger-open', (e) => {
      // Get active slide index
      const currentIndex = $(e.currentTarget)
        .closest('.slick-slide')
        .data('slick-index');

      toggleDisplay($modal, true);
      // Set position once modal is open
      $productGallery.find('.slick-initialized').slick('setPosition');
      // Modal carousel to go to active slide index
      $modalCarousel
        .find('.carousel__nav')
        .slick('getSlick')
        .slickGoTo(currentIndex)
        .slick('slickSetOption', {
          slidesToShow: 15,
          infinite: false,
        });
    });

    $modal.on('click', '.trigger-close', () => {
      // Stop youtube video from playing when the modal is closed
      if ($modalCarousel.find('iframe').length) {
        $modalCarousel
          .find('iframe')[0]
          .contentWindow.postMessage(
            JSON.stringify({ event: 'command', func: 'stopVideo' }),
            '*'
          );
      }
      toggleDisplay($modal, false);
    });
  };
  $(window).on('click', (e) => {
    if ($(e.target).is('.overlay')) {
      toggleDisplay($productGallery, false);
    }
  });

  $(document).on('click', '.zoomContainer', function () {
    $(
      '.carousel__main .slick-slide.slick-current.slick-active .trigger-open'
    ).trigger('click');
  });
  $productGallery.each((i, el) => {
    initModal($(el));
  });

  //  Add the headerHeight to the gallery for sticky
  $(window)
    .on('resize', () => {
      $('.product-gallery__main').css({
        top: $header.outerHeight(),
      });
    })
    .trigger('resize');
}
export default enable;
