import {h} from 'preact';

export function QuantitySelector({t, product, selectQuantityHandler}) {
  return (
    <div class="purchase-block__dropdown">
      <div class="form-item">
        <div class="row row--quantity-selector">
          <label
            for="select"
            class="form-item__label form-item__label--textfield"
          >
            {product.list_quantity_label
              ? product.list_quantity_label
              : t('Quantity')}:
          </label>
          <div class="form-item__dropdown">
            <select class="form-item__select"
                    aria-label={product.list_quantity_label ? product.list_quantity_label : t('Quantity')}
                    onChange={selectQuantityHandler}>
              {product.options.map((qty: any) => (
                <option value={qty} selected={product.selectedQuantity === qty}>
                  {qty}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
