import { h, Fragment } from 'preact';

export function ProductInfo({ t, product }) {
  const brand = (window as any).drupalSettings.site.brand;
  const eldon_check = (window as any).drupalSettings.eldon;

  return (
    <Fragment>
      {product.noPricing ? (
        <Fragment>
          {(brand === 'schroff')
            && (
            <div class="row row--catalog">
              <span>
                {product.catalog_label ? product.catalog_label : t('Catalog#:')}
              </span>
              <span class="catalog-id">{product.id}</span>
            </div>
          )}
          <div class="row row--no-price">
            <span>{t('Pricing and Leadtime available upon request.')}</span>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {(brand === 'hoffman' || brand === 'schroff')
            && (
            <div class="row row--catalog">
              <span>
                {product.catalog_label ? product.catalog_label : t('Catalog#:')}
              </span>
              <span class="catalog-id">{product.id}</span>
            </div>
          )}
          {product.showPricing && product.user && brand == 'hoffman' && eldon_check.sku && eldon_check.show_prices
            && (
              <div class="row row--price-hoffman">
                <span>
                  {product.price_label ? product.price_label : t('Price per unit:')}
                </span>
                <span class="price-per-unit">
                  {product.price.title}
                </span>
              </div>
          )}
          {brand == 'schroff' && (
            <div class="row row--price">
              <h5>
                {product.price_label ? product.price_label : t('Your price:')}
              </h5>
              <span class="link link--alt">
                {product.price.title}
              </span>
            </div>
          )}
          {brand == 'schroff' && (
            <div class="row row--list-price">
              <h5>
                {product.list_price_label
                  ? product.list_price_label
                  : t('List price (RRP):')}
              </h5>
              <div>
                <div class="rrp-list text-align-right">{product.list_price}</div>
              </div>
              <a href="#footnote" class="w-full text-align-right"><span>{t('Price per piece, excluding VAT')}<span class="no-style">{t('*')}</span></span></a>
            </div>
          )}
          {product.alert && (
            <p class="purchase-block__alert-message">
              {product.alert}
            </p>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
