/**
 * accordion-item
 */

// Module dependencies
import 'protons';

// Module styles
import './_accordion-item.scss';

// Module template
import './_accordion-item.twig';

export const name = 'accordion-item';

export const defaults = {
  dummyClass: 'js-accordion-item-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  // 'use strict';

  const accordionTerm = document.querySelectorAll('.accordion-term', $context);
  const accordionDef = document.querySelectorAll('.accordion-def', $context);

  // If javascript, hide accordion definition on load
  function jsCheck() {
    for (let i = 0; i < accordionDef.length; i += 1) {
      const closeOnLoad = ![
        ...accordionDef[0].previousElementSibling.classList,
      ].includes('closed-on-load');
      if (accordionDef[i].classList) {
        accordionDef[i].classList.add('active');
        if (closeOnLoad) {
          accordionDef[0].previousElementSibling.classList.add('is-active');
        }
      } else {
        accordionDef[i].className += ' active';
        if (closeOnLoad) {
          accordionDef[0].previousElementSibling.classList.add('is-active');
        }
      }
    }
  }

  jsCheck();

  // Accordion Toggle
  // Mobile Click Menu Transition
  for (let i = 0; i < accordionTerm.length; i += 1) {
    accordionTerm[i].addEventListener('click', function accordionToggle(e) {
      const className = 'is-active';
      // Add is-active class
      if (this.classList) {
        this.classList.toggle(className);
      } else {
        const classes = this.className.split(' ');
        const existingIndex = classes.indexOf(className);

        if (existingIndex >= 0) {
          classes.splice(existingIndex, 1);
        } else {
          classes.push(className);
        }
        this.className = classes.join(' ');
      }
      e.preventDefault();
    });
  }
}

export default enable;
