/**
 * contact-block
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_contact-block.scss';

// Module template
import './_contact-block.twig';

export const name = 'contact-block';

export const defaults = {
  dummyClass: 'js-contact-block-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context, { contactBlock = {} }) {
  // Find our component within the DOM
  const $contactBlock = $('.contact-block', $context);

  // Bail if component does not exist
  if (!$contactBlock.length) {
    return;
  }

  // Merge defaults with incoming settings
  const settings = {
    ...defaults,
    ...contactBlock,
  };

  // An example of what could be done with this component
  $contactBlock.addClass(settings.dummyClass);

  // Hides/shows modal
  const toggleDisplay = ($el, isOpen = false) => {
    $el.toggleClass('modal-open', isOpen);
    $('body', $context).toggleClass('js-modal-is-open', isOpen);
  };

  const initModal = ($modal) => {
    $modal.on('click', '.contact-block__main .trigger-open', () => {
      toggleDisplay($modal, true);
    });
    $modal.on('click', '.trigger-close', () => {
      toggleDisplay($modal, false);
    });
  };

  $(window).on('click', (e) => {
    if ($(e.target).is('.overlay')) {
      toggleDisplay($contactBlock, false);
    }
  });

  initModal($contactBlock);
}

export default enable;
