import { h } from 'preact';

export function ComponentsTable({ t, productList, product }) {
  return (
    <div class="components-table">
      <p class="product-components__description">
        {t(
          `Below is a list of components for @product. Your custom configuration, @product, can be modified or cloned using the buttons below.`,
          { '@product': product.id }
        )}
      </p>
      <p className="product-components__description">
        {t(
          `The CLONE button will allow you to create a new configuration based on the components listed below. You can add or remove components, and save it as a new configuration to your Cart.`
        )}
      </p>
      <p className="product-components__description">
        {t(
          `The MODIFY button will allow you to add or remove components and to save the updated configuration back to your Cart.`
        )}
      </p>
      <div class="components-table__row-container components-table__row-container--border">
        <div class="components-table__header components-table__fixed--25">
          {t('catalog #')}
        </div>
        <div class="components-table__header components-table__flex-column">
          {t('item name')}
        </div>
        <div class="components-table__header components-table__fixed--16">
          {t('quantity')}
        </div>
      </div>

      {productList.map((item) => (
        <div class="components-table__row-container">
          <div class="components-table__cell components-table__fixed--25">
            <div class="components-table__label"> {t('catalog #')}</div>
            <div class="components-table__data">
              <a
                href={item.skuUrl}
                target="_blank"
                class="components-table__link"
              >
                {item.itemNumber}
              </a>
            </div>
          </div>
          <div class="components-table__cell components-table__flex-column">
            <div class="components-table__label"> {t('item name')}</div>
            <div class="components-table__data">{item.itemDescription}</div>
          </div>
          <div class="components-table__cell components-table__fixed--16">
            <div class="components-table__label"> {t('quantity')}</div>
            <div class="components-table__data components-table__cell--bold">
              {item.quantity}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
