import { RxJsModelBase } from '../shared/RxJsModelBase';
import { IRxjsModule } from '../shared/Interface/IRxjsModule';
import { CommerceModule } from './CommerceModule';
import './scss/configurators.scss';

export class ConfiguratorsModule extends RxJsModelBase implements IRxjsModule {
  /**
   * Static Instance
   */
  private static instance: ConfiguratorsModule;

  /**
   * Sites
   */
  private sites: Array<string> = [
    'varistar',
    'novastar',
    'front-panel',
    'subrack',
    'varistar-cp',
  ];

  /**
   * Private constructor to enforce Singleton
   */
  private constructor() {
    super();
  }

  /**
   * Singleton model
   */
  static getInstance(): ConfiguratorsModule {
    if (!ConfiguratorsModule.instance) {
      ConfiguratorsModule.instance = new ConfiguratorsModule();
    }
    return ConfiguratorsModule.instance;
  }

  /**
   * Controls iframe scaling
   * @param originalViewport: meta viewport
   */
  scaleIframeViewport(originalViewport: string) {
    const $ = (window as any).jQuery;
    const viewport = document.querySelector(
      'meta[name=viewport]'
    ) as HTMLMetaElement;
    if (viewport) {
      if ($('body').width() < 1025) {
        viewport.setAttribute('content', 'width=689, user-scalable=1');
      } else {
        viewport.content = originalViewport;
      }
    }
  }
  /**
   * Setting namespace for drupal behavior
   */
  getNamespace(): string {
    return 'nventConfigurators';
  }

  /**
   * Get configuration predefined name
   */
  getConfiguratorName(): string {
    let site = this.getKeyFromUrl();
    if (!!site) {
      if (['varistar', 'novastar'].includes(site)) {
        site += '-cabinet';
      }
      const words = site.split('-');
      const res = words.map((word: string) => {
        return word[0].toUpperCase() + word.slice(1);
      });
      return res.join(' ');
    }
    return '';
  }

  /**
   * Get site key from url
   */
  getKeyFromUrl(): string {
    const path: string = window.location.pathname;
    const regex = /.*\/configurators\/3d\/(.*)\/(new|update)/gm;
    const subst = `$1`;
    const site = path.replace(regex, subst);
    return this.sites.includes(site) ? site : '';
  }

  /**
   * Get configuration predefined iamge
   */
  getConfiguratorImage(): string {
    const site = this.getKeyFromUrl();
    if (!!site) {
      return `${window.location.origin}/themes/custom/particle/apps/drupal/assets/images/250x250/${site}.jpg`;
    }
    return '';
  }

  /**
   *  Configurators Reducer
   */
  reducer(data) {
    if (data.key) {
      switch (data.key) {
        case 'CONFIGURATOR_ADD':
          ConfiguratorsModule.getInstance().addConfiguratorProductToCart(data);
          break;
        case 'CONFIGURATOR_UPDATE':
          ConfiguratorsModule.getInstance().addConfiguratorProductToCart(
            data,
            true
          );
          break;
        case 'productAdded()':
          // Redirect after product message dissapears
          setTimeout(() => {
            const region_manager = (window as any).drupalSettings
              .region_manager;
            const lang =
              region_manager.current_language +
              '-' +
              region_manager.current_country;
            window.location.href = `${window.location.origin}/${lang}/customer/cart`;
          }, 4000);
          break;
      }
    }
  }

  /**
   * Add to cart using message delegation.
   * @param data
   * @param overrideProductData
   */
  addConfiguratorProductToCart(data, overrideProductData = false) {
    const region_manager = (window as any).drupalSettings.region_manager;
    const lang =
      region_manager.current_language + '-' + region_manager.current_country;
    const configKey = this.getKeyFromUrl();
    const bomData = data.bom.Bom;
    let components = Array.isArray(bomData.item)
      ? bomData.item
      : [bomData.item];
    components = components.map((part) => {
      return {
        itemNumber: part.partNumber,
        itemDescription: part.partDescription,
        skuUrl: `${window.location.origin}/${lang}/p/ENC_${part.partNumber}`,
        itemImage: `${window.location.origin}/p/ENC_${part.partNumber}/i`,
        quantity: part.quantity,
      };
    });

    const sfUserID = CommerceModule.getInstance().getUser();
    const configuratorPayload = {
      sfUserID: sfUserID,
      configId: bomData.configurationID,
      sapNumber: bomData.sapGUID,
    };
    this.addConfiguratorMapping(configuratorPayload);

    const payload = {
      id: bomData.sapGUID,
      name: this.getConfiguratorName(),
      image: this.getConfiguratorImage(),
      region: '*',
      qty: 1,
      components,
      configuratorCopyURL: `/configurators/3d/${configKey}/new?configId=${bomData.configurationID}`,
      configuratorModifyURL: `/configurators/3d/${configKey}/update?sapNumber=${bomData.sapGUID}&configId=${bomData.configurationID}`,
      configurationID: bomData.configurationID,
      zipURL: bomData.zipUrl,
      overrideExistingItem: overrideProductData,
      itemUrl: '',
      sapGUID: bomData.sapGUID,
      isWedgeLok: false,
    };
    CommerceModule.getInstance().dispatch('addProductToCart()', payload, this);
  }
  /**
   * Return the CSS selctor of subscribers
   */
  getSubscribersSelector() {
    return '';
  }

  /**
   * Function to add data to dynamic product.
   */
  addConfiguratorMapping(values: Object) {
    let data = {};
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/configurator-mapping/insert',
         data: {
          sf_uid: values.sfUserID,
          config_id: values.configId,
          sap_id: values.sapNumber
        },
        dataType: 'json',
        timeout: 30000,
      })
        .done(function (data) {
          if (values.sfUserID === null || values.sfUserID === undefined) {
            localStorage.setItem('configuration_id', data.last_insert_id);
          }
          resolve(data);
        })
        .fail(function (xhr) {
          reject(xhr);
        });
    });
  }

  /**
   * statusMessage handler
   * @param ev
   */
  messageProcessor(e: MessageEvent) {
    // Only listen to the URL we requested in the iframe.
    const origin = (window as any).drupalSettings.nvent_configurators
      .kim_base_url;
    if (e.origin !== origin) {
      return;
    }
    // The message to be rebroadcast.
    let message: any = {};
    try {
      message.key = 'CONFIGURATOR_' + e.data.action;
      if (e.data.bom) {
        const xml = ConfiguratorsModule.getInstance().parseXml(e.data.bom);
        if (xml) {
          message.bom = ConfiguratorsModule.getInstance().xmlToJson(xml);
        }
      }
      message.configId = e.data.configId;
      message.custom = e.data.custom;
    } catch (e) {
      console.error(e);
    }
    // Reducer connect
    ConfiguratorsModule.getInstance().reducer(message);
  }
  /**
   * Behavior implmentation
   * @param context Drupal behavior context
   * @param settings Drupal Settings
   */
  behavior(context, settings) {
    const $ = (window as any).jQuery;
    const config = settings.nvent_configurators;
    const $elem = $(context.querySelector('.kim-configurator-iframe'));
    const region_manager = (window as any).drupalSettings.region_manager;
    const lang =
      region_manager.current_language + '-' + region_manager.current_country;
    // Make sure it only happens once (as is an ID is unique)
    if (context == document && $elem.length) {
      // Make Configurator module a listener of commerce module
      CommerceModule.getInstance()
        .getOutputObservable()
        .subscribe(this.reducer);

      // Make config values(partNumber,sapNumber,configId) from URL as source of truth while loading the configurator iframe.
      const params = new URLSearchParams(window.location.search);
      const part_number = params.has('partNumber') ? params.get('partNumber') : null;
      const sap_number = params.has('sapNumber') ? params.get('sapNumber') : null;
      const config_id = params.has('configId') ? params.get('configId') : null;

      // Ajax request to get the iframe scr, then load it on the page.
      $elem.addClass('kim-configurator-iframe--loading');
      $.ajax({
        url:
          `/${lang}/api/3d/` +
          config.type +
          '/' +
          config.action +
          window.location.search,
        method: 'GET',
        data: {
          partNumber: part_number,
          sapNumber: sap_number,
          configId: config_id,
        },
      })
        .done((data) => {
          const viewport: HTMLMetaElement | null = document.querySelector(
            'meta[name=viewport]'
          );
          if (viewport) {
            const originalViewport = viewport.content;
            this.scaleIframeViewport(originalViewport);
            const ifrm = document.createElement('iframe');
            ifrm.setAttribute('src', data.iframe);
            ifrm.setAttribute('allowFullScreen', '');
            $elem.prepend(ifrm);
            // Add a resizing listener.
            $(window).resize(() => {
              this.scaleIframeViewport(originalViewport);
            });
            // Once is loaded the overlay needs to be removed.
            window.addEventListener('load', function loadLIstener() {
              $elem.removeClass('kim-configurator-iframe--loading');
              window.removeEventListener('load', loadLIstener);
            });
            // Add 25 seconds automatic dissappearance
            setTimeout(() => {
              $elem.removeClass('kim-configurator-iframe--loading');
            }, 25000);
          }
        })
        .fail(function (xhr) {
          $elem.removeClass('kim-configurator-iframe--loading');
          CommerceModule.getInstance().dispatch('showAlertMessage()', {
            message: `The configurator can't be loaded right now. Please try again later.`,
            type: 'error',
          });
          console.error(xhr);
        });
      // Add a listener for message events.
      if (window.addEventListener) {
        window.addEventListener('message', this.messageProcessor, false);
      } else if ((window as any).attachEvent) {
        (window as any).attachEvent('onmessage', this.messageProcessor);
      }
    }
  }

  parseXml(xml: string) {
    var dom = null;
    if (window.DOMParser) {
      try {
        dom = new DOMParser().parseFromString(xml, 'application/xml');
      } catch (e) {
        dom = null;
      }
    } else window.console.log('Error: Cannot parse XML string!');
    return dom;
  }

  xmlToJson(xml) {
    // Create the return json object.
    let json = {};

    if (xml.nodeType == 1) {
      // Element.
      // Do attributes.
      if (xml.attributes.length > 0) {
        for (let j = 0; j < xml.attributes.length; j++) {
          const attribute = xml.attributes.item(j);
          json[attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType == 3) {
      // Text.
      const str = xml.nodeValue.trim();
      if (str.length) return str;
    } else if (xml.nodeType == 4) {
      // CDATA Section.
      return xml.data;
    }

    // Do children.
    if (
      xml.hasChildNodes() &&
      xml.childNodes.length === 1 &&
      xml.childNodes.item(0).nodeName === '#text'
    ) {
      json = xml.childNodes.item(0).nodeValue.trim();
    } else if (xml.hasChildNodes()) {
      for (let i = 0, n = xml.childNodes.length; i < n; i++) {
        const item = xml.childNodes.item(i);
        let nodeName = item.nodeName;
        const value = this.xmlToJson(item);

        if (typeof json[nodeName] === 'undefined') {
          // Unique key.
          if (Object.keys(value).length) json[nodeName] = value;
        } else {
          // Non-unique keys.
          if (Object.keys(value).length === 0) continue;
          if (typeof json[nodeName].push === 'undefined')
            json[nodeName] = [json[nodeName], value];
          else json[nodeName].push(value);
        }
      }
    }
    return json;
  }
}
