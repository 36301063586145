import { RxJsWebComponentBase } from '../../shared/RxJsWebComponentBase';
import { IRxjsWebComponent } from '../../shared/Interface/IRxjsWebComponent';

export default class PriceBlockComponent extends RxJsWebComponentBase
  implements IRxjsWebComponent {
  /**
   * Subscription handler
   */
  private subscription;

  /**
   * Total cart items
   */
  private items: number = 0;

  private getNode(items): Element {
    let cartNumber = document.createElement('span');
    if (items > 99) {
      items = '99+';
    }
    if (items == 0) {
      return cartNumber;
    } else {
      cartNumber.classList.add('cart-nav__count');
      cartNumber.innerHTML = '' + items;
      return cartNumber;
    }
  }

  /**
   * App initializing method
   * @param props Web Component properties
   */
  _initApp(props) {
    const service = props.root.getPropertiesService();
    this.subscription = service.subscribe(({ key, value }) => {
      switch (key) {
        case 'cartInfoOfflineUpdate()':
          let count = 0;
          for(const brand in value.cart) {
            count += Object.keys(value.cart[brand]).length;
          }
          this.items = count;
          this.querySelector('.cart-nav span')?.remove();
          this.querySelector('.cart-nav')?.append(this.getNode(this.items));
          break;
        case 'cartInfoOnlineUpdate()':
          this.items = parseInt(value.count)
            ? value.count
            : '--';
          this.querySelector('.cart-nav span')?.remove();
          this.querySelector('.cart-nav')?.append(this.getNode(this.items));
          break;
      }
    });
    this.querySelector('.cart-nav')?.append(this.getNode(0));
  }

  /**
   * Remove subscription on element dissapearance
   */
  disconnectCallback() {
    this.subscription.unsubscribe();
  }
}
