/**
 * main-menu
 */

/* eslint-disable */

import $ from 'jquery';
//import 'sliding-menu';

// Module dependencies
import 'protons';

// Module styles
import './_main-menu.scss';
import './_main-menu-item.scss';
import './_main-menu-link.scss';
import './_main-menu-toggle.scss';

// Module template
import './_main-menu.twig';

export const name = 'main-menu';

export const defaults = {
  dummyClass: 'js-main-menu-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  if(!window.scriptHasRun) {
    window.scriptHasRun = true;
    let toggle_expand = $('#toggle-expand', $context);

    let menu = document.getElementById('main-nav');

    var mobileMenuu = $('.mobile-nav_menu');
    var mobileFirstStage = $('ul.mobile_navigation--level-1', mobileMenuu);
    var mobileFirstLink = $('> li > a', mobileFirstStage);
    var mobileSecondStage = $('ul.mobile_navigation--level-2', mobileMenuu);
    var mobileSecondLink = $('> li > a', mobileSecondStage);
    var mobileThirdStage = $('ul.mobile_navigation--level-3', mobileMenuu);
    var mobileThirdLink = $('> li > a', mobileThirdStage);
    var mobileFourthStage = $('ul.mobile_navigation--level-4', mobileMenuu);
    var mobileFourthLink = $('> li > a', mobileFourthStage);

    // Set submenu Height
    var screenHeight = screen.height;
    var screenWidth = screen.width;
    $('.mobile_navigation__sub-menu').css('height', screenHeight);

    // Mobile Menu Show/Hide.
    toggle_expand.on('click', function (e) {
      e.stopPropagation();
      e.preventDefault();
      toggle_expand.toggleClass('toggle-expand--open');
      toggle_expand.parent('.toggle-bar').toggleClass('toggle-bar--open');
      $('#main-nav', $context).toggleClass('main-nav--open');
      document.body.classList.toggle('js-scroll-lock');
      document.body.classList.toggle('js-hide-main');
      document.body.classList.toggle('js-mobile-nav-open');
      $('#toggle-expand--search', $context).toggleClass('hide-mobile-search');
      $('#block-particle-coveo-standalone-search-box').removeClass('is-open');
      if(!$('body').hasClass('js-mobile-nav-open')) {
        const $header = $('.header');
        const $mainContent = $('.main--anon');
        $mainContent.css({
          'padding-top': $header.outerHeight(),
        });
      }
    });

    function toggleSubList(e) {
      let el = e.currentTarget;
      let menu_item = el.parentElement.parentElement;
      let sub_menu = el.parentElement.nextElementSibling;

      menu_item.classList.toggle('main-menu__item--open');
      sub_menu.classList.toggle('main-menu--sub-open');
    }

    // Expose mobile sub menu on click.
    let submenu_toggles = $('#main-nav', $context).find('.js-menu-toggle');
    for (let i = 0; i < submenu_toggles.length; i++) {
      submenu_toggles[i].addEventListener('click', toggleSubList);
    }
    $(window).bind('resize', function () {
      if ($(window).width() > 919) {
        $('body').removeClass('js-scroll-lock js-mobile-nav-open js-hide-main');
      }
      else {
        if ($('.main-nav--open').length > 0) {
          $('body').addClass('js-scroll-lock js-mobile-nav-open js-hide-main');
        }
      }
      const event = new Event('afterHeaderResize');
      window.dispatchEvent(event);
    });

    // Expose header search form on click
    const toggle_expand_search = $('.toggle-expand--search');
    toggle_expand_search.on('click', (e) => {
      e.stopPropagation();
      e.preventDefault();
      $('.site-search__form', $context).toggleClass('is-open');
    });

    // Append mobile menu to product menu
    $('.menu-mobile-brand > .main-menu').find('.mobile_navigation__item--first').addClass('brand-menu');
    if ($('.menu-mobile-products').length > 0) {
      $('.menu-mobile-products').append($('.menu-mobile-brand > .main-menu'));
    } else {
      $('.mega-menu nav').css('display', 'block');
    }

    $('.mobile_navigation--level-1 > li > a').each(function () {
      var $this = $(this);
      var backButton = $('<div class="back-button"><a class="back2parent">Back to Main Menu</a></div>');
      backButton.insertBefore($this.next('ul.mobile_navigation--level-2').find('.mobile_navigation__item--level-2.mobile_navigation__item--first'));
    });

    $('.mobile_navigation--level-2 > li > a').each(function () {
      var $this = $(this);
      var backButton = $('<div class="back-button"><a class="back2parent">Back to </a></div>');
      backButton.insertBefore($this.next('ul.mobile_navigation--level-3').find('.mobile_navigation__item--level-3.mobile_navigation__item--first'));
    });

    $('.mobile_navigation--level-2 > li > a').each(function () {
      const $this = $(this);
      if ($this.attr('href').length !== 0) {
        const text = ` ${$this.data('overview')}`;
        $('<li class="nav_l3_overview">')
          .append($this.clone().append(text))
          .insertBefore(
            $this
              .next('ul.mobile_navigation--level-3')
              .find('.mobile_navigation__item--level-3.mobile_navigation__item--first')
          );
      }
    });

    $('.mobile_navigation--level-1 > li > a').each(function () {
      const $this = $(this);
      if ($this.attr('href').length !== 0) {
        const text = ` ${$this.data('overview')}`;
        $('<li class="nav_l2_overview">')
          .append($this.clone().append(text))
          .insertBefore(
            $this
              .next('ul.mobile_navigation--level-2')
              .find('.mobile_navigation__item--level-2.mobile_navigation__item--first')
          );
      }
      let l1menu = $this.text();
      let appendToback = $this.siblings().children().find('.mobile_navigation__sub-menu.mobile_navigation--level-3 > .back-button > a');
      appendToback.append(l1menu);
    });

    $('ul.mobile_navigation__sub-menu').find('.back-button').append('<div class="mobile-navigation__close"><a role="button" href="#" aria-label="Close"></a></div>');

    // Code to drive Main menu Parent items click.
    mobileFirstLink.click(function (e) {
      var elm = $(this);
      var parent = elm.parent();

      // Menu lavel 1 overflow changes.
      $('.header').addClass('disable-overflow-strict');

      if (parent.hasClass('mobile_navigation__item--has-children')) {
        e.preventDefault();
      }

      if (parent.siblings().find('> a').hasClass('is-clicked')) {
        parent.siblings().find('> a').removeClass('is-clicked');
      }

      if (elm.hasClass('is-clicked')) {
        elm.removeClass('is-clicked');
        elm.next('.mobile_navigation--level-2').removeClass('menu-enabled');
        elm.attr('aria-expanded','false');
      }
      else {

        if (parent.siblings().find('> ul').hasClass('menu-enabled')) {
          parent.siblings().find('> ul').removeClass('menu-enabled');
        }
        if (mobileSecondStage.hasClass('menu-enabled')) {
          mobileSecondStage.removeClass('menu-enabled');
          mobileThirdStage.removeClass('menu-enabled');
        }
        if (mobileFirstLink.hasClass('is-clicked')) {
          mobileFirstLink.removeClass('is-clicked');
        }
        elm.addClass('is-clicked');
        elm.next('.mobile_navigation--level-2').addClass('menu-enabled');
        elm.next('.mobile_navigation--level-2').addClass('default-overflow-mega-menu');
        elm.attr('aria-expanded','true');
      }
    });

    mobileSecondLink.click(function (e) {
      var elm = $(this);
      var parent = elm.parent();


      mobileSecondStage.removeClass('default-overflow-mega-menu');
      mobileSecondStage.addClass('disable-overflow');


      if (parent.hasClass('mobile_navigation__item--has-children')) {
        e.preventDefault();
      }
      if (parent.siblings().find('> a').hasClass('is-clicked')) {
        parent.siblings().find('> a').removeClass('is-clicked');
      }

      if (elm.hasClass('is-clicked')) {
        elm.removeClass('is-clicked');
        elm.next('.mobile_navigation--level-3').removeClass('menu-enabled');
        elm.attr('aria-expanded','false');
      }
      else {

        if (parent.siblings().find('> ul').hasClass('menu-enabled')) {
          parent.siblings().find('> ul').removeClass('menu-enabled');
        }
        if (mobileThirdStage.hasClass('menu-enabled')) {
          mobileThirdStage.removeClass('menu-enabled');
          mobileFourthStage.removeClass('menu-enabled');
        }
        if (mobileSecondLink.hasClass('is-clicked')) {
          mobileSecondLink.removeClass('is-clicked');
        }
        elm.addClass('is-clicked');
        elm.next('.mobile_navigation--level-3').addClass('menu-enabled');
        elm.next('.mobile_navigation--level-3').addClass('default-overflow-mega-menu');
        elm.attr('aria-expanded','true');
      }
    });

    $('.back2parent').click(function (e) {
      var elm = $(this);
      var parent = elm.parent().parent();

      if (parent.hasClass('mobile_navigation--level-2')) {
        $('.header').removeClass('disable-overflow-strict');
        //$('.header').addClass('default-overflow-mega-menu');
      }

      if (parent.hasClass('mobile_navigation--level-3')) {
        mobileSecondStage.removeClass('disable-overflow');
        mobileSecondStage.addClass('default-overflow-mega-menu');
      }

      let subUl = $(this).closest('.mobile_navigation__sub-menu');

      subUl.addClass("back-clicked").delay(100).queue(function () {
        subUl.removeClass('menu-enabled');
        $('.mobile_navigation__link').removeClass('is-clicked');
        $(this).removeClass("back-clicked").dequeue();
      });

      e.stopPropagation();
    });

    // Function to Reset Menu.
    function closeDesktopMenu() {
      $('.mobile_navigation__sub-menu').css('visibility', 'hidden');
      $('.mobile-navigation__close').parent().parent().removeClass('menu-enabled');
      $('.mobile-navigation__close').parent().parent().parent().find('> a').removeClass('is-clicked');
      $('.header').removeClass('disable-overflow-strict');
      mobileSecondStage.removeClass('disable-overflow');
      $('.toggle-expand__close').click();
      mobileFirstLink.attr('aria-expanded','false');
    }

    // Reset Menu on Close button click.
    $('.mobile-navigation__close').click(function () {
      closeDesktopMenu();
    });


  }
}

export default enable;
