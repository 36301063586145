/**
 * footer
 */

// Module dependencies
import 'protons';

// Module template
import './brand-footer/_brand-footer.twig';

// Module styles
import './brand-footer/_brand-footer.scss';
import $ from 'jquery';

export const name = 'footer';

export function disable() {}

/**
 * Enables this component.
 *
 * @param {jQuery} $context - A piece of DOM
 */

// Use for toggle only for mobile view
export function enable() {
  const mdBreakpoint = '720';
  if (window.innerWidth <= mdBreakpoint) {
    $('.company-more').click(function () {
      $(this).toggleClass('upside');
      $(this).parent().siblings('div').slideToggle();
    });
  }
}

export default enable;
