/**
 * product-feature-benefits
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_product-feature-benefits.scss';

// Module template
import './_product-feature-benefits.twig';

export const name = 'product-feature-benefits';

export const defaults = {
  dummyClass: 'js-product-feature-benefits-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context, { productFeatureBenefits = {} }) {
  // Find our component within the DOM
  const $productFeatureBenefits = $('.feature-benefits__bullets', $context);
  // Bail if component does not exist
  if (!$productFeatureBenefits.length) {
    return;
  }

  // Set the character limit
  const characterLimit = 500;

  const listItems = $('.content-list li');
  let currentCharacterCount = 0;

  $.each(listItems, function (i, listItem) {
    // Avoid ellipsis for last Li
    if (i === listItems.length - 1) {
      return false;
    }

    const $item = $(listItem);
    const itemCharacterCount = $item.text().length;

    if (currentCharacterCount + itemCharacterCount > characterLimit) {
      const remainingCharacters = characterLimit - currentCharacterCount;
      const truncatedContent = $item.text().substring(0, remainingCharacters);
      const remainingContent = $item.text().substring(remainingCharacters);

      const remainingSpan = $('<span>')
        .addClass('remaining-characters')
        .text(remainingContent)
        .hide();

      $item
        .html(`${truncatedContent}<span class="ellipsis">...</span>`)
        .append(remainingSpan);

      remainingSpan.hide();

      for (let j = i + 1; j < listItems.length; j++) {
        $(listItems[j]).addClass('hide').hide();
      }

      return false; // Break out of the loop
    }
    currentCharacterCount += itemCharacterCount;
  });

  // Toggle hide/show on "Read more" click
  const readMoreLink = $('.feature-benefits-bullets__read-more');
  readMoreLink.find('span.read-less').hide();
  let isExpanded = false;

  readMoreLink.on('click', function (event) {
    event.preventDefault();
    const ellipsisSpan = $('.ellipsis');
    const remainingSpan = $('.remaining-characters');
    const hiddenListItems = $('.content-list li.hide');

    if (!isExpanded) {
      hiddenListItems.show();
      remainingSpan.show();
      readMoreLink.find('span.read-less').show();
      readMoreLink.find('span.read-more').hide();
      ellipsisSpan.hide();
      readMoreLink.addClass('less');
    } else {
      hiddenListItems.hide();
      remainingSpan.hide();
      readMoreLink.find('span.read-less').hide();
      readMoreLink.find('span.read-more').show();
      ellipsisSpan.show();
      readMoreLink.removeClass('less');
    }
    isExpanded = !isExpanded;
  });

  const hasHiddenListItems = $('.content-list li.hide').length > 0;
  const hasRemainingCharacters =
    $('.content-list li span.remaining-characters').length > 0;

  // Hide the "Read More" button if there are no list items with the "hide" class
  if (!hasHiddenListItems && !hasRemainingCharacters) {
    readMoreLink.hide();
  }

  // Merge defaults with incoming settings
  const settings = {
    ...defaults,
    ...productFeatureBenefits,
  };
  // An example of what could be done with this component
  $productFeatureBenefits.addClass(settings.dummyClass);
}

export default enable;
