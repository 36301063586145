/**
 * comparison-table
 */

/* eslint-disable */

// Module dependencies
import 'protons';

// Module styles
import './_comparison-table.scss';

// Module template
import './_comparison-table.twig';

export const name = 'comparison-table';

export const defaults = {
  dummyClass: 'js-comparison-table-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  const rows = document.getElementsByClassName('js-comparison-table-highlight', $context);
  const activeClass = 'comparison-table__row--highlight';

  for (let i = 0; i < rows.length; i += 1) {
    rows[i].addEventListener('mouseenter', function onHoverEnter() {
      this.classList.toggle(activeClass);
    });
    rows[i].addEventListener('mouseleave', function onHoverLeave() {
      this.classList.toggle(activeClass);
    });
  }

  function tableResize() {
    // Adjust row size
    const containers = document.getElementsByClassName(
      'comparison-table-top'
    );
    for (let i = 0; i < containers.length; i += 1) {
      const container = containers[i];
      let rows;
      let stickyRows;

      for (let j = 0; j < container.children.length; j += 1) {
        const table = container.children[j];
        if (table.classList.contains('comparison-table--sticky')) {
          stickyRows = table.rows;
        } else if (table.classList.contains('comparison-table')) {
          rows = table.rows;
        }
      }

      for (let rowI = 0; rowI < rows.length; rowI += 1) {
        let height = rows[rowI].clientHeight;
        window.addEventListener('load', () => {
          if (navigator.userAgent.indexOf('Chrome') !== -1) {
            if (rowI === 0) {
              height += 6;
            }
          } else if (navigator.userAgent.indexOf('Safari') !== -1) {
            if (rowI === 0) {
              height -= 16;
            }
          }
        });
        stickyRows[rowI].style.height = `${height}px`;
      }

      // Set up horizontal scroll watch.
      container.addEventListener('scroll', function watchHorizontalScroll() {
        this.children[1].style.left = `${this.scrollLeft}px`;
      });
    }
  }

  let debounceTimer;

  function debounceTableResize() {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    debounceTimer = setTimeout(tableResize(), 0);
  }
  window.addEventListener('resize', debounceTableResize);

  window.addEventListener('load', () => {
    tableResize();
  });

  // double scroll on solution card
  $(function () {
    $('.comparison-top-scroll').scroll(function () {
      $('.comparison-table-top').scrollLeft($('.comparison-top-scroll').scrollLeft());
    });
    $('.comparison-table-top').scroll(function () {
      $('.comparison-top-scroll').scrollLeft($('.comparison-table-top').scrollLeft());
    });
  });

  // Click on scroll left or right
  $('.scroll-right', $context).click(function (e) {
    const tablediv = $(this)
      .closest('.header-container')
      .siblings('.comparison-table__container')
      .children('.comparison-table-top');
    e.preventDefault();
    tablediv.animate(
      {
        scrollLeft: '+=150px',
      }
    );
  });

  $('.scroll-left', $context).click(function (e) {
    const tablediv = $(this)
      .closest('.header-container')
      .siblings('.comparison-table__container')
      .children('.comparison-table-top');
    e.preventDefault();
    tablediv.animate(
      {
        scrollLeft: '-=150px',
      }
    );
  });
}

export default enable;
