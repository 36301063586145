import { h } from 'preact';

export function AddToRequest({t, product, AddToRequestHandler }) {
  return (
    <div class="purchase-block__add-request">
      <a
        class={product.ready == false ? 'button button--secondary  disabled' : 'button button--secondary enabled'}
        tabIndex={-1}
        onClick={AddToRequestHandler}
      >
        {t('Request Sample')}
      </a>
      <p class={'purchase-block__add-request--anon-user'}>
        {!product.user &&
          t("*You need to login to order a sample product")
        }
      </p>
    </div>
  );
}
