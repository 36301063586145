/**
 * Assets
 */

import $ from 'jquery';
import 'slick-carousel';
import 'slick-lightbox';
import 'jquery-once';

// Module styles
import './_assets.scss';

// Module template
import './_assets.twig';

export const name = 'assets';

export function enable($context) {
  // Find .asset-block__list elements on the page
  const $assetBlock = $('.asset-block__list', $context);

  $assetBlock.once('asset-slickLightbox').slickLightbox();
}

export default enable;
