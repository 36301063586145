/**
 * tabs block
 */

/* eslint-disable */
import $ from 'jquery';

import './_tabs-block.scss';
import './_tabs-block.twig';

export const name = 'tabs-block';

export const defaults = {
  dummyClass: 'js-tabs-exists',
};

export function disable() {
}

export function enable($context, {tabs = {}}) {
  const mdBreakpoint = '720';
  const $tabs = $('.tabs-block', $context);
  const $tabBlockContent = $('.tabs-block__tab__contents', $context);
  const $accordionTrigger = $('.tabs-block__tab__accordion-header', $context);
  const $tabContent = $tabBlockContent.find('.tabs-block__tab__content', $context);
  const noContentMsg = 'No %token% available';
  const isLogged = !!$('.toolbar-fixed').length > 0;
  const $header = $('.header', $context);
  const $toolbar = $('.toolbar-bar', $context);
  const topSpacing = isLogged ? $toolbar.outerHeight() : $header.outerHeight();
  let tabsOpened = [];

  // Evaluate if any tab is empty to set the message accordingly
  $tabContent.each(function () {
    if ( $(this).find('.tab-content__content').length == 0) {
      const id =  $(this).attr('id');
      let message = noContentMsg.replace('%token%', $('a.' + id).text());
      message = '<h3 class="tabs-block__tab__content-no">' + message + '</h3>';
      $(this).append(message);
    }
  });

  const tabsBlockEnable = () => {
    // Bail if component does not exist
    if (!$tabs.length) {
      return;
    }

    if (window.innerWidth <= mdBreakpoint) {

      // What to do when click on a Accordion title
      function openAccordion($el) {
        const dataTab = $el.attr('data-tab');
        if (!tabsOpened.includes(dataTab)) {
          tabsOpened.push(dataTab);
        }
        else {
          tabsOpened = tabsOpened.filter(x => x !== dataTab);
        }

        const $accordionContent = $el.next();
        if ($el.hasClass('active')) {
          $accordionContent.slideUp();
        }
        else {
          $accordionTrigger.each(function () {
            if ($(this).hasClass('active')) {
              $(this).removeClass('active');
              $(this).next().slideUp();
            }
          });

          // Improves timing for animations
          setTimeout(() => {
            scrollToAccordion($el);
            setTimeout(() => {
              $accordionContent.slideDown();
            }, 400);
          }, 400);
        }
        $el.toggleClass('active');
      }

      // Scrolls to the accordion when it opens
      function scrollToAccordion($el) {
        $('html, body').animate(
          {
            scrollTop: $el.offset().top - topSpacing,
          }
        );
      }

      $accordionTrigger.off("click");
      $accordionTrigger.off("keypress");
      $accordionTrigger.on('click', function (e) {
        e.preventDefault();
        openAccordion($(this));
      });
      $accordionTrigger.on('keypress', function (e) {
        e.preventDefault();
        openAccordion($(this));
      });

    }
    else {
      // Set the constants and variables
      const $tabTrigger = $('.tablink', $context);
      const $tabTitles = $('.tabs-block__tab__titles', $context);

      // What to do when click on a tab
      function openTab(el) {
        const [, id] = el.href.split('#');
        tabsOpened = [id];
        $tabTrigger.each(function () {
          if ($(this).hasClass('active')) {
            $(this).removeClass('active').addClass('disable');
            $tabBlockContent.find('.tabs-block__tab__content.active').attr('style', null).removeClass('active').addClass('disable');
          }
        });
        $('.tabs-block__tab__titles').find('.' + id).addClass('active').removeClass('disable');
        $tabBlockContent.find('#' + id).addClass('active').removeClass('disable');

        // We need overflow: visible on the tab's container for the table header
        // to be sticky, but it needs to be applied after it's done expanding.
        const $detailsTab = $('#Details.tabs-block__tab__content.active');

        if ($detailsTab.length > 0) {
          const currentTab = document.getElementById(id);
          currentTab.ontransitionend = (event) => {
            if ((event.propertyName === 'max-height') && ($('#Details.tabs-block__tab__content.active').length > 0)) {
              $detailsTab.css({
                overflow: 'visible',
              })
            }
          }
        }

        return false;
      }

      $accordionTrigger.removeClass("active");
      $accordionTrigger.next().attr('style', null);
      $tabTrigger.off('click');
      $tabTrigger.off('keypress');

      const $videos = [...$('.video iframe')];
      $tabTrigger.on('click', function (e) {
        e.preventDefault();
        openTab(this);
        $videos.forEach((video) =>{
          video.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
        })
      });
      $tabTrigger.on('keypress', function (e) {
        e.preventDefault();
        openTab(this);
        $videos.forEach((video) => {
          video.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
        })
      });

      // open the first tab as default behavior
      if (tabsOpened.length === 0 &&  !window.location.hash) {
        tabsOpened = [$accordionTrigger.first().attr("data-tab")];
      }
      $(`.tablink[href="#${tabsOpened[tabsOpened.length - 1]}"]`).trigger("click");

      // Set position for sticky tabs
      function stickyTabs() {
        $tabTitles.css({
          'top': topSpacing,
        });
      }

      let debounceTimer;

      function debounceSticky() {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(stickyTabs, 250);
      }

      $(window).on('resize', debounceSticky);

      // Call stickyTabs once on first load
      stickyTabs();
    }
    // Merge defaults with incoming settings
    const settings = {
      ...defaults,
      ...tabs,
    };
    $tabs.addClass(settings.dummyClass);
  }

  tabsBlockEnable();
  $(window).on("resize", () => {
    tabsBlockEnable();
  });
}

export default enable;
