/**
 * image
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_image.scss';

// Module template
import './_image.twig';
import './_image--figure.twig';
import './_image--picture.twig';
import './_image--placeholder.twig';
import './_image--responsive.twig';

import './nVent_Logo.png';
import './blueprint.webp';
import './cert-e30.png';
import './cert-e60.png';
import './cert-e90.png';
import './cert-ul.png';
import './icon-chevron.png';
import './logo.svg';
import './nVent-featured-news.jpg';
import './nVent-RAYCHEM-Logo-RGB-Horizontal.png';
import './nVent-RAYCHEM-Logo-RGB-Horizontal.svg';
import './product.jpg';
import './product-placeholder.png';
import './resource-library-bg.png';
import './touch-icon.png';
import './ajax-loader.gif';
import './bars-solid.svg';
import './close.svg';
import './datatables_sort_asc.png';
import './datatables_sort_desc.png';
import './icon-flag.png';
import './industry.png';
import './logo192.png';
import './long-right-arrow-red.png';
import './commercial-icon/commercial-icon.png';
import './commercial-icon/commercial-icon@2x.png';
import './commercial-icon/commercial-icon@3x.png';
import './industrial-icon/industrial-icon.png';
import './industrial-icon/industrial-icon@2x.png';
import './industrial-icon/industrial-icon@3x.png';
import './residential-icon/residential-icon.png';
import './residential-icon/residential-icon@2x.png';
import './residential-icon/residential-icon@3x.png';
import './raychem-logo/nvent-raychem-sidebyside-logo.png';
import './raychem-logo/nvent-raychem-sidebyside-logo@2x.png';
import './raychem-logo/nvent-raychem-sidebyside-logo@3x.png';
import './logos/logo-nvent.svg';
import './logos/logo-caddy.svg';
import './logos/logo-lenton.svg';
import './logos/logo-erico.svg';
import './logos/logo-eriflex.svg';
import './logos/logo-efs.svg';
import './logos/logo-hoffman.svg';
import './logos/logo-schroff.svg';
import './logos/logo-raychem.svg';
import './logos/logo-tracer.svg';
import './logos/logo-pyrotenax.svg';
import './logos/logo-thermal.svg';
import './logos/logo-data-solutions.svg';

export const name = 'image';

export const defaults = {
  dummyClass: 'js-image-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context, { image = {} }) {
  // Find our component within the DOM
  const $image = $('.image', $context);
  // Bail if component does not exist
  if (!$image.length) {
    return;
  }
  // Merge defaults with incoming settings
  const settings = Object.assign(defaults, image);
  // An example of what could be done with this component
  $image.addClass(settings.dummyClass);
}

export default enable;
