import { h, Fragment } from 'preact';

export function SkuShippingInfo({ product }) {
  const eldon_check = (window as any).drupalSettings.eldon;

  return (
    <Fragment>
      {product.showDetails && (
        <Fragment>
          {product.deliveryPlan && eldon_check.show_stock && (
            <Fragment>
              <div class="availability-info">
                {(window as any).Drupal.t('Availability:')}
                {product.deliveryPlan.map(plan => (
                  <div class="row row--availability-info">
                    <span>{plan.ProposedQuantity}</span>
                    <span>{plan.ConfirmedDeliveryDate}</span>
                  </div>
                ))}
              </div>
            </Fragment>
          )}
          {product.totalPrice && eldon_check.show_prices && (
            <Fragment>
              <div class="row row--total-price">
                <span>{(window as any).Drupal.t('Total Price:')}</span>
                <span>{product.totalPrice}</span>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
      {product.notice && (
        <Fragment>
          <div class="hoffmansku-notice-msg">{(window as any).Drupal.t(product.notice)}</div>
        </Fragment>
      )}
    </Fragment>
  );
}
