import { h } from 'preact';
import { useRef } from 'preact/hooks';
import { CommerceModule} from "../../../../models/CommerceModule";

export function CartItem({
  root,
  product,
  t,
  index,
  componentsToggle,
  loadingStr,
}) {
  const apiPricingDisabled = CommerceModule.getInstance().isApiPricingDisabled();
  const dropdown = useRef<HTMLDivElement>();

  function toggleMenu() {
    (dropdown.current as any).classList.toggle('show');
  }

  function openProductComponentsMenuHandler(event) {
    openProductComponents(event);
    toggleMenu();
  }

  function openProductComponents(event) {
    event.preventDefault();
    componentsToggle(index);
  }

  // Select Quantity
  function selectQuantity(event) {
    const option = event.target.value;
    const payload = { id: product.id, quantity: option, index };
    root.dispatchOutputEvent('updateCartProduct()', payload);
  }

  // Remove Item
  function removeCartItem() {
    (dropdown.current as any).classList.toggle('show');
    root.dispatchOutputEvent('removeCartProduct()', index);
  }

  var dynamicText = {
    "cartLabels": {
      "image": "Image",
      "cnum": "Catalog #",
      "item": "Item",
      "lPrice": "List Price",
      "yPrice": "Your Price",
      "quant": "Qty",
      "act": "Actions",
      "sub": "Sub-Total",
      "oDropdown": "Open actions dropdown",
      "sComponents": "Show Components",
      'delete': "Delete",
    }
  };

  return (
    <div class="div-table__row div-table__body">
      <div class="div-table__cell td--image">
        <div class="th th--mobile">{dynamicText.cartLabels.image}</div>
        <img src={product.image} alt={product.name} />
      </div>
      <div class="div-table__cell td--catalog">
        <div class="th th--mobile">{dynamicText.cartLabels.cnum}</div>
        <div>
          {product.components.length > 0 ? (
            <span
              class="commerce-cart__product-id"
            >
              {product.id}
            </span>
          ) : (
            <a
              href={product.url}
              class="link link--tertiary js-link-exists commerce-cart__product-id"
            >
              {product.id}
            </a>
          )}
        </div>
      </div>
      <div class="div-table__cell td--item">
        <div class="th th--mobile">{dynamicText.cartLabels.item}</div>
        <div>
          <span class="link link--tertiary js-link-exists commerce-cart__product-name">
            {product.name}
          </span>
          {!apiPricingDisabled && (
            <span class="commerce-cart__product-availability">
              {product.noPricing && product.availability != loadingStr
                ? '--'
                : product.availability}
            </span>
          )}
        </div>
      </div>
      {!apiPricingDisabled && (
      <div className="div-table__cell td--list-price">
        <div className="th th--mobile">{dynamicText.cartLabels.lPrice}</div>
        {product.noPricing ? '--' : product.listPrice}
      </div>
      )}
      {!apiPricingDisabled && (
        <div class="div-table__cell td--your-price">
          <div class="th th--mobile">{dynamicText.cartLabels.yPrice}</div>
          {product.noPricing ? '--' : product.yourPrice}
        </div>
      )}
      <div class="div-table__cell td--quantity">
        <div class="th th--mobile">{dynamicText.cartLabels.quant}</div>
        <div class="form-item">
          <div class="form-item__dropdown">
            <select onChange={selectQuantity}>
              {product.options.map((qty) => (
                <option value={qty} selected={product.qty == qty}>
                  {qty}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div class="div-table__cell td--actions">
        <div class="th th--mobile">{dynamicText.cartLabels.act}</div>
        <div class="button-dropdown">
          <button
            class="button-dropdown__toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={toggleMenu}
          >
            <span class="sr-only">{dynamicText.cartLabels.oDropdown}</span>
          </button>
          <div
            ref={dropdown}
            class="button-dropdown__menu"
            aria-label="Actions"
          >
            {product.components.length > 0 && (
              <a
                class="button-dropdown__menu-item"
                role="button"
                onClick={openProductComponentsMenuHandler}
              >
                {dynamicText.cartLabels.sComponents}
              </a>
            )}
            <a
              class="button-dropdown__menu-item"
              role="button"
              onClick={removeCartItem}
            >
              {dynamicText.cartLabels.delete}
            </a>
          </div>
        </div>
      </div>
      {!apiPricingDisabled && (
        <div class="div-table__cell td--sub-total">
          <div class="th th--mobile">{dynamicText.cartLabels.sub}</div>
          {product.yourPrice == '--'
            ? '--'
            : !product.noPricing
            ? product.netValues.currency +
              (product.netValues.yourPrice * product.qty).toFixed(2)
            : '--'}
        </div>
      )}
    </div>
  );
}
