/**
 * Product Detail Tile mode
 */

import './_product-detail-tile.twig';

export const name = 'product-detail-tile';

export function disable() {}

/**
 * Enables this component.
 */
export function enable() {}

export default enable;
