/**
 * site-nav
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_site-nav.scss';

// Module template
import './_nav-tabs.twig';
import './_nested.twig';
import './_site-nav.twig';
import './nvent-raychem-sidebyside-logo.png';

export const name = 'site-nav';

export const defaults = {
  dummyClass: 'js-site-nav-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} context - A piece of DOM
 */
export function enable(context) {
  function overlayEnable() {
    $('#overlay').fadeIn();
  }

  function overlayDisable() {
    $('#overlay').fadeOut();
  }

  // Function to Reset Menu.
  function closeDesktopMenu(firstLink, secondLink) {
    $('.site_navigation__sub-menu').css('visibility', 'hidden');
    $('.site-navigation__close').parent().removeClass('menu-enabled');
    $('.site-navigation__close')
      .parent()
      .next('.dropdown-container')
      .find('.menu-enabled')
      .removeClass('menu-enabled');
    $('.site-navigation__close')
      .parent()
      .parent()
      .find('> a')
      .removeClass('is-clicked');
    overlayDisable();
    firstLink.attr('aria-expanded', 'false');
    secondLink.attr('aria-expanded', 'false');
  }

  if (!window.desktopScriptHasRun) {
    window.desktopScriptHasRun = true;
    let $mainContent = $('.main--anon', context);
    const $header = $('.header', context);
    const isLoggedin = !!$('.toolbar-fixed').length;
    let subtractedHeight;
    if (!isLoggedin && $('.main--auth', context).length > 0) {
      $mainContent = $('.main--auth', context);
    }

    const menu = $('.site-nav_menu');
    const firstStage = $('ul.site_navigation--level-1', menu);
    const firstLink = $('> li > a', firstStage);
    const secondStage = $('ul.site_navigation--level-2', menu);
    const secondLink = $('> li > a', secondStage);
    const thirdStage = $('ul.site_navigation--level-3', menu);
    const langParam = $('html').attr('lang');
    $(window)
      .on('resize', () => {
        $mainContent.css({
          'padding-top': $header.outerHeight(),
        });
        subtractedHeight = $header.outerHeight() + (isLoggedin ? 90 : 0);
      })
      .trigger('resize');

    $('.site_navigation__item--level-1', context)
      .on('mouseenter', function () {
        $(this)
          .find('.site_navigation__sub-menu')
          .css({
            'max-height': $(window).height() - subtractedHeight - 40,
          });
      })
      .on('mouseleave', function () {
        $(this).find('.site-nav__wrapper').css({
          'max-height': 0,
        });
      })
      .on('click', '.site-navigation__collapse', (e) => {
        $(e.delegateTarget).find('.site-nav__wrapper').css({
          'max-height': 0,
        });
      });

    $('li.site_navigation__item--level-1').removeClass(
      'site_navigation__item--first site_navigation__item--last'
    );
    $('li.site_navigation__item.site_navigation__item--has-children > a').attr(
      'aria-expanded',
      'false'
    );

    $('.site_navigation--level-2 > li > a').each(function () {
      const $this = $(this);
      if ($this.attr('href')) {
        const text = ` ${$this.data('overview')}`;
        $('<li class="nav_l3_overview">')
          .append($this.clone().append(text))
          .insertBefore(
            $this
              .next('ul.site_navigation--level-3')
              .find(
                '.site_navigation__item--level-3.site_navigation__item--first'
              )
          );
      }
    });

    $('.site_navigation--level-1 > li > a').each(function () {
      const $this = $(this);
      if ($this.attr('href')) {
        const text = ` ${$this.data('overview')}`;
        $('<li class="nav_l2_overview">')
          .append($this.clone().append(text))
          .insertBefore(
            $this
              .next('ul.site_navigation--level-2')
              .find(
                '.site_navigation__item--level-2.site_navigation__item--first'
              )
          );
      }
    });

    $('li.nav_l2_overview > a').removeAttr('aria-expanded');
    $('li.nav_l3_overview > a').removeAttr('aria-expanded');

    // Code to generate max-height of L2 and L3 levels.
    $('.site-nav_menu > ul > li').each(function () {
      let maxHeight = 0;
      const submenuUl = $('ul', $(this));
      submenuUl.each(function () {
        maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
      });
      submenuUl.height(maxHeight);
    });

    $('span.site_navigation__link.site_navigation__link--level-1').replaceWith(
      function () {
        return `<a>${$(this).text()}</a>`;
      }
    );

    // Add overlay div.
    if ($('.toolbar-horizontal').length === 0) {
      $('.main-content').prepend('<div id="overlay"></div>');
    }

    // Add div for close button.
    $('ul.site_navigation__sub-menu').append(
      '<div class="site-navigation__close"><a role="button" href="#" aria-label="Close"></a></div>'
    );

    // Add Dropdown div as L2 menu sibling.
    secondStage.each(function () {
      $('<div class="dropdown-container"/>').insertAfter(this);
    });

    // Code to drive detachment and working of L3 menu and appending it Dropdown container div.
    secondLink.each(function (index) {
      const elm = $(this);
      if (
        elm
          .parent('.site_navigation__item')
          .hasClass('site_navigation__item--has-children')
      ) {
        const dropdown = elm.next('.site_navigation--level-3');
        const dropdownContainer = elm
          .parent()
          .parent()
          .next('.dropdown-container');
        const refinedID =
          langParam === 'zh'
            ? `zh-item-${index}`
            : elm.text().replace(/[^A-Z0-9]/gi, '_');
        dropdown.attr('id', refinedID);
        dropdown.appendTo(dropdownContainer);
        elm.attr('data-dropdown', refinedID);

        $(this).click(function (e) {
          e.preventDefault();
          if ($(this).parent().hasClass('l2_clicked')) {
            $(this).parent().removeClass('l2_clicked');
            $(this)
              .parent()
              .siblings('.site-navigation__close')
              .css('display', 'block');
            $('.dropdown-container')
              .find('.site_navigation--level-3')
              .removeClass('menu-enabled');
            $(this).attr('aria-expanded', 'false');
          } else {
            $(this).parent().siblings().removeClass('l2_clicked');
            $(this).parent().addClass('l2_clicked');
            $(this)
              .parent()
              .siblings('.site-navigation__close')
              .css('display', 'none');
            const dropdownId = $(this).attr('data-dropdown');
            $('.dropdown-container')
              .find('.site_navigation--level-3')
              .removeClass('menu-enabled');
            $(this)
              .parent()
              .parent()
              .siblings('.dropdown-container')
              .find(`#${dropdownId}`)
              .addClass('menu-enabled');
            $(this)
              .parent()
              .parent()
              .siblings('.dropdown-container')
              .find(`#${dropdownId}`)
              .css({ left: '320px' });
            $(this).attr('aria-expanded', 'true');
          }
        });
      }
    });

    // Code to drive Main menu Parent items click.
    firstLink.click(function (e) {
      e.stopPropagation();
      const elm = $(this);
      const parent = elm.parent();
      if (parent.hasClass('site_navigation__item--has-children')) {
        e.preventDefault();
        overlayEnable();
      }
      if (parent.siblings().find('> a').hasClass('is-clicked')) {
        parent.siblings().find('> a').removeClass('is-clicked');
      }

      if (elm.hasClass('is-clicked')) {
        overlayDisable();
        elm.removeClass('is-clicked');
        elm.next('.site_navigation--level-2').removeClass('menu-enabled');
        elm
          .next()
          .next('.dropdown-container')
          .find('.menu-enabled')
          .removeClass('menu-enabled');
        elm.attr('aria-expanded', 'false');
      } else {
        if (parent.siblings().find('> ul').hasClass('menu-enabled')) {
          parent.siblings().find('> ul').removeClass('menu-enabled');
          parent
            .siblings()
            .find('.dropdown-container')
            .find('.menu-enabled')
            .removeClass('menu-enabled');
        }
        if (secondStage.hasClass('menu-enabled')) {
          secondStage.removeClass('menu-enabled');
          thirdStage.removeClass('menu-enabled');
        }
        if (firstLink.hasClass('is-clicked')) {
          firstLink.removeClass('is-clicked');
        }

        if (parent.hasClass('site_navigation__item--has-children')) {
          elm.addClass('is-clicked');
          elm.attr('aria-expanded', 'true');
        }
        elm.next('.site_navigation--level-2').addClass('menu-enabled');
        elm
          .next('.site_navigation--level-2')
          .find('.site-navigation__close')
          .css('display', 'block');
        elm
          .next('.site_navigation--level-2')
          .find('.l2_clicked')
          .removeClass('l2_clicked');
      }
    });

    // Reset Menu on Close button click.
    $('.site-navigation__close').click(function () {
      closeDesktopMenu(firstLink, secondLink);
    });

    $('#overlay').click(function () {
      closeDesktopMenu(firstLink, secondLink);
    });
  }
}

export default enable;
