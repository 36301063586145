import { Observable } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { IPropertyService } from './Interface/IPropertyService';

export abstract class RxJsModelBase {
  // Subscription service
  private propertiesService: IPropertyService;
  private drupal: boolean;
  // Subject RXJs
  // By default the state of events is updated
  constructor() {
    this.drupal = false;
    const subject = new ReplaySubject();
    const outputSubject = new ReplaySubject();
    // Initialize property Service
    this.propertiesService = {
      dispatch: (key: String, value, elem) => {
        subject.next({ key, value, elem });
      },
      dispatchOutput: (key: String, value, source = null) => {
        const elem = source ? source : this;
        outputSubject.next({ key, value, elem });
      },
      getService: () => subject.asObservable(),
      getOutputObservable: () => outputSubject.asObservable(),
    };
  }

  /**
   * Adaptor used for external updates on properties.
   */
  dispatch(name: string, value, source = null) {
    const elem = source ? source : this;
    this.propertiesService.dispatch(name, value, elem);
  }

  /**
   * Returns the service
   * This is the method used to update internal apps like React
   */
  public getPropertiesService(): Observable<any> {
    return this.propertiesService.getService();
  }

  /**
   * Returns the observable for communicating evnts via RXJS Observer
   */
  public getOutputObservable() {
    return this.propertiesService.getOutputObservable();
  }

  /**
   *  Allows to send events to subscribers.
   * @param name
   * @param value
   */
  public dispatchOutputEvent(name: string, value) {
    this.propertiesService.dispatchOutput(name, value);
  }

  /**
   * Particle entry point
   */
  public enable() {
    // Forced initializer for Drupal Behaviors
    if (this.drupal) {
      return;
    }
    try {
      const Drupal = (window as any).Drupal;
      if (Drupal && Drupal.behaviors) {
        this.drupal = true;
        const ref = this;
        // Attaching once
        if (!Drupal.behaviors[this.getNamespace()]) {
          Drupal.behaviors[this.getNamespace()] = {
            attach: function (context, settings) {
              ref.attachment(context, settings);
            },
          };
        }
        // Subscribing reducer
        const service = this.getPropertiesService();
        service.subscribe(this.reducer);
        // Invoke behavior as particle already capture it.
        this.attachment(document, (window as any).drupalSettings);
      }
    } catch (error) {
      window.onload = () => {
        this.attachment(document, {});
      };
    }
  }

  /**
   * BehaviorAttachment
   * @param context Drupal Context
   * @param settings DrupalSettings
   */
  private attachment(context, settings) {
    // maintains subscriptions
    this.autoSubscribe(context, settings);
    // run behavior
    this.behavior(context, settings);
  }

  /**
   * Creates bidirectional RXJS
   * @param context
   * @param settings
   */
  public autoSubscribe(context, settings) {
    // Using Drupal Jquery
    if (!!this.getSubscribersSelector()) {
      const listeners = context.querySelectorAll(this.getSubscribersSelector());
      for (var i = 0; i < listeners.length; i++) {
        let listener = listeners[i];
        if (!listener.classList.contains(`js-${this.getNamespace()}`)) {
          listener.classList.add(`js-${this.getNamespace()}`);
          // Register Listening Reducer
          listener.getOutputObservable().subscribe(this.reducer);
          // Make the component to subscribe to this module
          listener.subscribeToExternalSubject(this.getOutputObservable());
        }
      }
    }
  }

  /**
   * Allow External entities to make the WC a subscriber
   * @param externalSubject
   */
  public subscribeToExternalSubject(externalSubject) {
    externalSubject.subscribe((data) => {
      this.dispatch(data.key, data.value);
    });
  }

  /**
   * Binds Drupal Translate Service
   * @param str The translate string
   */
  t(str: string, args: any = {}): string {
    const Drupal = (window as any).Drupal;
    if (Drupal) {
      return Drupal.t(str, args);
    }
    return str;
  }

  /**
   * Return the namespace for init the module.
   */
  abstract getNamespace(): string;
  abstract getSubscribersSelector(): string;
  abstract reducer(data): void;
  abstract behavior(context, settings): void;
}
