import { Observable } from 'rxjs';
import { IPropertyService } from './Interface/IPropertyService';
import { WebComponentBase } from './WebComponentBase';
import { ReplaySubject } from 'rxjs';

export abstract class RxJsWebComponentBase extends WebComponentBase {
  // Subscription service
  private propertiesService: IPropertyService;
  // Subject RXJs
  // By default the state of events is updated
  constructor() {
    super();
    const subject = new ReplaySubject();
    const outputSubject = new ReplaySubject();
    // Initialize property Service
    this.propertiesService = {
      dispatch: (key: String, value) => {
        subject.next({ key, value });
      },
      dispatchOutput: (key: String, value) => {
        outputSubject.next({ key, value, elem: this });
      },
      getService: () => subject.asObservable(),
      getOutputObservable: () => outputSubject.asObservable(),
    };
  }

  /**
   * Adaptor used for external updates on properties.
   */
  dispatch(name: string, value) {
    this.propertiesService.dispatch(name, value);
  }

  /**
   * Returns the service
   * This is the method used to update internal apps like React
   */
  public getPropertiesService(): Observable<any> {
    return this.propertiesService.getService();
  }

  /**
   * Returns the observable for communicating evnts via RXJS Observer
   */
  public getOutputObservable(): Observable<any> {
    return this.propertiesService.getOutputObservable();
  }

  /**
   *
   * @param name Send event via Subject
   * @param value
   */
  public dispatchOutputEvent(name: string, value) {
    this.propertiesService.dispatchOutput(name, value);
  }

  /**
   * Allow External entities to make the WC a subscriber
   * @param externalSubject
   */
  public subscribeToExternalSubject(externalSubject: any) {
    externalSubject.subscribe((data) => {
      this.dispatch(data.key, data.value);
    });
  }

  /**
   * Web Component unmount
   */
  disconnectCallback() {}
}
