import { h, render } from 'preact'
import { RxJsWebComponentBase } from '../../shared/RxJsWebComponentBase';
import { IRxjsWebComponent } from '../../shared/Interface/IRxjsWebComponent';
import RxJsWedgelokConfiguratorApp from './app/RxJsWedgelokConfiguratorApp';

export default class WedgelokConfiguratorComponent extends RxJsWebComponentBase implements IRxjsWebComponent {

  /**
   * App initializing method.
   * @param props
   */
  _initApp(props) {
    render(
      <RxJsWedgelokConfiguratorApp {...props} />,
      this
    )
  }
}
