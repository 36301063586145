/**
 * Request-quote-popup
 */

// Module dependencies
import 'protons';

// Module template
import './_request-quote-popup.twig';

export const name = 'request-quote-popup';

export function disable() {}

export function enable() {}

export default enable;
