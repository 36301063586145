/**
 * banner
 */

// Module dependencies
import 'protons';
import $ from 'jquery';
import 'slick-carousel';

// Module styles
import './banner/_banner.scss';
import './quote-banner/_quote-banner.scss';
import './hero/_hero.scss';
import './hero/_hero-carousel.styles.scss';
import './title-hero/_title-hero.scss';
import './dual-banner/_dual-banner.scss';

// Module template
import './banner/_banner.twig';
import './quote-banner/_quote-banner.twig';
import './hero/_hero.twig';
import './title-hero/_title-hero.twig';
import './dual-banner/_dual-banner.twig';

export const name = 'banner';

export const defaults = {
  dummyClass: 'js-banner-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */

export function disable() {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */

export function enable($context) {
  const $sliderContainer = $('.hero-banner-slider');
  $sliderContainer.each(function () {
    const $currentSlider = $(this);
    $($currentSlider.find('.slider-container'), $context).slick({
      lazyLoad: 'ondemand',
      dots: true,
      infinite: false,
      accessibility: true,
      autoplay: false,
      autoplaySpeed: 4000,
      arrows: true,
      dotsClass: 'bullets',
      draggable: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 300,
      useTransform: false,
      prevArrow:
        '<a class="prev slide-arrow hero-carousel-handle" title="Previous" rel="nofollow"><span>❮</span></a>',
      nextArrow:
        '<a class="next slide-arrow hero-carousel-handle" title="Next" rel="nofollow"><span>❯</span></a>',
      appendArrows: $($(this).find('.slick-slider-nav'), $context),
      appendDots: $($(this).find('.slick-slider-dots'), $context),
      pauseOnFocus: false,
      pauseOnHover: false,
    });

    // Set infinite and autoplay setting true after 10 second.
    setTimeout(function () {
      $currentSlider
        .find('.slider-container')
        .slick('slickSetOption', 'infinite', true, true);
      $currentSlider
        .find('.slider-container')
        .slick('slickSetOption', 'autoplay', true, true);
    }, 10000);
  });

  $('.hero-banner-slider')
    .find('.hero-activity-handle')
    .on('click', function () {
      if ($(this).hasClass('pause-slide')) {
        $(this)
          .parent('.slick-slider-dots')
          .parent('.hero-banner-slider')
          .find('.slider-container')
          .slick('slickPause');
        $(this).removeClass('pause-slide');
      } else {
        $(this).addClass('pause-slide');
        $(this)
          .parent('.slick-slider-dots')
          .parent('.hero-banner-slider')
          .find('.slider-container')
          .slick('slickPlay');
      }
    });

  const $pcSliderContainer = $('.dual-banner');
  $pcSliderContainer.each(function () {
    const $pcCurrentSlider = $(this);
    $($pcCurrentSlider.find('.dual-banner-slider'), $context).slick({
      lazyLoad: 'ondemand',
      slidesToShow: 2,
      dots: true,
      arrows: true,
      prevArrow:
        '<a class="pc-prev pc-slide-arrow pc-carousel-handle" title="Previous" rel="nofollow"><span>❮</span></a>',
      nextArrow:
        '<a class="pc-next pc-slide-arrow pc-carousel-handle" title="Next" rel="nofollow"><span>❯</span></a>',
      appendArrows: $($(this).find('.pc-slick-slider-nav'), $context),
      appendDots: $($(this).find('.pc-slick-slider-dots'), $context),
      pauseOnFocus: false,
      pauseOnHover: false,
      infinite: true,
      accessibility: true,
      autoplay: true,
      autoplaySpeed: 4000,
      dotsClass: 'bullets',
      draggable: true,
      speed: 300,
      useTransform: false,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    setTimeout(function () {
      $pcSliderContainer
        .find('.dual-banner-slider')
        .slick('slickSetOption', 'infinite', true, true);
      $pcSliderContainer
        .find('.dual-banner-slider')
        .slick('slickSetOption', 'autoplay', true, true);
    }, 10000);
  });

  $('.dual-banner')
    .find('.pc-activity-handle')
    .on('click', function () {
      if ($(this).hasClass('pc-pause-slide')) {
        $(this)
          .parent('.pc-slick-slider-dots')
          .parent('.dual-banner')
          .find('.dual-banner-slider')
          .slick('slickPause');
        $(this).removeClass('pc-pause-slide');
      } else {
        $(this).addClass('pc-pause-slide');
        $(this)
          .parent('.pc-slick-slider-dots')
          .parent('.dual-banner')
          .find('.dual-banner-slider')
          .slick('slickPlay');
      }
    });
}
export default enable;
