import { h, render } from 'preact'
import { RxJsWebComponentBase } from '../../shared/RxJsWebComponentBase'
import {IRxjsWebComponent} from '../../shared/Interface/IRxjsWebComponent'
import RxJsCommerceCartApp from './app/RxJsCommerceCartApp'
import './commerce-cart.scss'

export default class CommerceCartComponent extends RxJsWebComponentBase implements IRxjsWebComponent  {

  /**
   * App initializing method
   * @param props Web Component properties
   */
  _initApp(props) {
    this.innerHTML = ''
    render(
      <RxJsCommerceCartApp {...props} />,
      this
    )
  }
}
