/**
 * detail-page-content
 */
// Module dependencies
import 'protons';

// Module styles
import './_detail-page-content.scss';

// Module template
import './_detail-page-content.twig';

export const name = 'detail-page-content';

export const defaults = {
  dummyClass: 'js-detail-page-content-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  const $printElementRef = document.querySelector('.js-print-page', $context);

  // Adding Click Listener to Referenced Element
  if ($printElementRef) {
    $printElementRef.addEventListener('click', () => {
      // Print Function
      window.print();
    });
  }
}

export default enable;
