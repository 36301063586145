import '@webcomponents/webcomponentsjs';

export abstract class WebComponentBase extends HTMLElement {
  private _mode: string | null;
  public _props;
  public _innerAttributes: NamedNodeMap;
  public hydrate: boolean;

  /**
   * Render method for the Web Component.
   */
  abstract _initApp(props): void;

  /**
   * Constructor method.
   * Root Attributes access.
   */
  constructor() {
    super();
    this._mode = this.getAttribute('mode');
    this._props = {};
    this._innerAttributes = this.attributes;
    // Override this to false if you don't want to Hydrate your App.
    this.hydrate = true;
  }

  /**
   * connectedCallback
   * Executed when the component is mounted into the DOM.
   */
  connectedCallback() {
    this.initHydration(this);
  }

  /**
   * Prepare DOM for React Hydration.
   */
  initHydration(node: HTMLElement) {
    let content = node.innerHTML;
    const transform = [
      {
        regex: /^\s*</gm,
        replace: '<',
      },
      {
        regex: />\s*$/gm,
        replace: '>',
      },
      {
        regex: />\s*</gm,
        replace: '><',
      },
    ];

    transform.map((elem) => {
      content = content.replace(elem.regex, elem.replace);
      return content;
    });

    if (this._mode !== 'offline') {
      if (this.hydrate) {
        this.innerHTML = content;
      }
      this._render();
    }
  }

  /**
   * Adaptor used for external updates on properties.
   */
  updateProp(name: string, value: any) {
    this._props[name] = value;
    const event = new CustomEvent('updateProps', { detail: this._props });
    this.dispatchEvent(event);
  }

  /**
   * Event dispatcher that bubbles from the web component.
   */
  dispatch(eventName: string, data: object) {
    const event = new CustomEvent(eventName, {
      detail: data,
      bubbles: true,
    });
    this.dispatchEvent(event);
  }

  /**
   * Binds Drupal Translate Service
   * @param str The translate string
   */
  t(str: string, args: any = {}): string {
    const Drupal = (window as any).Drupal;
    if (Drupal) {
      return Drupal.t(str, args);
    }
    return str;
  }

  /**
   * Rendering React app.
   */
  _render() {
    const attributes = this._innerAttributes;
    this._props = {};
    Array.from(attributes).forEach((attr: Attr) => {
      this._props[attr.nodeName] = attr.nodeValue;
    });
    this._props['root'] = this;
    this._initApp(this._props);
  }
}
