/**
 * contact-block
 */

// eslint-disable-next-line no-unused-vars
import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_product-overview.scss';

// Module template
import './_product-overview.twig';
import './_product-overview-tab.twig';

export const name = '_product-overview';

export function disable() {}

export function enable(context) {
  const $productTabs = $('.product-nav-tabs__item', context);
  const $productMain = $('.product-main-nav-tabs', context);
  const $productItems = $(
    '.product-main-nav-tabs .product-nav-tabs__item',
    context
  ).length;
  $productTabs.each(function () {
    const $navItem = $(this);
    $navItem.find('.icon-circle').on('click', function () {
      let activeClass = 'product-nav-tabs__item--active';
      let $siblingEl = $navItem
        .closest('.product-nav-tabs__list')
        .find('.product-nav-tabs__item');

      $siblingEl.removeClass(activeClass);
      // Show active nested nav
      $navItem.addClass(activeClass);

      // Tertiary-level navigation
      const $activeNestedContent = $(`#${$navItem.data('target')}`);

      activeClass = 'product-nav-tabs__content--active';

      $siblingEl = $activeNestedContent
        .closest('.product-nav-tabs__content-wrapper')
        .find('.product-nav-tabs__content');

      // Hide all nested navs
      $siblingEl.removeClass(activeClass);

      // Show active nav
      $activeNestedContent.addClass(activeClass);
    });
  });
  $productMain.each(function () {
    const $currentSlider = $(this);
    if ($productItems > 4) {
      $($currentSlider.find('.slider-container'), context).slick({
        lazyLoad: 'ondemand',
        dots: false,
        infinite: false,
        accessibility: true,
        autoplay: false,
        draggable: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 300,
        useTransform: false,
        rows: 0,
        prevArrow:
          '<a class="prev slide-arrow hero-carousel-handle" title="Previous" rel="nofollow"><span>❮</span></a>',
        nextArrow:
          '<a class="next slide-arrow hero-carousel-handle" title="Next" rel="nofollow"><span>❯</span></a>',
        appendArrows: $($(this).find('.product-slider-nav'), context),
      });
    }
  });

  // Product card equal heights/
  $('.product-main-nav-tabs .product-nav-tabs__item').matchHeight({
    byRow: false,
    property: 'height',
    target: null,
    remove: false,
  });

  // when parent menu click then its submenu's first child will be active.
  $('.product-nav-menu-tabs__list > ul.product-nav-tabs__list li').on(
    'click',
    function () {
      $(
        '.product-nav-menu-tabs__content-wrapper > .product-nav-tabs__content-wrapper > .product-nav-tabs__content--active '
      ).each(function () {
        const isSubmenuActive = $(this)
          .find(
            '.product-nav-nested-tab__content-wrap > .product-nav-tabs__content-wrapper > .product-nav-nested__section-wrapper > .product-nav-tabs__content'
          )
          .hasClass('product-nav-tabs__content--active');
        if (isSubmenuActive === false) {
          $(this)
            .find(
              '.product-nav-nested-tab__content-wrap > .product-nav-tabs__content-wrapper > .product-nav-nested__section-wrapper > .product-nav-tabs__content:first-child'
            )
            .addClass('product-nav-tabs__content--active');
        }
      });
    }
  );
}
export default enable;
