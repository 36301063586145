/**
 * card-accordion
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_card-accordion.scss';

// Module template
import './_card-accordion.twig';

export const name = 'card-accordion';

export const defaults = {
  dummyClass: 'js-card-accordion-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 */
export function enable($context) {
  // Find our component within the DOM
  const $cardAccordion = $('.card-accordion', $context);
  let isOpen = false;

  // Bail if component does not exist
  if (!$cardAccordion.length) {
    return;
  }

  const toggleContent = ($el) => {
    if (isOpen) {
      $el.find('.card-accordion__accordion-content').fadeIn();
      $el.find('.card-accordion__trigger').attr('aria-expanded', true);
    } else {
      $el.find('.card-accordion__accordion-content').fadeOut('fast');
      $el.find('.card-accordion__trigger').attr('aria-expanded', false);
    }
  };

  $cardAccordion.each((i, el) => {
    $(el).on('click', '.card-accordion__trigger', () => {
      isOpen = !isOpen;
      toggleContent($(el));
    });
  });
}

export default enable;
