/**
 * button-dropdown
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_button-dropdown.scss';

// Module template
import './_button-dropdown.twig';

export const name = 'button-dropdown';

export const defaults = {
  dummyClass: 'js-button-dropdown-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  // Find our component within the DOM
  const $buttonDropdown = $('.button-dropdown', $context);
  // Bail if component does not exist
  if (!$buttonDropdown.length) {
    return;
  }

  $buttonDropdown.each((i, el) => {
    $(el).on('click', '.button-dropdown__toggle', () => {
      $(el).find('.button-dropdown__menu').toggleClass('show');
    });
  });
}

export default enable;
