/**
 * Related Image Gallery
 */

import $ from 'jquery';
import 'slick-carousel';
import 'slick-lightbox';
import 'jquery-once';

// Module styles
import './_related-image-gallery.scss';

// Module template
import './_related-image-gallery.twig';

export const name = 'related-image-gallery';

export function enable($context) {
  // Find .relate-image-block__list elements on the page
  const $assetBlock = $('.relate-image-block__list', $context);

  $assetBlock.once('relate-image-block__list-slickLightbox').slickLightbox();
}

export default enable;
