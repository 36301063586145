/**
 * download-card
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_download-card.scss';

// Module template
import './_download-card.twig';

export const name = 'download-card';

export const defaults = {
  dummyClass: 'js-download-card-exists',
};

const queryString = require('query-string');

/* eslint-disable */
/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context, settings) {
  const ns = 'downloadCard';
  let module = {
    /**
     * Drupal environment flag.
     */
    drupal: false,

    /**
     * Initialized flag.
     */
    init: false,
  };

  /**
   * Renders the iframe.
   * @param {HtmlNode} $elem: The downlaod card element.
   * @param {*} data: Traceparts Iframe service data.
   */
  function prepareIframe($elem, data) {
    // Returns false if viewer_ui is not defined.
    if (!data.viewer_ui) {
      $elem.remove();
      return false;
    }

    $('iframe#download-card-iframe').remove();
    $elem.find('.download-card__card img').remove();
    $elem
      .find('.download-card__card')
      .prepend(
        `<iframe id="download-card-iframe" src="${data.viewer_ui}"></iframe>`
      );
    $elem
      .find('.download-card__dropdown')
      .addClass('download-card__dropdown--loaded');
    $elem.addClass('download-card--loaded');
    $elem.find('.loading-indicator').hide();
    const event = new Event('download-card-loaded');
    document.dispatchEvent(event);
    return true;
  }

  /**
   * Render Download options
   * @param {HtmlNode} $elem: The downlaod card element.
   * @param {*} options: The downlaod files urls.
   */
  function prepareDownloadOptions($elem, options) {
    const $btn_element = $elem.find('.download-card__link a');
    $btn_element.removeClass('generate-option-btn');
    $elem.find('.form-item').show();
    $btn_element.find('span').text(Drupal.t('Select a File Type to Download'));
    const $select = $elem.find('select.form-item__select');
    $select.find('option').remove();
    if (options.options) {
      const selecttext = Drupal.t('Select');
      $select.append(`<option value="">${selecttext}</option>`);
      for (const option in options.options) {
        $select.append(
          `<option value="${option}">${options.options[option]}</option>`
        );
      }
    }
    $select
      .on('change', function () {
        const $anchor = $elem.find('.download-card__link a');
        const url = this.value;
        if (!url) {
          $anchor
            .addClass('disabled')
            .attr('href', '#')
            .find('span')
            .text(Drupal.t('Select a File Type to Download'));
        } else {
          $anchor
            .removeClass('disabled')
            .attr('href', url)
            .find('span')
            .text(Drupal.t('Generate and Download'));
        }
      })
      .change();
  }

  /**
   * Render 'login to download' button.
   * @param {HtmlNode} $elem: The downlaod card element.
   */
  function prepareLoginButton($elem) {
    $elem.find('.form-item').html('');
    const icon = $elem.find('.button--secondary svg').clone();
    $elem
      .find('.button--secondary')
      .text(Drupal.t('Login to Download'))
      .attr('href', '')
      .append(icon)
      .on('click', function (ev) {
        ev.preventDefault();
        SFIDWidget.login();
      });
  }

  /**
   * Returns data to be used for the CAD download card.
   * @param {HTMLNode} $elem: The download card element.
   * @param {any} params: The product item's part number.
   */
  function getDownloadCardData($elem, params, context) {
    $.ajax({
      url: '/api/traceparts/viewer',
      method: 'GET',
      data: params,
    })
      .done(function (iframeData) {
        const brands = ['HOFFMAN', 'SCHROFF'];
        // Show 'Login to Download' button for 'hoffman' and 'schroff' brand when user is not logged in.
        if (brands.includes(drupalSettings.searchBrand) && !drupalSettings.nVent.salesforce.user) {
          prepareLoginButton($elem);
          prepareIframe($elem, iframeData);
        }
        else {
          $.ajax({
            url: '/api/traceparts/options',
            method: 'GET',
            data: params,
          })
            .done(function (options) {
              prepareDownloadOptions($elem, options);
              prepareIframe($elem, iframeData);
              // If user is salesforce authenticated and within the brands of salesforce
              // then remove the description.
              if (brands.includes(drupalSettings.searchBrand) && drupalSettings.nVent.salesforce.user) {
                $('.download-card__description').text(Drupal.t("Select and download additional CAD models in the format of your choice."));
              }
            })
            .fail(function (xhr) {
              checkTracepartPreview($elem, params, iframeData);
            })
        }
      })
      .fail(function (xhr) {
        console.error(xhr);

        $('.facet-list').addClass('facet-list--left');
        const $tab_content = $('.download-card', context).parents(
          '.tabs-block__tab__content'
        );
        if ($tab_content) {
          // Update message by parent tab name
          const id = $tab_content.attr('id');
          // Add message to download-card container
          const message = `<h3 class="tabs-block__tab__content-no">No ${$(
            `a.${id}`
          ).text()} available</h3>`;
          $('.download-card', context).parent().append(message);
        }
        // Hide iframe section
        $('.download-card', context).hide();
      })
      .always(() => {
        $('.loading-indicator').hide();
      });
  }

  /**
   * Check tracepart preview availablity.
   * @param {HtmlNode} $elem: The downlaod card element.
   * @param {any} params: The product item's parameters.
   * @param {*} iframeData: Traceparts Iframe service data.
   */
  function checkTracepartPreview($elem, params, iframeData) {
    // Returns false if viewer_ui is not defined.
    if (!iframeData.viewer_ui) {
      $elem.find('.download-card__card').remove();
      $elem.addClass('download-card--loaded');
      $elem.html('<h3>' + Drupal.t('No 3D models available.') + '</h3>');
      return false;
    }

    $.ajax({
      url: iframeData.viewer_ui,
      method: 'GET',
    })
    .done(function (previewData) {
      // We still get status_code 200 in case of Preview not available.
      // So, have to check in this way only whether preview is available or not.
      if (previewData.includes('Preview not available')) {
        $elem.find('.download-card__card').remove();
        $elem.addClass('download-card--loaded');
        $elem.html('<h3>' + Drupal.t('No 3D models available.') + '</h3>');
      }
      else {
        prepareDownloadButton($elem, params, iframeData);
      }
    })
    .fail(function (xhr) {
      checkTracepartPreview($elem, params, iframeData);
    })
    .always(() => {
      $('.loading-indicator').hide();
    });
  }

  /**
   * Render 'Generate Download format options' button.
   * @param {HtmlNode} $elem: The downlaod card element.
   * @param {any} params: The product item's parameters.
   * @param {*} iframeData: Traceparts Iframe service data.
   */
  function prepareDownloadButton($elem, params, iframeData) {
    $elem.find('.form-item').hide();
    $elem
      .find('.button--secondary')
      .addClass('generate-option-btn')
      .text(Drupal.t('Generate Download format options'))
      .attr('href', '')
      .on('click', function (ev) {
        ev.preventDefault();
        generateOptions($elem, params, iframeData)
      });
  }

  /**
   * Call to tracepart API to get all options.
   * @param {HtmlNode} $elem: The downlaod card element.
   * @param {any} params: The product item's parameters.
   * @param {*} iframeData: Traceparts Iframe service data.
   */
  function generateOptions($elem, params, iframeData) {
    $.ajax({
      url: '/api/traceparts/options',
      method: 'GET',
      data: params,
    })
    .done(function (options) {
      prepareDownloadOptions($elem, options);
    })
    .fail(function (xhr) {
      prepareDownloadButton($elem, params, iframeData);
      console.error(xhr, 'Failed to download tracepart option.');
    })
    .always(() => {
      prepareIframe($elem, iframeData);
    });
  }

  /**
   * Sets the data to be used for the CAD download card.
   * @param {HTMLNode} $elem: The download card element.
   */
  function setDownloadCardData($elem, context) {
    const $selector = $('.traceparts-info-data-selector', context);
    $selector.once('traceparts-info-data-selector').change(function () {
      setDownloadCardData($elem, context);
    });
    const tracepartsInfo = $selector.data('traceparts-info');
    const pid = $selector.val();
    const brands = ['HOFFMAN', 'SCHROFF'];

    // Show Download options for other brands except 'hoffman' and 'schroff'.
    if (!(brands.includes(drupalSettings.searchBrand)) || drupalSettings.nVent.salesforce.user) {
      prepareDownloadOptions($elem, {
        options: tracepartsInfo.downloadOptions[pid],
      });
    }
    else {
      prepareLoginButton($elem);
    }
    prepareIframe($elem, { viewer_ui: tracepartsInfo.viewerUri[pid] });
  }

  /**
   * Drupal behavior attachament.
   */
  module.attachment = function (context, settings) {
    // Write shared patternlab drupal code here
    if (!module.drupal) {
      // Exlusive Patternlab interacions
      $('.download-card', context).each(function () {
        $(this).addClass('download-card--loaded');
        $(this)
          .find('.download-card__dropdown')
          .addClass('download-card__dropdown--loaded');
      });
    }

    // Get the User-Agent header from the request.
    var userAgent = navigator.userAgent.toLowerCase();
    // Check if the User-Agent is "coveobot".
    if (userAgent.includes("coveobot")) {
      return;
    }

    if (module.drupal && !module.init) {
      module.init = true;
      const $downloadCards = $('.download-card');

      // Create an Intersection Observer instance
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            $('.download-card', context).each(function () {
              const $elem = $(this);
              const parsed = queryString.parse(location.search);
              const $wedgeLokConfigurator = $('#wedgelok-configurator');
              const pid = $elem.data('product-id');
              const brand = $elem.data('brand');
              // If there is a selectionPath param in the browser URL upon page load,
              // first get the part # according to the selectionPath via the API.
              // This part # will be used to get the data for the CAD Download
              // instead of using the value coming from the node's Catalog Number
              // field. If there is no selectionPath in the browser URL upon page
              // load, the part # from the node's Catalog Number field will be used.
              if (parsed.selectionPath && $wedgeLokConfigurator.length) {
                let params = {
                  'part_id': pid,
                  'selection_path': parsed.selectionPath,
                  'check_availability': 0,
                  'brand': brand,
                }
                getDownloadCardData($elem, params, context);
              } else if (!$wedgeLokConfigurator.length) {
                if (pid === 'PRELOADED') {
                  setDownloadCardData($elem, context);
                } else {
                  let params = {
                    'part_number': pid,
                    'check_availability': 0,
                    'brand': brand,
                  }
                  getDownloadCardData($elem, params, context);
                }
              }
            });

            // Disconnect the observer after the desired element is in the viewport
            observer.disconnect();
          }
        });
      });

      // Start observing the "cad-generation" element
      observer.observe($downloadCards[0]);
    }

    const brands = ['HOFFMAN', 'SCHROFF'];
    // Avoid 'Login to Download' button to open in new tab for 'hoffman' & 'schroff' brand.
    if(!drupalSettings.nVent.salesforce.user) {
      $('.download-card__link a', context).on('click', function (e) {
        console.log('sagar');
        let windowsUrl = window.location;
        if (windowsUrl.hash && !Cookies.get('Drupal.windows_url_hash')) {
          Cookies.set('Drupal.windows_url_hash', windowsUrl.hash);
        }
      });
    }
    if (!(brands.includes(drupalSettings.searchBrand)) || drupalSettings.nVent.salesforce.user) {
      $('.download-card__link a', context).on('click', function (e) {
        var generate_option_btn = $(this).hasClass('generate-option-btn');
        if (generate_option_btn) {
          return false;
        }
        e.preventDefault();
        const $link = $(this);
        setDownloadState($link);
        if (drupalSettings.searchBrand === 'SCHROFF') {
          Drupal.ajax({
            url: `/ajax/alert?msg=${encodeURI(
              Drupal.t(
                "Your @filename file will be downloaded shortly in the new tab.",
                {
                  "@filename": $('.download-card__dropdown select option:selected').text(),
                }
              )
              )}&type=${"success"}`,
          }).execute();
          setTimeout(() => {
            window.open($link.attr('href'));
          }, 3000);
        } else {
          // Forces the download to start on a new tab so the button state resets.
          window.open($link.attr('href'));
        }
      });
    }
  };

  function setDownloadState($link) {
    const linkText = Drupal.t('Generating file');
    $link
      .addClass('button--secondary__downloading disabled spinner')
      .find('span')
      .text(linkText);
    window.addEventListener('focus', resetDownloadState, false);
  }

  function resetDownloadState() {
    window.removeEventListener('focus', resetDownloadState, false);
    const linkText = Drupal.t('Generate and download');
    $('.download-card__link a')
      .removeClass('button--secondary__downloading disabled spinner')
      .find('span')
      .text(linkText);
  }

  // Forced initializer for Drupal Behaviors
  try {
    if (Drupal.behaviors) {
      module.drupal = true;
      // Attaching once
      if (Drupal.behaviors[ns] === undefined) {
        Drupal.behaviors[ns] = {
          attach: module.attachment,
        };
        // Invoke behavior as particle already capture it.
        module.attachment($context[0], settings);
      }
    }
  } catch (error) {
    window.onload = (event) => {
      console.log('ERRR3');
      module.attachment(document, {});
    };
  }
}

export default enable;
/* eslint-enable */
