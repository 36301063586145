/**
 * search-form
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_search-form.scss';

// Module template
import './_search-form.twig';

export const name = 'search-form';

export const defaults = {
  dummyClass: 'js-search-form-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  // Find our component within the DOM
  const $searchForm = $('.search-form', $context);
  const $body = $('body[class^="site-"]', $context);
  // Bail if component does not exist
  if (!$searchForm.length) {
    return;
  }
  $(window).on('afterHeaderResize', () => {
    const $inputWidth = $searchForm.find('input').width();
    $body.find('.search-api-autocomplete-search').css({
      'max-width': $inputWidth,
    });
    const event = new Event('headerResizingReady');
    document.dispatchEvent(event);
  });

  $(window).trigger('resize');
}

export default enable;
