/**
 * reseller
 */

import $ from 'jquery';

// Module styles
import './_resellers.scss';

// Module template
import './_resellers.twig';

export const name = 'resellers';

export const defaults = {
  dummyClass: '',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  // Find our component within the DOM
  const $skuTable = $('#availability-content.table--hidden', $context);
  // Bail if component does not exist
  if (!$skuTable.length) {
    return;
  }
  const $resellerTable = $('.Resellers-In-Your-Area');
  const $resellerMobileTable = $('[data-tab="Resellers-In-Your-Area"]');
  $($resellerTable).addClass('hidden');
  $($resellerMobileTable).addClass('hidden');

  /**
   * Renders the Mectronics Resellers/SKU table.
   */
  function getSKUTableData() {
    const endpoint = `/${
      drupalSettings.region_manager.current_prefix
    }/api/availability/${$('.product-hero__catalog--number').text()}`;
    $.ajax({
      url: endpoint,
      method: 'GET',
      data: {},
      crossDomain: true,
      dataType: 'json',
      timeout: 30000,
    })
      .done(function cb(data) {
        if (data.StockCheck && data.StockCheck.status) {
          const payload = data.StockCheck;
          const $tbody = $('tbody', $skuTable);
          $tbody.empty();
          const keys = ['Quantity', 'InvDate'];
          for (let i = 0; i < payload.rCount; i += 1) {
            const $trow = $(document.createElement('tr'));
            const $distLink = $(document.createElement('td'));
            const distContent = `
          <a href="${payload.resultlist[i].Buy}"
          class="link--distributor-title" target="_blank">${payload.resultlist[i].DistName}
          </a>
          `;
            $distLink.html(distContent);
            $trow.append($distLink);
            for (let j = 0; j < keys.length; j += 1) {
              const $tdata = $(document.createElement('td'));
              $tdata.html(payload.resultlist[i][keys[j]]);
              $trow.append($tdata);
            }
            const $buyLink = $(document.createElement('td'));
            const linkContent = `
          <a href="${payload.resultlist[i].Buy}"
          class="link link--alt" target="_blank">${Drupal.t('Shop')}
            <svg class="icon" aria-hidden="true" role="img">
                    <use xlink:href="/themes/custom/particle/dist/app-drupal/assets/images/sprite.svg#sprite-arrow-right"></use>
            </svg>
          </a>
          `;
            $buyLink.html(linkContent);
            $trow.append($buyLink);
            $tbody.append($trow);
          }
        } else if (data.error) {
          $skuTable.hide();
          document
            .querySelector('price-block')
            .dispatch('removeAvailability()', true);
          return;
        } else {
          $('tbody tr > td:nth-child(1)', $skuTable).html(data.message);
        }
        if (
          window.location.hash &&
          window.location.hash === '#Resellers-In-Your-Area'
        ) {
          const $clickElem = $(`a[href="#Resellers-In-Your-Area"]`);
          if ($clickElem.length) {
            setTimeout(function cto() {
              $clickElem[0].click();
            }, 400);
          }
        }
        $skuTable.removeClass('table--hidden');
        if ($(window).width() > 919) {
          $resellerTable.removeClass('hidden');
        } else {
          $resellerMobileTable.removeClass('hidden');
        }
      })
      .fail(function ecb(xhr) {
        console.error(xhr);
        document
          .querySelector('price-block')
          .dispatch('removeAvailability()', true);
      });
  }
  // If there is a selectionPath param in the browser URL upon page load,
  // first get the part # according to the selectionPath via the API. This
  // part # will be used to get the data for the SKU table instead of using the
  // value coming from the node's Catalog Number field. If there is no
  // selectionPath in the browser URL upon page load, the part # from the
  // node's Catalog Number field will be used.
  const $wedgeLokConfigurator = $('#wedgelok-configurator');
  if (!$wedgeLokConfigurator.length) {
    getSKUTableData();
  }

  const $clickElement = $('a[href="#Resellers-In-Your-Area"]');
  const $mobileClickElement = $('[data-tab="Resellers-In-Your-Area"]');
  $('.purchase-block__availability').on('click', function (event) {
    event.preventDefault(); // Prevent default anchor behavior if needed
    if ($clickElement.length) {
      setTimeout(function lto() {
        if ($(window).width() > 919) {
          $clickElement[0].click();
        } else if (!$mobileClickElement.hasClass('active')) {
          $('html, body').stop(true, true);
          $mobileClickElement[0].click();
        }
      }, 400);
    }
  });
}

export default enable;
