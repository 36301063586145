/**
 * Apply the Design System to a single Drupal behavior
 */

// ECMAScript polyfills, but NOT fetch(). Fetch() is web standard, not
// ECMAScript.
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import $ from 'jquery';

import { enableAllComponents } from '../../source/default';

require('./scss/_drupal-styles.scss');

console.log('Particle Drupal behaviors ran.');

Drupal.behaviors.designSystem = {
  attach($context, settings) {
    // Let's pretend we have a Drupal module called customCarouselModule that
    // provides a key on settings called `customCarouselModule` that has a key
    // called `interval`. Since this won't exist out of the box, we'll also
    // provide a default fallback object: { interval: 5000 }. Change this in
    // actual implementation.
    const { customCarouselModule = { interval: 5000 } } = settings;

    // Provide overrides to components from Drupal settings
    const componentSettings = {
      carousel: {
        interval: customCarouselModule.interval,
      },
      // .. other component overrides go here
    };

    // Now enable all components with a custom componentSettings object
    enableAllComponents($context, componentSettings);

    // double scroll on literature and parts listing page
    $(function () {
      if ($('.global-part-table .table').length > 0) {
        $('.global-part-scroll__inner').css(
          'width',
          $('.global-part-table .table').outerWidth()
        );
      }
      $('.literature-top-scroll,.global-part-top-scroll').scroll(function () {
        $('.literature-table,.global-part-table').scrollLeft(
          $('.literature-top-scroll,.global-part-top-scroll').scrollLeft()
        );
      });
      $('.literature-table,.global-part-table').scroll(function () {
        $('.literature-top-scroll, .global-part-top-scroll').scrollLeft(
          $('.literature-table, .global-part-table').scrollLeft()
        );
      });
    });
  },
};
