/**
 * h5p-image-hotspots
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

export const name = 'h5p-image-hotspots';

export const defaults = {
  dummyClass: 'js-h5p-image-hotspots-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable() {
  // Define the function to update the elements
  $(document).ready(function ($context) {
    const updateHotspotInfo = () => {
      // Get the iframe element
      const iframe = document.querySelectorAll('.h5p-iframe', $context);

      if (iframe.length > 0) {
        // Loop through the iframes
        iframe.forEach(function (iframeElement) {
          // Get the content document of the iframe
          const iframeDocument =
            iframeElement.contentDocument ||
            iframeElement.contentWindow.document;

          // Find elements with the class 'h5p-image-hotspot-info' inside the iframe's document
          const hotspotInfoElements = iframeDocument.querySelectorAll(
            '.h5p-image-hotspot-info',
            $context
          );

          // Loop through the found elements and set their HTML content to 'LALALA'
          hotspotInfoElements.forEach(function (element) {
            element.innerHTML = element.title;
          });
        });
      }
    };
    /* eslint-disable no-lonely-if */
    if (!$('body').hasClass('.toolbar-fixed')) {
      updateHotspotInfo();
    }
    if (document.readyState === 'complete') {
      updateHotspotInfo();
    } else {
      window.addEventListener('load', updateHotspotInfo);
    }
  });
}

export default enable;
