import { h, Fragment } from 'preact';
import { useEffect, useReducer } from 'preact/hooks';
import { CartItem } from './components/CartItem';
import { CartSidebar } from './components/CartSidebar';
import { SalesforceCartIframe } from './components/SalesforceCartIframe';
import { ProductComponents } from './components/ProductComponents';
import { CommerceModule } from "../../../models/CommerceModule";

function RxJsCommerceCartApp(props) {
  const apiPricingDisabled = CommerceModule.getInstance().isApiPricingDisabled();
  let initialState: any = {};
  // Add inital options
  initialState.options = [1];
  initialState.selectedQuantity = 1;
  initialState.user = -1;
  initialState.cartId = '';
  initialState.products = {};
  initialState.ready = false;
  initialState.listPriceSubtotal = '--';
  initialState.yourPriceSubtotal = '--';
  initialState.noPricing = false;
  initialState.showComponents = false;
  initialState.componentIndex = -1;
  const loadingStr = `(...${t('loading')})`;

  // Shothand for drupal t
  function t(str: string, args = {}): string {
    return props.root.t(str, args);
  }

  // Reducer
  const reducer = (cartState, message) => {
    switch (message.key) {
      case 'loadIframe()':
        cartState.ready = true;
        if (message.value) {
          cartState.cartId = message.value.cartId;
        }
        return { ...cartState };
      case 'toggleComponents()':
        cartState.showComponents = !cartState.showComponents;
        cartState.componentIndex = message.value;
        document.body.classList.toggle('ecommerce-js--overlay');
        return { ...cartState };
      case 'updateUser()':
        cartState.user = message.value;
        return { ...cartState };
      case 'cartInfoOfflineUpdate()':
        const defaultProductValues = {
          availability: '(...)',
          listPrice: '--',
          yourPrice: '--',
          url: '/',
          options: [],
          noPricing: false,
          components: [],
          netValues: {
            yourPrice: 0,
            listPrice: 0,
          },
        };
        cartState.noPricing = false;
        cartState.componentIndex = -1;
        // verify new added products and changed quantities
        const items = message.value.products;

        // Replace Products
        let yourSubtotal: any = 0;
        let listSubTotal: any = 0;

        let products = items.map((product) => {
          let finalProduct = {
            ...defaultProductValues,
            ...product,
          };

          if (yourSubtotal != '--') {
            let pYourPrice = finalProduct.yourPrice;
            let pYourPriceSubTotal: number =
              finalProduct.netValues['yourPrice'] * finalProduct.qty;
            if (pYourPrice == '--') {
              yourSubtotal = '--';
            } else {
              yourSubtotal += pYourPriceSubTotal;
            }
          }

          if (listSubTotal != '--') {
            let pListPrice = finalProduct.listPrice;
            let pListPriceSubTotal =
              finalProduct.netValues['listPrice'] * finalProduct.qty;
            if (pListPrice == '--') {
              listSubTotal = '--';
            } else {
              listSubTotal += pListPriceSubTotal;
            }
          }

          finalProduct.noPricing =
            finalProduct.netValues['yourPrice'] == 0 ||
            finalProduct.netValues['listPrice'] == 0;
          // If at least one product is zero priced then all the cart display is affected
          if (finalProduct.noPricing) {
            cartState.noPricing = true;
          }
          if (finalProduct['availability'] !== '(...)') {
            finalProduct['availability'] =
              finalProduct['availability'] === loadingStr
                ? loadingStr
                : finalProduct['availability'] == "0" ?
                  t('Leadtime available upon request')
                :
                  t('Ships within @days working days', {
                    '@days': finalProduct['availability'],
                  });
          }

          return finalProduct;
        });

        let currency = '';
        if (products.length > 0) {
          if (listSubTotal !== '--') {
            currency = products[0].netValues.currency;
            listSubTotal = currency + listSubTotal.toFixed(2) + '*';
            yourSubtotal = currency + yourSubtotal.toFixed(2) + '*';
            cartState.ready = true;
          } else {
            // pending calulation products
            const waitingProducts = products.filter((p) => {
              return p.availability === loadingStr;
            });
            if (waitingProducts.length > 0) {
              cartState.ready = false;
            } else {
              cartState.ready = true;
            }
          }
        } else {
          listSubTotal = '--';
          yourSubtotal = '--';
        }

        // Refresh Products
        cartState.products = products;

        // Refresh Subtotals
        cartState.listPriceSubtotal = listSubTotal;
        cartState.yourPriceSubtotal = yourSubtotal;
        return { ...cartState };
      default:
        return cartState;
    }
  };

  // Use Reducer
  const [cart, dispatch] = useReducer<any, any>(reducer, initialState);

  // Cart initialization events
  const initCart = () => {
    // Sync cart items info with current region.
    props.root.dispatchOutputEvent('checkRegionInvariance()', true);
  };

  // Components toggle handler
  const toggleProductComponents = (index: number = 0) => {
    dispatch({ key: 'toggleComponents()', value: index });
  };

  // UseEffect HOOK
  useEffect(() => {
    let subscription = null;
    const service = props.root.getPropertiesService();
    initCart();
    subscription = service.subscribe((data) => {
      dispatch(data);
    });
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  var dynamicText = {
    "cartLabels": {
      "mycart": "My Cart",
      "cnum": "Catalog #",
      "desc": "Description",
      "lPrice": "List Price",
      "yPrice": "Your Price",
      "quant": "Quantity",
      "act": "Actions",
      "sub": "Sub-Total",
      "cartempty": "You have no items here! Add some products to see this table populate.",
      "disclaimer": '*The price shown is the per piece price and does not include VAT. Depending on local conditions, packaging and freight charges might not be included. Where these charges should be part of the total price, they are included in the quote. The price given is a non-binding recommended retail price (RRP), which is the nVent list price. The final sales prices of local distributors may vary. Quotations requested or generated on SCHROFF.nVent.com are only valid for commercial customers (B2B). The lead time displayed above is an estimate for this item to be dispatched from an nVent Schroff facility. Transit delivery times are not included in this estimate, as transit time may vary, depending on delivery address, parcel dimensions, and other factors. Cut-outs and modifications placed in non-recommended areas ("collision detected") for configured products may be subject to additional engineering and/or design costs, which may increase initially quoted prices.',
    }
  };

  return cart.user == null ? (
    <Fragment>
      <h1 class="commerce-cart__title">{dynamicText.cartLabels.mycart}</h1>
      <div class="commerce-cart__main_container">
        <div class="div-table product-orders-table js-product-orders-table-exists">
          <div class="div-table__inner">
            {cart.products.length > 0 && (
              <div class="div-table__row div-table__heading">
                <div class="div-table__cell th--image"></div>
                <div class="div-table__cell th--catalog #">{dynamicText.cartLabels.cnum}</div>
                <div class="div-table__cell th--item">{dynamicText.cartLabels.desc}</div>
                {!apiPricingDisabled && (
                  <div class="div-table__cell th--list-price">
                    {dynamicText.cartLabels.lPrice}
                  </div>
                )}
                {!apiPricingDisabled && (
                  <div class="div-table__cell th--your-price">
                    {dynamicText.cartLabels.yPrice}
                  </div>
                )}
                <div class="div-table__cell th--quantity">{dynamicText.cartLabels.quant}</div>
                <div class="div-table__cell th--actions">{dynamicText.cartLabels.act}</div>
                {!apiPricingDisabled && (
                  <div class="div-table__cell th--sub-total">{dynamicText.cartLabels.sub}</div>
                )}
              </div>
            )}
            {cart.products.length > 0 ? (
              cart.products.map((product, index: number) => {
                return (
                  <CartItem
                    key={product.id}
                    t={t}
                    root={props.root}
                    product={product}
                    index={index}
                    componentsToggle={() => (toggleProductComponents(index))}
                    loadingStr={loadingStr}
                  ></CartItem>
                );
              })
            ) : (
              <div class="div-table__row div-table__body">
                <div class="commerce-cart__empty">{dynamicText.cartLabels.cartempty}</div>
              </div>
            )}
          </div>
        </div>
        {!apiPricingDisabled && (
          <div class="commerce-cart__disclaimer sku-body-field">
            {cart.products.length > 0 && (
              <p class="paragraph-xs">
                {dynamicText.cartLabels.disclaimer}
              </p>
            )}
          </div>
        )}
      </div>
      <CartSidebar t={t} cart={cart}></CartSidebar>
      {cart.showComponents && (
        <div
          class="commerce-cart__overlay"
          onClick={() => (toggleProductComponents())}
        ></div>
      )}
      {cart.showComponents && cart.componentIndex >= 0 && (
        <ProductComponents
          t={t}
          closeHandler={() => (toggleProductComponents(cart.componentIndex))}
          index={cart.componentIndex}
          products={cart.products}
        />
      )}
    </Fragment>
  ) : cart.ready ? (
    <SalesforceCartIframe t={t} src={props.iframe}></SalesforceCartIframe>
  ) : (
    <div class="commerce-cart__loading"></div>
  );
}

export default RxJsCommerceCartApp;
