/**
 * cta-buttons
 */

// Module dependencies
import 'protons';

// Module styles
import './_cta-buttons.scss';

// Module template
import './_cta-buttons.twig';

export const name = 'cta-buttons';

export function disable() {}

export function enable() {}

export default enable;
