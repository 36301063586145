import { RxJsModelBase } from '../shared/RxJsModelBase';
import { IRxjsModule } from '../shared/Interface/IRxjsModule';
import { CommerceModule } from './CommerceModule';

export class PriceBlockModule extends RxJsModelBase implements IRxjsModule {
  private static instance: PriceBlockModule;
  private init: boolean;
  /**
   * Private constructor to enforce Singleton
   */
  private constructor() {
    super();
    this.init = false;
  }

  /**
   * Singleton model
   */
  static getInstance(): PriceBlockModule {
    if (!PriceBlockModule.instance) {
      PriceBlockModule.instance = new PriceBlockModule();
    }
    return PriceBlockModule.instance;
  }

  /**
   * Return the CSS selctor of subscribers
   */
  getSubscribersSelector() {
    return 'price-block, commerce-cart';
  }

  /**
   * Setting namespace for drupal behavior
   */
  getNamespace(): string {
    return 'purchaseblock';
  }

  /**
   * Updates the user sent to the components
   * @param userID
   */
  updateUser(userID: string | null): void {
    if (userID) {
      this.dispatchOutputEvent('updateUser()', userID);
    }
  }

  /**
   * Behavior implmentation
   * @param context Drupal behavior context
   * @param settings Drupal Settings
   */
  behavior(context, settings) {
    // Using Drupal Jquery
    const priceBlockElements = context.querySelectorAll(
      this.getSubscribersSelector()
    );

    for (var i = 0; i < priceBlockElements.length; i++) {
      let priceBlock = priceBlockElements[i];
      if (!priceBlock.classList.contains('wc--loaded')) {
        priceBlock.classList.add('wc--loaded');
        // Notify endpoint ready
        priceBlock.dispatch('pricingAPIReady()', {});
      }
      // Enable the Shipping API for Eldon products.
      if ((window as any).drupalSettings.site.brand === 'hoffman' &&
        (window as any).drupalSettings.eldon !== undefined &&
        (window as any).drupalSettings.eldon.sku &&
        ((window as any).drupalSettings.eldon.show_prices || (window as any).drupalSettings.eldon.show_stock)) {
        priceBlock.dispatch('shippingApiReady()', {});
      }
      if (!this.init) {
        this.init = true;
        // Make Priceblock module a listener of this module propagations
        CommerceModule.getInstance()
          .getOutputObservable()
          .subscribe(this.reducer);
      }
    }
  }

  /**
   *  PriceBlockReducer
   */
  reducer(data) {
    if (data.key) {
      const self = PriceBlockModule.getInstance();
      switch (data.key) {
        case 'addProductToCart()':
          // Product additions is centralized in commerce
          CommerceModule.getInstance().dispatch(
            'addProductToCart()',
            data.value,
            data.elem
          );
          break;
        case 'addProductToFavorites()':
          // Favs additions is centralized in commerce
          CommerceModule.getInstance().dispatch(
            'addProductToFavorites()',
            data.value,
            data.elem
          );
          break;
        case 'addProductToRequest()':
          // Request additions is centralized in commerce
          CommerceModule.getInstance().dispatch(
            'addProductToRequest()',
            data.value,
            data.elem
          );
          break;
        case 'log()':
          console.log(data.value);
          break;
        case 'refreshQuantity()':
          PriceBlockModule.getPriceBlockPricingData(
            data.value.id,
            data.value.quantity,
            data.value.type
          )
            .then((res) => {
              data.elem.dispatch('updatePrice()', res);
            })
            .catch((xhr) => {
              console.log(xhr);
            });
          break;
        case 'updateUser()':
          self.updateUser(data.value);
          break;
        case 'updateSkuShippingInfo()':
          // Update the shipping info for Eldon products.
          PriceBlockModule.updateSkuShippingInfo(
            data.value.id,
            data.value.quantity
          )
            .then((res) => {
              data.elem.dispatch('showSkuShippingInfo()', res);
            })
            .catch((xhr) => {
              console.error(xhr);
            });
          break;
      }
    }
  }

  /**
   * Adjust currency values to symbols
   * @param data Fix currency on product Data
   */
  static adjustPricingCurrency(data) {
    const getPrice = (str: string) => {
      switch (str) {
        case 'USD':
          return 'USD ';
        case 'EUR':
          return '€';
        case 'GBP':
          return '£';
        case 'CNY':
          return '¥';
        default:
          return str + ' ';
      }
    };
    Object.values(data.items).forEach((product) => {
      const currency = getPrice(product.listPrice.currency);
      product.netPrice.currency = currency;
      product.listPrice.currency = currency;
    });
  }

  /**
   * Get updated quantity Data SF API
   * @param productId
   * @param quantity
   * @param type
   */
  static getPriceBlockPricingData(
    productId: string,
    quantity: number,
    type: string
  ) {
    const $ = (window as any).jQuery;
    // Get full drupal Settings
    const drupalSettings = (window as any).drupalSettings;
    const siteBrand = (window as any).drupalSettings.site.brand;
    var isWedgeLok_flag:boolean = 0;
    if(window.location.href.indexOf("cart") > -1) {
      let cartContent_wedgelok = JSON.parse(localStorage.getItem("commerce_cart_offline_" + siteBrand) || "");
      cartContent_wedgelok.products.map((product) => {
      if (product.id == productId) {
        isWedgeLok_flag = product.isWedgeLok;
      }
     });
    }

    return new Promise((resolve, reject) => {
      // If authenticated then add user ID
      const currentUser = CommerceModule.getInstance().getUser();
      const item = {};
      item[productId] = {
        qty: quantity,
        type: type,
      };
      $.ajax({
        url: `/${drupalSettings.region_manager.current_prefix}/api/pricing`,
        data: {
          items: item,
          sf_uid: currentUser,
        },
        method: 'GET',
        crossDomain: true,
        timeout: 30000,
      })
        .done(function (data) {
          data.user = currentUser;
          PriceBlockModule.adjustPricingCurrency(data);
          resolve(data);
        })
        .fail(function (xhr) {
          reject(xhr);
        });
    });
  }

  /**
   * Get price and availability information using multiple products.
   * @param products array of product objects with:
   *   - productId: string,
   *  - quantity: number,
   *  - components = [] Product components if available
   */
  static getPriceBlockPricingDataMultipleItems(products: Array<any>) {
    const items = products.map((p) => {
      return {
        // Strip dashes on the itemNumber.
        itemNumber: p.id,
        quantity: p.qty,
        unitOfMeasure: null,
        isWedgeLok: p.isWedgeLok,
        components: p.components.map((product) => {
          return {
            // Strip dashes on the itemNumber.
            itemNumber: product.itemNumber,
            quantity: product.quantity,
            unitOfMeasure: null,
            properties: [],
          };
        }),
      };
    });
    const $ = (window as any).jQuery;
    // Get full drupal Settings
    const drupalSettings = (window as any).drupalSettings;
    return new Promise((resolve, reject) => {
      const endpoint = '/api/pricing';
      let data: any = {
        countryOfUser: drupalSettings.region_manager.current_country.toUpperCase(),
        items,
      };
      // If authenticated then add user ID
      const currentUser = CommerceModule.getInstance().getUser();
      if (currentUser) {
        data.userId = currentUser;
      }

      $.ajax({
        url: endpoint,
        data,
        method: 'GET',
        crossDomain: true,
        dataType: 'json',
        timeout: 30000,
      })
        .done(function (data) {
          data.user = currentUser;
          PriceBlockModule.adjustPricingCurrency(data);
          resolve(data);
        })
        .fail(function (xhr) {
          reject(xhr);
        });
    });
  }

  /**
   * Update Shipping Info (Price & Availability) based on sku quantity
   * through M3 API.
   * @param productId
   * @param quantity
   */
  static updateSkuShippingInfo(
    productId: string,
    quantity: number
  ) {
    const $ = (window as any).jQuery;
    // Get full drupal Settings
    const drupalSettings = (window as any).drupalSettings;
    // Current locale.
    const locale =
      drupalSettings.region_manager.current_language + '-' + drupalSettings.region_manager.current_country;

    return new Promise((resolve, reject) => {
      const endpoint = '/' + locale + '/api/shipping';

      let data: any = {
        itemNumber: productId,
        quantity: quantity
      };
      // If authenticated then add user ID
      const currentUser = CommerceModule.getInstance().getUser();
      if (currentUser) {
        data.userId = currentUser;
      }
      $.ajax({
        url: endpoint,
        data,
        method: 'GET',
        crossDomain: true,
        dataType: 'json',
        timeout: 30000,
      })
        .done(function (data) {
          data.user = currentUser;
          resolve(data);
        })
        .fail(function (xhr) {
          reject(xhr);
        });
    });
  }
}
