/**
 * Card
 */

// Module dependencies
import 'protons';
import 'atoms/image';
import 'atoms/button';

// Module template
import './_gated-card.twig';
import './gated-card-horizontal/_gated-card-horizontal.twig';
import './gated-card-product/_gated-product-card.twig';
import './gated-related-product-card/_gated-related-product-card.twig';

// Module styles
import './_gated-card.scss';
import './gated-card-horizontal/_gated-card-horizontal.scss';
import './gated-card-product/_gated-product-card.scss';
import './gated-related-product-card/_gated-related-product-card.scss';

export const name = 'card';

export function disable() {}

export function enable() {}

export default enable;
