import {h} from 'preact';
import {useEffect, useState} from 'preact/hooks';

const queryString = require('query-string');

function RxJsWedgelokConfiguratorApp(props) {
  const parsed = queryString.parse(location.search);

  // useState hook - setup dropdown API state.
  const [options, setOptions] = useState({
    classification: {
      editable: [],
      noneditable: [],
    },
    configuratorList: [{grid: {fieldGridList: []}}],
  });

  // Setup selectionPath.
  let selectionPath = parsed.selectionPath || '';

  // useEffect hook - fires on first component render.
  useEffect(() => {
    fetchPartNumberData(props.partid, {}, 'PartNumberData').catch((reason => {
      console.error(reason)
    }));
  }, []);

  const pricingBlock: any = document.querySelector('price-block');
  const $ = (window as any).jQuery;

  // Shorthand for drupal t
  function t(str: string, args = {}): string {
    return props.root.t(str, args);
  }

  // Updates the browser URL with the selection path and returns the updated
  // selection path
  function updateBrowserURL() {
    let url = window.location.href.split('?')[0];
    let urlhash = url.split('#')[1];
    url = url.split('#')[0];
    let parsedParams = queryString.parse(location.search);
    parsedParams.selectionPath = selectionPath;
    let newURL = `${url}?${queryString.stringify(parsedParams)}`
    if (urlhash) {
      newURL = `${newURL}#${urlhash}`
    }
    window.history.pushState({path: newURL}, '', newURL);
    return queryString.stringify(parsedParams);
  }

  // Dynamically updates the Mectronics Resellers table based on the part
  // number
  function getUpdatedResellersTable(partNum: string) {
    const $resellersTable = $('#availability-content');
    const $resellersTableHidden = $('#availability-content.table--hidden');
    const drupalSettings = (window as any).drupalSettings;
    const selectedCountryLang = "/" + drupalSettings.region_manager.current_language + '-' + drupalSettings.region_manager.current_country;
    const endpoint = selectedCountryLang + '/api/availability/' + partNum;
    const countryOfUser = drupalSettings.region_manager.current_country.toUpperCase();
    return new Promise((resolve, reject) => {
      $.ajax({
        url: endpoint,
        method: 'GET',
        data: {
          countryOfUser,
        },
        crossDomain: true,
        dataType: 'json',
        timeout: 30000,
      })
        .done(function (data) {
          if (
            $resellersTable.length &&
            data.StockCheck &&
            data.StockCheck.status
          ) {
            const payload = data.StockCheck;
            const $tbody = $('tbody', $resellersTable);
            $tbody.empty();
            const keys = ['Quantity', 'InvDate'];
            for (let i = 0; i < payload.rCount; i += 1) {
              const $trow = $(document.createElement('tr'));
              const $distLink = $(document.createElement('td'));
              const distContent = `
          <a href="${payload.resultlist[i].Buy}"
          class="link--distributor-title" target="_blank">${payload.resultlist[i].DistName}
          </a>
          `;
              $distLink.html(distContent);
              $trow.append($distLink);
              for (let j = 0; j < keys.length; j += 1) {
                const $tdata = $(document.createElement('td'));
                $tdata.html(payload.resultlist[i][keys[j]]);
                $trow.append($tdata);
              }
              const $buyLink = $(document.createElement('td'));
              const linkContent = `
              <a href="${payload.resultlist[i].Buy}"
              class="link link--alt" target="_blank">Shop
                <svg class="icon" aria-hidden="true" role="img">
                  <use xlink:href="/themes/custom/particle/dist/app-drupal/assets/images/sprite.svg#arrow-right"></use>
                </svg>
              </a>
              `;
              $buyLink.html(linkContent);
              $trow.append($buyLink);
              $tbody.append($trow);
            }
          }
          if ($resellersTableHidden.length) {
            $resellersTable.removeClass('table--hidden');
            if ($(window).width() > 919) {
              $('.Resellers-In-Your-Area').removeClass('hidden');
            } else {
              $('[data-tab="Resellers-In-Your-Area"]').removeClass('hidden');
            }
          }
          pricingBlock.dispatch('addAvailability()');
        })
        .fail(function (xhr) {
          if (!$resellersTableHidden.length) {
            $resellersTable.addClass('table--hidden');
          }
          pricingBlock.dispatch('removeAvailability()');
          console.error(xhr);
        });
    });
  }

  // Updates the download card iframe based on the part number
  function updateCADDownloadIframe(data, $downloadCard, $innerDownloadCard) {
    if ($('.download-card.download-card--loaded').length) {
      $('#download-card-iframe').attr('src', data.viewer_ui);
    } else {
      $('.download-card__card iframe').remove();
      $('.download-card__card img').remove();
      const ifrm = document.createElement('iframe');
      ifrm.setAttribute('src', data.viewer_ui);
      ifrm.id = 'download-card-iframe';
      $innerDownloadCard.prepend(ifrm);
      $('.download-card__dropdown').addClass('download-card__dropdown--loaded');
      $innerDownloadCard
        .find('select.form-item__select')
        .on('change', function () {
          const url = this.value;
          $innerDownloadCard.find('.download-card__link a').attr('href', url);
        });
      $downloadCard.addClass('download-card--loaded');
    }
    $downloadCard.show();
  }

  // Dynamically updates the download options for the download card based on
  // the part number
  function updateCADDownloadOptions(options, $downloadCard) {
    $downloadCard.find('select.form-item__select').html('');
    let counter = 0;
    for (let option in options.options) {
      const opt = document.createElement('option');
      opt.value = option;
      opt.innerHTML = options.options[option];
      $downloadCard.find('select.form-item__select').append(opt);
      if (counter == 0) {
        $downloadCard.find('.download-card__link a').attr('href', option);
        $downloadCard.find('.download-card__link a').attr('target', '_blank');
      }
      counter++;
    }
  }

  // Prepares the login to download button
  function prepareCADDownloadLoginButton($downloadCard) {
    $downloadCard.find('.form-item').html('');
    const icon = $downloadCard.find('.button--secondary svg').clone();
    $downloadCard
      .find('.button--secondary')
      .text((window as any).Drupal.t('login to download'))
      .attr('href', '')
      .append(icon)
      .on('click', function (event) {
        event.preventDefault();
        (window as any).SFIDWidget.login();
      });
  }

  // Returns data to be used for the CAD download card
  function getUpdatedCADDownload(partId: string, selectionPath: string) {
    const $downloadCard = $('.download-card');
    const $innerDownloadCard = $('.download-card__card');
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/api/traceparts/viewer',
        method: 'GET',
        data: {
          part_id: partId,
          selection_path: selectionPath,
          check_availability: 0,
          brand: 'SCHROFF',
        },
      })
        .done(function (iframeData) {
          // Check authentication state
          $.ajax({
            url: '/ajax/ecommerce/user',
            data: {},
            method: 'POST',
            crossDomain: true,
            dataType: 'json',
            timeout: 30000,
          })
            .done(function (userData) {
              // Verify user
              const user = userData.authenticated ? userData.username : null;
              if (!!user) {
                $.ajax({
                  url: '/api/traceparts/options',
                  method: 'GET',
                  data: {
                    part_id: partId,
                    selection_path: selectionPath,
                    brand: 'SCHROFF',
                  },
                })
                  .done(function (options) {
                    updateCADDownloadOptions(options, $downloadCard);
                    updateCADDownloadIframe(
                      iframeData,
                      $downloadCard,
                      $innerDownloadCard
                    );
                  })
                  .fail(function (xhr) {
                    prepareCADDownloadLoginButton($innerDownloadCard);
                    updateCADDownloadIframe(
                      iframeData,
                      $downloadCard,
                      $innerDownloadCard
                    );
                    console.error(xhr);
                  }).always(() => {
                    $(".download-card .loading-indicator").hide();
                  });
              } else {
                prepareCADDownloadLoginButton($innerDownloadCard);
                updateCADDownloadIframe(
                  iframeData,
                  $downloadCard,
                  $innerDownloadCard
                );
              }
            })
            .fail(function (xhr) {
              // If auth could not be verified
              prepareCADDownloadLoginButton($innerDownloadCard);
              updateCADDownloadIframe(
                iframeData,
                $downloadCard,
                $innerDownloadCard
              );
              console.error(xhr);
            }).always(() => {
              $(".download-card .loading-indicator").hide();
            });
        })
        .fail(function (xhr) {
          // If there is no CAD Download available
          $downloadCard.hide();
          $downloadCard.removeClass('download-card--loaded');
          reject(xhr);
        }).always(() => {
         $(".download-card .loading-indicator").hide();
        });
    });
  }

  /**
   * Function to add data to dynamic product.
   */
  function addProductToDynamicProduct(product: Object) {
    let data = {};
    data = {
      items: product,
    };
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/dp/create',
        data,
        method: 'POST',
        crossDomain: true,
        dataType: 'json',
        timeout: 30000,
      })
        .done(function (data) {
          resolve(data);
        })
        .fail(function (xhr) {
          reject(xhr);
        });
    });
  }

  // Generates a different Wedge Lok part # based on the facet dropdown
  // selections. This happens only on the onChange event.
  function handleChange(event) {

    // Construct the query param needed for the dropdown change.
    let tpParams = {
      'symbol': event.target.getAttribute('data-tp-symbol'),
      'value': event.target.value,
    };

    fetchPartNumberData(props.partid, tpParams, 'PartNumberDataUpdate');
  }

  // Returns data to be used for building the facet dropdowns based on the
  // configuration location/part id
  function fetchPartNumberData(partID: string, tpParams: any, url: string) {
    $(".wedgelock .loading-indicator").show();
    return new Promise((resolve, reject) => {
      const endpoint = `/api/traceparts/${url}`;
      let data: any = {
        partFamilyCode: partID,
      };
      if (selectionPath !== '') {
        data.selectionPath = selectionPath;
      }
      if (!$.isEmptyObject(tpParams)) {
        data = {...data, ...tpParams};
      }
      $.ajax({
        url: endpoint,
        data,
        method: 'GET',
        crossDomain: true,
        dataType: 'json',
        timeout: 30000,
      })
        .done(data => {
          $(".wedgelock .loading-indicator").hide();
          let classification = {
            'editable': [],
            'noneditable': [],
          };
          for (let facetSelector of data.configuratorList[0].grid.fieldGridList) {
            if (facetSelector.valuesFieldGrid.length < 2) {
              classification.noneditable.push(facetSelector);
            }
            else {
              classification.editable.push(facetSelector);
            }
          }
          data.classification = classification;

          // Set options state.
          setOptions(data);

          // Set selection path.
          selectionPath = data.globalInfo.partFamilyInfo.selectionPath;

          // Set Part number.
          const partNum = data.bomFields[2].value;
          $('.product-hero__catalog--number').text(partNum);

          // Update the browser URL with the selectionPath.
          const updatedSelectionPath = updateBrowserURL();

          // Fire the pricing block updates.
          const queryVars = `${updatedSelectionPath}&sourceId=ENC_${props.sourceid}`;
          // Add Product to the dynamic products table.
          addProductToDynamicProduct({
            id: partNum,
            type: 'CALMARK',
            original_id: JSON.parse($(pricingBlock).attr('product')).original_catalog_id,
            additional_data: {
              urlparams: queryVars,
            },
          });
          pricingBlock.dispatch('updateProductSKU()', {partNum, queryVars});

          // Update the CAD download and resellers.
          // Only update the 3D model if the iframe is already loaded.
          // This is just to boost the perfomance to avoid api
          // calls on page load.
          if ($('.download-card iframe').length) {
            getUpdatedCADDownload(partID, selectionPath);
          }
          getUpdatedResellersTable(partNum);
        })
        .fail(xhr => {
          reject(xhr)
        });
    });
  }

  return (
    <div class="wedgelock-components">
      <div class="configuration-section-parts row">
        {options.classification.editable.map((facetSelector: any) => (
          <div class="facet-selectors__dropdown col-xs-12 col-6" key={facetSelector.label}>
            <div class="form-item">
              <label for="select" class="form-item__label form-item__label--textfield">{facetSelector.label}</label>
              <div class="form-item__dropdown">
                <select
                  class="form-item__select"
                  aria-label={facetSelector.label}
                  onChange={handleChange}
                  value={facetSelector.value}
                  data-tp-symbol={facetSelector.symbol}
                >
                  {facetSelector.valuesFieldGrid.map((opt: any) => (
                    <option value={opt.value} selected={opt.selected === 'selected'}>
                      {opt.label ? opt.label : opt.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        ))}
      </div>
      {options.classification.noneditable.length != 0 && (
        <div class="configuration-section-parts non-editable row">
          {options.classification.noneditable.map((facetSelector: any) => (
            <div class="facet-selectors__dropdown col-xs-12 col-6" key={facetSelector.label}>
              <div class="form-item">
                <label for="select" class="form-item__label form-item__label--textfield">{facetSelector.label}</label>
                <div class='form-item__dropdown_disabled'>
                  <select
                    class="form-item__select"
                    aria-label={facetSelector.label}
                    disabled={true}
                    onChange={handleChange}
                    value={facetSelector.value}
                    data-tp-symbol={facetSelector.symbol}
                  >
                    {facetSelector.valuesFieldGrid.map((opt: any) => (
                      <option value={opt.value} selected={opt.selected === 'selected'}>
                        {opt.label ? opt.label : opt.value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default RxJsWedgelokConfiguratorApp;
