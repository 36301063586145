/**
 * Carousel
 */

import $ from 'jquery';
import 'slick-carousel';
import '../../../../../apps/drupal/js/zoom-nvent.min';

// Module dependencies
import 'protons';
import 'atoms/image';

// Module template
import './_carousel.twig';

// Module styles
import './_carousel.scss';

export const name = 'carousel';

export const defaults = {
  interval: 3000,
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  // Find carousel elements on the page
  const $carousels = $('.carousel', $context);
  const $numSlides = $(
    '.product-gallery__main .carousel__nav .carousel__item',
    $context
  );
  // Bail if none exist
  if (!$carousels.length) {
    return;
  }

  function initCarousel($carouselWrapper) {
    const $carouselMain = $carouselWrapper.find('.carousel__main');
    const $carouselNav = $carouselWrapper.find('.carousel__nav');

    $carouselMain
      .slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: $carouselMain.data('autoslide'),
        autoplaySpeed: '5000',
        dots: true,
        fade: true,
        arrows: false,
        mobileFirst: true,
        accessibility: true,
        cssEase: 'ease',
        respondTo: 'window',
        touchMove: true,
        infinite: false,
        responsive: [
          {
            breakpoint: '1024',
            settings: {
              dots: $carouselMain.data('indicators'),
            },
          },
        ],
      })
      .on('beforeChange', function pauseVideo(event, slick, currentSlide) {
        const $youtubeVideo = slick.$slides.eq(currentSlide).find('iframe');
        if ($youtubeVideo.length) {
          $youtubeVideo[0].contentWindow.postMessage(
            JSON.stringify({ event: 'command', func: 'pauseVideo' }),
            '*'
          );
        }
      });
    $carouselNav.slick({
      slidesToShow: $carouselWrapper.hasClass('carousel--vertical') ? 4 : 5,
      slidesToScroll: 1,
      vertical: true,
      asNavFor: $carouselMain,
      dots: false,
      centerMode: true,
      focusOnSelect: true,
      autoplay: false,
      infinite: false,
    });

    // Change active slide on hover
    $carouselNav.on('mouseenter', '.slick-slide', function changeActiveSlide(
      e
    ) {
      const index = $(e.currentTarget).data('slick-index');
      $carouselMain.slick('getSlick').slickGoTo(index);
      $carouselNav.slick('getSlick').slickGoTo(index);
    });
  }

  // For Slider adding class to remove transform if less than or equal 4
  if ($numSlides.length <= 4) {
    // Add a CSS class to the slider-nav
    $('.carousel__nav').addClass('small-slides');
  }

  // Initiate slick
  $carousels.each(function initiateSlick(i, el) {
    initCarousel($(el));
    if ($('.product-gallery .carousel__main-item img').length) {
      const url = $('.product-gallery .carousel__main-item img').attr('src'); // Get the src attribute of the image
      const carouselImg = new Image();
      carouselImg.src = url;
      const zoomOptions = {
        zoomType: 'window',
        zoomWindowWidth: 600,
        zoomWindowHeight: window.innerWidth > 1400 ? 380 : 380,
        zoomWindowPosition: 1,
        zoomWindowOffetx: window.innerWidth > 1400 ? 50 : 50,
        zoomWindowOffety: -1,
        gallery: 'gallery_01',
        galleryActiveClass: 'active',
        easing: true,
        borderSize: 2,
        lensOpacity: 0.5,
        lensColour: '#d7d7d7',
        lensBorderColour: '#9a9b9c',
        lensSize: 100,
        borderColour: '#9a9b9c',
        cursor: 'crosshair',
        scrollZoom: true,
        scrollZoomIncrement: 0.5,
      };
      if ($(window).width() >= 1200 && carouselImg.width > 500) {
        $(
          '.product-gallery__main .carousel__main .slick-current img'
        ).elevateZoom(zoomOptions);

        $('.carousel__enlarge').show();
        const beforeChangeHandler = (event, slick, currentSlide) => {
          $.removeData(currentSlide, 'elevateZoom');
          $('.zoomContainer').remove();
        };

        const afterChangeHandler = () => {
          $(
            '.product-gallery__main .carousel__main .slick-current img'
          ).elevateZoom(zoomOptions);
        };

        $('.product-gallery__main .carousel__main').on(
          'beforeChange',
          beforeChangeHandler
        );
        $('.product-gallery__main .carousel__main').on(
          'afterChange',
          afterChangeHandler
        );
      }
    }
  });
  $(document).on('ajaxSuccess', function reinitSlider() {
    setTimeout(function sliderTimeout() {
      $('.carousel__main .slick-dots li:not(.slick-active) button').trigger(
        'click'
      );
    }, 1000);
  });
}

export default enable;
