import { h } from 'preact';

export function SalesforceCartIframe({ t, src }) {
  src += `?locale=${window.drupalSettings.region_manager.current_language}`;
  return (
    <iframe
      class="commerce-cart__iframe"
      src={src}
      title={t('my cart')}
    ></iframe>
  );
}
