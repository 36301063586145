import { h, render } from 'preact'
import { RxJsWebComponentBase } from '../../shared/RxJsWebComponentBase'
import {IRxjsWebComponent} from '../../shared/Interface/IRxjsWebComponent'
import RxJsPriceApp from './app/RxJsPriceApp'
export default class PriceBlockComponent extends RxJsWebComponentBase implements IRxjsWebComponent  {

  /**
   * App initializing method
   * @param props Web Component properties
   */
  _initApp(props) {
    render(
      <RxJsPriceApp {...props} />,
      this
    )
  }
}
