/**
 * category-card-list
 */

/* eslint-disable */

import $ from 'jquery';

// Module dependencies
import './close.svg';

// Module template
import './_category-card-list.twig';

export const name = 'category-card-list';

export const defaults = {
  dummyClass: 'js-category-card-list-exists',
};

const categoryCards = {
  drupal: !$('body.pl').length,
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable(context) {
  if (categoryCards.drupal) {
    if (typeof dataLayer !== 'undefined' && dataLayer.length > 0 && dataLayer[0].hasOwnProperty('entityTaxonomy') && dataLayer[0].entityTaxonomy.hasOwnProperty('nvent_pim_product_line')) {
      const $market = dataLayer[0].entityTaxonomy.nvent_pim_product_line;
      const marketTid = Object.keys($market)[0];
      $('.category-card__list .button', context).on('click', (e) => {
        e.preventDefault();
        const url = `${e.currentTarget.href}&market=${marketTid}`;
        document.location = url;
      });
    }

    document.addEventListener('DOMContentLoaded', () => {
      function resizeModal() {
        const widthBrowser = window.innerWidth;
        const widthPercentage = widthBrowser * 0.6;
        $('#drupal-modal', context).dialog({
          title: '',
          autoOpen: false,
          width: widthPercentage,
          resizable: true,
          classes: {
            'ui-dialog': 'card-modal',
          },
          show: { effect: 'blind', duration: 800 },
        });
      }

      resizeModal();

      window.addEventListener('resize', () => {
        resizeModal();
      });

      $(document).ajaxStart(() => {
        $('*').addClass('loading');
      });

      $(document).ajaxComplete(() => {
        $('*').removeClass('loading');
        resizeModal();
      });
    });
  }
}

export default enable;
