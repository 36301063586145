import $ from 'jquery';
import { RxJsModelBase } from '../shared/RxJsModelBase';
import { IRxjsModule } from '../shared/Interface/IRxjsModule';
import { PriceBlockModule } from './PriceBlockModule';
import { ConfiguratorsModule } from './ConfiguratorsModule';
import Quantity from '../web-components/price-block/app/Quantity';

// Main E-commerce controller
export class CommerceModule extends RxJsModelBase implements IRxjsModule {
  // Static instance for singleton
  private static instance: CommerceModule;
  // Salesforse User auth state

  private user: null | string;

  private loadingStr;
  /**
   * Private constructor to enforce Singleton
   */
  private constructor() {
    super();
    this.user = null;
    this.loadingStr = `(...${this.t('loading')})`;
  }

  /**
   * Singleton model
   */
  static getInstance(): CommerceModule {
    if (!CommerceModule.instance) {
      CommerceModule.instance = new CommerceModule();
    }
    return CommerceModule.instance;
  }

  /**
   * Setting namespace for drupal behavior
   */
  getNamespace(): string {
    return 'ecommerceModule';
  }

  /**
   * Checks if apiPricing is disabled (Currently disabled for data-solutions)
   */
  isApiPricingDisabled(): boolean {
    return (window as any).drupalSettings.site.brand !== 'schroff';
  }

  /**
   * Checks if Shipping APIs are enabled (Feature available only for Eldon).
   */
  isApiShippingEnabled(): boolean {
    return (
      (window as any).drupalSettings.site.brand === 'hoffman' &&
      (window as any).drupalSettings.eldon !== undefined &&
      (window as any).drupalSettings.eldon.sku &&
      ((window as any).drupalSettings.eldon.show_prices ||
      (window as any).drupalSettings.eldon.show_stock)
    );
  }

  /**
   * Return elements to subscribe (CSS Selector)
   */
  getSubscribersSelector() {
    return 'commerce-cart,commerce-icon';
  }

  setLocalStorage() {
    if ((window as any).drupalSettings.site.brand !== 'data-solutions') {
      return 'commerce_cart_offline';
    }
    return 'commerce_cart_offline_' + (window as any).drupalSettings.site.brand;
  }

  /**
   * Events listener
   * @param data {key: method name, value: payload}
   */
  reducer(data) {
    const ctx = CommerceModule.getInstance();
    if (data.key) {
      switch (data.key) {
        case 'addProductToCart()':
          // Add product service
          ctx.addProductToCart(data);
          break;
        case 'addProductToFavorites()':
          // Add product service
          ctx.addProductToFavorites(data);
          break;
        case 'addProductToRequest()':
          // Add product service
          ctx.addProductToRequest(data);
          break;
        case 'checkRegionInvariance()':
          // Product additions is centralized in commerce
          ctx.checkRegionCartInvariant();
          break;
        case 'removeCartProduct()':
          // Product additions is centralized in commerce
          ctx.removeCartProduct(data.value);
          break;
        case 'showAlertMessage()':
          const payload = {
            type: 'error',
            replacements: {},
            message: '',
            ...data.value,
          };
          ctx.showAlert(payload.message, payload.type, payload.replacements);
          break;
        case 'updateCartProduct()':
          // Product additions is centralized in commerce
          ctx.updateCartItemQuantity(
            data.value.id,
            data.value.quantity,
            data.value.index
          );
          break;
      }
    }
  }

  /**
   * Behavior implmentation
   * @param context Drupal behavior context
   * @param settings Drupal Settings
   */
  behavior(context, settings) {
    // This module will react only once and it will be the main module
    // Authentication state will be handled here and distributed to the other modules.
    const body = context.querySelector('body');
    if (body && !body.classList.contains('ecommerce-js')) {
      body.classList.add('ecommerce-js');
      // enable price block
      PriceBlockModule.getInstance().enable();
      // Enable Coinfgurators module
      ConfiguratorsModule.getInstance().enable();
      // Get user async and update state in auth web components/ modules
      this.checkAuthState().then((authState: any) => {
        this.user = authState.authenticated ? authState.userId : null;
        // Notify User
        this.dispatchOutputEvent('updateUser()', this.user);
        // Notify and send the cart state
        this.notifyCartUpdate();
      });
    }

    $("a.link--add-to-cart:not(.add-to-cart-processed), .link--add-to-cart > a:not(.add-to-cart-processed)")
      .addClass('add-to-cart-processed')
      .click(function (e) {
        e.preventDefault();
        const payload = $(this).data('add-to-cart-payload');
        if (payload) {
          const drupalSettings = (window as any).drupalSettings;
          payload['brand'] = drupalSettings.site.brand.toUpperCase();
          const lang = `${drupalSettings.region_manager.current_language}-${drupalSettings.region_manager.current_country}`;
          payload.url = `${window.location.origin}/${lang}${payload.url}`;
          CommerceModule.getInstance().dispatch('addProductToCart()', payload);
        }
      });

    $(document).on('click', '#request-button', function(e) {
      e.preventDefault();
      $('#request-dialog, #request-overlay', context).remove();
    });
  }

  /**
   * Async method to check if the user is authenticated
   */
  checkAuthState() {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/ajax/ecommerce/user',
        data: {},
        method: 'POST',
        crossDomain: true,
        dataType: 'json',
        timeout: 30000,
      })
        .done(function (data) {
          resolve(data);
        })
        .fail(function (xhr) {
          reject(xhr);
        });
    });
  }

  /**
   * Translates an item from the local storage offline cart into a salesforce API cart object
   * @param item
   */
  salesforceEcommerceApiProductConversor(item) {
    const region_manager = (window as any).drupalSettings.region_manager;
    const lang =
      region_manager.current_language + '-' + region_manager.current_country;
    let product: any = {
      itemNumber: item.id,
      quantity: item.qty,
      itemImage: item.image,
      itemDescription: item.name,
      englishItemDescription: item.englishName,
      isWedgeLok: item.isWedgeLok,
    };
    if (item.components && item.components.length > 0) {
      product['components'] = item.components.map((comp) => {
        comp[
          'skuUrl'
        ] = `${window.location.origin}/${lang}/p/ENC_${comp.itemNumber}`;
        return comp;
      });
      product[
        'configuratorCopyURL'
      ] = `${window.location.origin}/${lang}${item.configuratorCopyURL}`;
      product[
        'configuratorModifyURL'
      ] = `${window.location.origin}/${lang}${item.configuratorModifyURL}`;
      product['zipURL'] = item.zipURL;
      product['sapGUID'] = item.sapGUID;
      product['configurationID'] = item.configurationID;
    } else {
      // Only NON-iframed configured items have skuUrls
      product['skuUrl'] =
        item.url !== ''
          ? `${window.location.origin}/${lang}/p/ENC_${item.id}?${item.queryVars}`
          : '';
    }
    return product;
  }

  /**
   * Notify subscribers about changes in the cart.
   */
  notifyCartUpdate() {
    let cartContent = this.getCartContent();
    if (!this.getUser()) {
      this.dispatchOutputEvent('cartInfoOfflineUpdate()', cartContent);
    } else {
      // If SF user does not have access to Cart page, remove items from cart.
      // Else continue with normal cart flow.
      if ((window as any).drupalSettings.eldonAddToCartCheck !== undefined && !(window as any).drupalSettings.eldonAddToCartCheck) {
        localStorage.removeItem(this.setLocalStorage());
      }
      else {
        // cart merge needs to occur here once (it might remove the local data once merged)
        let products = cartContent.cart;
        if (Object.keys(products).length > 0) {
          // Merge cart contents
          this.cartApiAdd(cartContent, 'cart')
            .then((res) => {
              console.log('Cart synced Succesfully', res);
              this.dispatchOutputEvent('cartInfoOnlineUpdate()', res);
              if(localStorage.configuration_id) {
                return new Promise((resolve, reject) => {
                  $.ajax({
                    url: '/configurator-mapping/update',
                    data: {
                      sf_uid: this.getUser(),
                      config_id: localStorage.getItem('configuration_id')
                    },
                    dataType: 'json',
                    timeout: 30000,
                  })
                    .done(function (data) {
                      resolve(data);
                      localStorage.removeItem('configuration_id');
                    })
                    .fail(function (xhr) {
                      reject(xhr);
                    });
                });
              }
              localStorage.removeItem(this.setLocalStorage());
            })
            .catch((err) => {
              this.showAlert(
                "Error - Your cart items couldn't been synced ",
                'error'
              );
            });
        } else {
          this.getCartApi()
            .then((res) => {
              // this.dispatchOutputEvent('loadIframe()', res);
              this.dispatchOutputEvent('cartInfoOnlineUpdate()', res);
            })
            .catch((err) => {
              // this.dispatchOutputEvent('loadIframe()', false);
              this.dispatchOutputEvent('cartInfoOnlineUpdate()', false);
              console.error(err);
            });
        }
      }
    }
  }

  /**
   * Returns the cart state from the corresponding storage
   */
  getCartContent() {
    return localStorage.getItem(this.setLocalStorage())
      ? JSON.parse(localStorage.getItem(this.setLocalStorage()) + '')
      : { cart: {}, lastUpdate: 0 };
  }

  /**
   * Add product to the shopping cart
   * @param data Object with:
   *   - elem: The DOMElement that generated the request
   *   - key: The reducer task "addProductToCart()"
   *   - value: Object with:
   *      - id: Product Catalog Number
   *      - qty: Product Quantity
   *      - image: Product Image
   *      - name: Product Name
   *      - items: array of accesories (configurators only)
   */
  addProductToCart(data): void {
    if (!this.user) {
      this.addProductAnonymousUser(data);
      // Notify new cart state
      this.notifyCartUpdate();
    } else {
      this.addProductAuthUser(data);
    }
  }

  /**
   * Add product to the whish list
   * @param data Object with:
   *   - elem: The DOMElement that generated the request
   *   - key: The reducer task "addProductToCart()"
   *   - value: Object with:
   *      - id: Product Catalog Number
   *      - qty: Product Quantity
   *      - image: Product Image
   *      - name: Product Name
   */
  addProductToFavorites(data): void {
    if (this.user) {
      const product = data.value;
      let payload = { wishlist: {} };
      payload.wishlist[data.value.brand] = {};
      payload.wishlist[data.value.brand][product.id] = {
        qty: data.value.qty,
      };
      this.cartApiAdd(payload, 'wishlist')
        .then((res) => {
          // If item exists mention item is already in your wishlist.
          if (res.exists) {
            this.showAlert(
              '@name // @id is already in your wishlist',
              'success',
              { '@name': product.name, '@id': product.id }
            );
          }
          else {
            this.showAlert(
              Drupal.t('Success - @name // @id added to your wishlist'),
              'success',
              { '@name': product.name, '@id': product.id }
            );
          }
          data.elem.dispatch('productAdded()', data);
        })
        .catch((err) => {
          this.showAlert(
            Drupal.t('Error - @name // @id  could not be added to your  wishlist'),
            'error',
            { '@name': product.name, '@id': product.id }
          );
          data.elem.dispatch('unlock()');
        });
    }
  }

  /**
   * Add Request product to the localStorage cart
   * @param data Object with:
   *   - elem: The DOMElement that generated the request
   *   - key: The reducer task "addProductToRequest()"
   *   - value: Object with:
   *      - id: Product Catalog Number
   *      - qty: Product Quantity
   *      - image: Product Image
   *      - name: Product Name
   *      - url: Product URL
   *      - netValues: {yourPrice:number, listPrice: number, currency: string}
   *      - options: List of quantity options
   *      - overrideExistingItem: boolean
   *      - queryVars: any additional query vars to append to the URL
   */
  addProductToRequest(data): void {
    if (!this.user) {
      (window as any).SFIDWidget.login();
      data.elem.dispatch('unlock()');
    } else {
      const product = data.value;
      const payload = {
        itemNumber: product.id,
        isWedgeLok: product.isWedgeLok,
        quantity: product.qty,
        itemImage: product.image,
        skuUrl: product.url,
        itemDescription: product.name,
        englishItemDescription: product.englishName,
      };
      this.makeSampleRequest(payload).then((res) => {
        $('.main-content').append(res.markup);
        data.elem.dispatch('unlock()');
      })
      .catch((err) => {
        this.showAlert(
          Drupal.t('Error - @name // @id  could not be added to your Request a sample product'),
          'error',
          {'@name': product.name, '@id': product.id}
        );
        data.elem.dispatch('unlock()');
      });
    }
  }

  /**
   * Add product to the localStorage cart
   * @param data Object with:
   *   - elem: The DOMElement that generated the request
   *   - key: The reducer task "addProductToCart()"
   *   - value: Object with:
   *      - id: Product Catalog Number
   *      - qty: Product Quantity
   *      - image: Product Image
   *      - name: Product Name
   *      - url: Product URL
   *      - netValues: {yourPrice:number, listPrice: number, currency: string}
   *      - options: List of quantity options
   *      - overrideExistingItem: boolean
   *      - queryVars: any additional query vars to append to the URL
   */
  addProductAnonymousUser(data) {
    let cartContent = this.getCartContent();
    // It always add the cart no matters if it already exists in cart NVENT-968
    cartContent.lastUpdate = new Date().getTime();
    let brand = data.value.brand;

    if (!cartContent.cart.hasOwnProperty(brand)) {
      cartContent.cart[brand] = {};
    }

    if (!cartContent.cart[brand].hasOwnProperty(data.value.id)) {
      cartContent.cart[brand][data.value.id] = {};
      cartContent.cart[brand][data.value.id]['qty'] = 0;
    }

    cartContent.cart[brand][data.value.id]['qty'] += parseInt(data.value.qty);

    if (!this.isApiPricingDisabled()) {
    } else {
      // checkInvariant
      // this.checkRegionCartInvariant();
      // Notify changes
      this.notifyCartUpdate();
    }

    // Setting item
    localStorage.setItem(this.setLocalStorage(), JSON.stringify(cartContent));

    // Updating local storage
    this.showAlert(Drupal.t('Success - @name // @sku added to your cart'), 'success', {
      '@name': data.value.name,
      '@sku': data.value.id,
    });

    setTimeout(() => {
      data.elem.dispatch('productAdded()', data);
    }, 1000);
  }

  /**
   * Region needs to match current configuration for anonymous cart
   */
  checkRegionCartInvariant(context = null) {
    const region_manager = (window as any).drupalSettings.region_manager;
    const currentRegion = region_manager.current_country.toUpperCase();
    let cart = null;
    if (context) {
      cart = context;
    } else {
      cart = this.getCartContent();
    }
    // Update collectively
    const dict = [];
    let items = [];
    cart.products.map((product, index: number) => {
      if (product.region !== currentRegion) {
        dict.push(index);
        items.push(product);
      }
    });
    if (items.length > 0) {
      // Put all elements in waiting state
      items.map((pitem, index) => {
        const i = dict[index];
        this.putCartItemInWaitingState(cart, i, pitem.qty);
      });
      localStorage.setItem(this.setLocalStorage(), JSON.stringify(cart));
      this.notifyCartUpdate();

      if (!this.isApiPricingDisabled()) {
        // Get all prices simultaneusly
        PriceBlockModule.getPriceBlockPricingDataMultipleItems(items).then(
          (pricingData: any) => {
            const pricedItems = pricingData.items;
            pricedItems.map((pitem: any, index) => {
              const i = dict[index];
              this.updateProductOnIndexWithPricingData(
                pricingData,
                cart,
                i,
                index
              );
            });
            localStorage.setItem(this.setLocalStorage(), JSON.stringify(cart));
            // Notify changes
            this.notifyCartUpdate();
            this.checkRegionCartInvariant(cart);
          }
        );
      }
    }
  }

  /**
   * Add product to the authenticated user via API
   * @param data Object with:
   *   - elem: The DOMElement that generated the request
   *   - key: The reducer task "addProductToCart()"
   *   - value: Object with:
   *      - id: Product Catalog Number
   *      - qty: Product Quantity
   *      - image: Product Image
   *      - name: Product Name
   *      - components: Product Components
   */
  addProductAuthUser(data) {
    const product = data.value;
    let payload = { cart: {} };
    payload.cart[data.value.brand] = {};
    payload.cart[data.value.brand][product.id] = {
      qty: data.value.qty,
    };
    this.cartApiAdd(payload, 'cart')
      .then((res) => {
        this.showAlert(
          Drupal.t('Success - @name // @id  added to your cart'),
          'success',
          { '@name': product.name, '@id': product.id }
        );
        data.elem.dispatch('productAdded()', data);
        if (this.user) {
          this.dispatchOutputEvent('cartInfoOnlineUpdate()', res);
        }
      })
      .catch((err) => {
        this.showAlert(
          Drupal.t('Error - @name // @id  could not be added to your cart'),
          'error',
          { '@name': product.name, '@id': product.id }
        );
        data.elem.dispatch('unlock()');
      });
  }

  /**
   * Updates a product to set it in waiting state.
   * @param cartContent
   * @param index
   * @param quantity
   */
  putCartItemInWaitingState(cartContent, index, quantity) {
    const region_manager = (window as any).drupalSettings.region_manager;
    cartContent.products[index]['listPrice'] = '--';
    cartContent.products[index]['yourPrice'] = '--';
    cartContent.products[index]['availability'] = this.loadingStr;
    cartContent.products[index][
      'region'
    ] = region_manager.current_country.toUpperCase();
    cartContent.products[index]['qty'] = quantity;
    cartContent.lastUpdate = new Date().getTime();
  }

  /**
   * Updates payload from pricing api and configurators urls in the cart items
   * @param pdata The data received by pricing API
   * @param cartContent Current offline cart Data
   * @param index The index of the product in the cart data.
   * @param pindex The inner index within the pricing data
   */
  updateProductOnIndexWithPricingData(pdata, cartContent, index, pIndex = 0) {
    const region_manager = (window as any).drupalSettings.region_manager;
    const lang =
      region_manager.current_language + '-' + region_manager.current_country;
    const newData: any = pdata;
    const quantity = parseInt(newData.items[pIndex].quantity);
    const listPrice = newData.items[pIndex].listPrice.value;
    const yourPrice = newData.items[pIndex].netPrice.value;
    const currency = newData.items[pIndex].netPrice.currency;
    cartContent.products[index]['listPrice'] = currency + listPrice;
    cartContent.products[index]['yourPrice'] = currency + yourPrice;
    cartContent.products[index]['netValues'] = {};
    cartContent.products[index]['netValues']['listPrice'] = listPrice;
    cartContent.products[index]['netValues']['yourPrice'] = yourPrice;
    cartContent.products[index]['netValues']['currency'] = currency;
    cartContent.products[index]['availability'] = newData.items[0].leadTime;
    cartContent.products[index]['qty'] = quantity;
    // Update URLs with the latest region
    cartContent.products[index]['url'] =
      cartContent.products[index]['url'] !== ''
        ? `${window.location.origin}/${lang}/p/ENC_${cartContent.products[index]['id']}?${cartContent.products[index]['queryVars']}`
        : '';
    if (
      cartContent.products[index].components &&
      cartContent.products[index].components.length > 0
    ) {
      cartContent.products[index]['components'] = cartContent.products[
        index
      ].components.map((comp) => {
        comp[
          'skuUrl'
        ] = `${window.location.origin}/${lang}/p/ENC_${comp.itemNumber}`;
        return comp;
      });
    }
    if (!this.isApiPricingDisabled()) {
      // Quantity Options
      let price = newData.items[pIndex];
      if (cartContent.products[index]['isWedgeLok'] && (window as any).drupalSettings.site.brand === 'schroff') {
        price.MOQ = 100;
      }
      cartContent.products[index]['options'] = Quantity(price);
    }
  }

  /**
   * Updates an existing item quantity
   * @param id catalog number
   * @param quantity
   * @param index the index within the cart data
   * @param sharedContext binded context if available
   */
  updateCartItemQuantity(id, quantity, index: number, sharedContext = null) {
    let cartContent = null;
    if (sharedContext) {
      cartContent = sharedContext;
    } else {
      cartContent = this.getCartContent();
    }
    this.putCartItemInWaitingState(cartContent, index, quantity);
    localStorage.setItem(this.setLocalStorage(), JSON.stringify(cartContent));

    if (!this.isApiPricingDisabled()) {
      // Async callback for updating quantities
      // Update the cart quantity prices async and then call notify cart again.
      PriceBlockModule.getPriceBlockPricingData(
        id,
        quantity,
        cartContent.products[index]['components']
      ).then((pdata) => {
        this.updateProductOnIndexWithPricingData(pdata, cartContent, index);
        localStorage.setItem(
          this.setLocalStorage(),
          JSON.stringify(cartContent)
        );
        // Notify changes
        this.notifyCartUpdate();
        // Verify Invariant
        this.checkRegionCartInvariant(cartContent);
      });
    }
    else {
      this.notifyCartUpdate();
    }
  }

  /**
   * Remove Product from Cart
   * @param index: The position in the array of products to remove
   */
  removeCartProduct(index: number) {
    let cartContent = this.getCartContent();
    cartContent.products.splice(index, 1);
    cartContent.lastUpdate = new Date().getTime();
    localStorage.setItem(this.setLocalStorage(), JSON.stringify(cartContent));
    this.notifyCartUpdate();
  }

  /**
   * Centralized method to get the current user
   */
  getUser() {
    return this.user;
  }

  /**
   * Shows a Drupal message alert
   * @param msg: The string using translatable syntax .ie. 'My fav number is: @number'
   * @param type The message type (error|success)
   * @param replacements: The object with the key replacements {'@number': 7}
   */
  showAlert(msg: string, type: string = 'error', replacements = {}): void {
    const Drupal = (window as any).Drupal;
    Object.keys(replacements).map((key) => {
      replacements[key] = replacements[key].replace('"', '“');
    });
    const message = Drupal.t(msg, replacements);
    Drupal.ajax({
      url: `/ajax/alert?msg=${encodeURI(message)}&type=${type}`,
    }).execute();
  }

  /**
   * Function to make a sample request.
   */
  makeSampleRequest(product: Object) {
    let data = {};
    data = {
      cartType: 'sample_request',
      items: [product],
      sf_uid: this.user,
    };
    const region_manager = (window as any).drupalSettings.region_manager;
    const lang =
      region_manager.current_language + '-' + region_manager.current_country;

    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/${lang}/api/sample-request`,
        data,
        method: 'POST',
        crossDomain: true,
        dataType: 'json',
        timeout: 30000,
      })
        .done(function (data) {
          resolve(data);
        })
        .fail(function (xhr) {
          reject(xhr);
        });
    });
  }

  /**
   * Get updated quantity Data SF API
   */
  cartApiAdd(products: Object, cartType: string = 'cart', extra = {}) {
    let data = {};

    data = {
      ...extra,
      cartType,
      items: products,
      sf_uid: this.user,
    };

    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/cart/add',
        data,
        method: 'POST',
        crossDomain: true,
        dataType: 'json',
        timeout: 30000,
      })
        .done(function (data) {
          resolve(data);
        })
        .fail(function (xhr) {
          reject(xhr);
        });
    });
  }

  /**
   * Get cart items count.
   */
  getCartApi() {
    return new Promise((resolve, reject) => {
      let data = {
        sf_uid: this.user,
      };
      $.ajax({
        url: '/cart/count',
        data,
        method: 'GET',
        crossDomain: true,
        dataType: 'json',
        timeout: 30000,
      })
        .done(function (data) {
          resolve(data);
        })
        .fail(function (xhr) {
          reject(xhr);
        });
    });
  }
}
