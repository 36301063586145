import { h, Fragment } from 'preact';
import { useEffect, useReducer } from 'preact/hooks';
import { AddToCart } from './components/AddToCart';
import { AddToFavorites } from './components/AddToFavorites';
import { AddToRequest } from './components/AddToRequest';
import { ProductInfo } from './components/ProductInfo';
import { QuantitySelector } from './components/QuantitySelector';
import { ProductSuccessor } from './components/ProductSuccessor';
import Quantity from './Quantity';
import { CommerceModule } from "../../../models/CommerceModule";
import {SkuShippingInfo} from "./components/SkuShippingInfo";

declare var Drupal;
let productData = null;
function RxJsApp(props) {
  const alwaysAllowAddToCart = (window as any).drupalSettings.nVent.eCommerce.enabled;
  let initialState = JSON.parse(props.product);
  productData = initialState.id;
  // Add inital options
  initialState.options = [];
  initialState.selectedQuantity = null;
  initialState.user = null;
  initialState.product = {};
  initialState.ready = alwaysAllowAddToCart;
  initialState.noPricing = false;
  initialState.showComponents = true;
  initialState.showAvailability = true;
  initialState.queryVars = '';

  // Brand
  const brand = (window as any).drupalSettings.site.brand;
  // Language
  const region_manager = (window as any).drupalSettings.region_manager;
  const lang =
    region_manager.current_language + '-' + region_manager.current_country;
  // Eldon check
  const eldon_check = (window as any).drupalSettings.eldon;
  // Eldon: Add to cart check
  const eldonAddToCartCheck = (window as any).drupalSettings.eldonAddToCartCheck !== undefined ?
    (window as any).drupalSettings.eldonAddToCartCheck : true;

  // Shothand for drupal t
  function t(str: string, args = {}): string {
    return props.root.t(str, args);
  }

  // Reducer
  const reducer = (productState, message) => {
    switch (message.key) {
      case 'updatePrice()':
        if (message.value.errorFlag === 'false') {
          // list Price
          if (typeof(message.value.items) == 'object') {
            var price = Object.values(message.value.items)[0];
          } else {
            var price = message.value.items[0];
          }

          if (productState.isWedgeLok && (window as any).drupalSettings.site.brand === 'schroff') {
            price.MOQ = 100;
          }

          // Quantity Options
          productState.options = Quantity(price);

          if (!productState.selectedQuantity) {
            productState.selectedQuantity = productState.options[0] ?? 1;
            const payload = {
              id: productState.id,
              quantity: productState.selectedQuantity,
              type: productState.type,
            };
            props.root.dispatchOutputEvent('refreshQuantity()', payload);
            return { ...productState };
          }
          productState.list_price = `${price.listPrice.currency
              ? price.listPrice.currency
              : 'USD '
            }${price.listPrice.value.trim()}`;

          // Your Price
          productState.price.title = `${price.netPrice.currency
              ? price.listPrice.currency
              : 'USD '
            }${price.netPrice.value}`;

          // Add net values
          productState.netValues = {
            yourPrice: Number(price.netPrice.value),
            listPrice: Number(price.listPrice.value.trim()),
            currency: price.netPrice.currency
              ? price.listPrice.currency
              : 'USD ',
          };
          // Availability
          product.days = price.leadTime;
          if (product.days == "0") {
            productState.alert = Drupal.t('leadtime available upon request');
          } else {
            productState.alert = Drupal.t(`Ships within @days working days`, {
              '@days': price.leadTime,
            });
          }
          productState.ready = true;

          // Updating noPricing flag
          productState.noPricing =
            productState.netValues.yourPrice == 0 ||
            productState.netValues.listPrice == 0;
          return { ...productState };
        }
        return productState;
      case 'updateQuantity()':
        if (message.value === productState.selectedQuantity) {
          break;
        }
        productState.selectedQuantity = message.value;
        if (!CommerceModule.getInstance().isApiPricingDisabled() || CommerceModule.getInstance().isApiShippingEnabled()) {
          productState.ready = false;
        }
        productState.price.title = '--';
        productState.notice = '';
        productState.showDetails = false;
        return { ...productState };
      case 'log()':
        console.log(message);
        return productState;
      case 'updateUser()':
        productState.user = message.value;
        return { ...productState };
      case 'lock()':
        return { ...productState, ready: false };
      case 'unlock()':
        return { ...productState, ready: true };
      case 'productAdded()':
        productState.ready = true;
        return { ...productState };
      case 'pricingAPIReady()':
        if (CommerceModule.getInstance().isApiPricingDisabled()) {
          productState.options = Array.from({ length: 100 }, (_, i) => i + 1);
          productState.selectedQuantity = 1;
        } else {
          // Only Dispatch refreshQuantity() if this is not a wedgeLok product.
          // Otherwise dispatch refreshQuantity() once the wedgeLok configurator is fully loaded, see updateProductSKU().
          if (!productState.isWedgeLok) {
            const payload = {
              id: productState.id,
              quantity: 1,
              type: productState.type
            };
            props.root.dispatchOutputEvent('refreshQuantity()', payload);
          }
        }
        return { ...productState };
      case 'shippingApiReady()':
        const payload = { id: productState.id, quantity: 1 };
        props.root.dispatchOutputEvent('updateSkuShippingInfo()', payload);
        return { ...productState };
      case 'removeAvailability()':
        return { ...productState, showAvailability: false };
      case 'addAvailability()':
        return { ...productState, showAvailability: true };
      case 'updateProductSKU()':

        if (productState.isWedgeLok && (window as any).drupalSettings.site.brand === 'schroff') {
          const payload = {
            id: message.value.partNum,
            quantity: productState.selectedQuantity ?? 100,
            type: productState.type,
          };
          props.root.dispatchOutputEvent('refreshQuantity()', payload);
        }
        else {
          const payload = {
            id: message.value.partNum,
            quantity: 1,
            type: productState.type,
          };
          props.root.dispatchOutputEvent('refreshQuantity()', payload);
        }
        return {
          ...productState,
          id: message.value.partNum,
          queryVars: message.value.queryVars,
        };
      case 'showSkuShippingInfo()':
        product.price_label = Drupal.t('Price per unit:');
        product.catalog_label = Drupal.t('Catalog#:');
        product.list_quantity_label = Drupal.t('Quantity');
        if (message.value.notice) {
          product.notice = message.value.notice;
        }
        else if (message.value.pricing_error) {
          product.showDetails = true;
          product.notice = message.value.pricing_error;
          product.deliveryPlan = message.value.deliveryPlan ?? [];
        }
        else if (message.value.delivery_error) {
          product.showDetails = true;
          product.showPricing = true;
          product.notice = message.value.delivery_error;
          product.price.title = message.value.unitPrice ?? '--';
          product.totalPrice = message.value.totalPrice ?? '--';
        }
        else {
          product.showDetails = true;
          product.showPricing = true;
          product.price.title = message.value.unitPrice ?? '--';
          product.totalPrice = message.value.totalPrice ?? '--';
          product.deliveryPlan = message.value.deliveryPlan ?? [];
        }
        return { ...productState, ready: true };
      default:
        return productState;
    }
  };

  // Use Reducer
  const [product, dispatch] = useReducer<any, any>(reducer, initialState);

  // Select Quantity
  function selectQuantity(event) {
    const option = event.target.value;
    const data = { key: 'updateQuantity()', value: option };
    dispatch(data);
    if (!CommerceModule.getInstance().isApiPricingDisabled()) {
      const payload = {
        id: product.id,
        quantity: option,
        type: product.type,
      };
      props.root.dispatchOutputEvent('refreshQuantity()', payload);
    }
    // Check if shipping api is enabled for Eldon products.
    else if (CommerceModule.getInstance().isApiShippingEnabled()) {
      const payload = { id: product.id, quantity: option };
      props.root.dispatchOutputEvent('updateSkuShippingInfo()', payload);
    }
  }

  // Add to Cart
  function addToCart(event) {
    event.preventDefault();
    const data = { key: 'lock()', value: false };
    dispatch(data);
    const pTitle: HTMLElement = document.querySelector('.product-hero__title');
    const elNameEnglish: HTMLElement = document.querySelector('div.product-title');
    let url = product.url;
    if (product.queryVars) {
      url += product.queryVars;
    }

    const payload = {
      id: product.id,
      // isWedgeLok: product.isWedgeLok,
      qty: product.selectedQuantity,
      name: pTitle ? pTitle.innerText : '',
      // englishName: elNameEnglish ? elNameEnglish.innerText : '',
      // image: `${window.location.origin}${product.image}`,
      // listPrice: (product.list_price + '').replace('*', ''),
      // yourPrice: product.price.title,
      // availability: product.days,
      // url: `${window.location.origin}${url}`,
      // options: product.options,
      // queryVars: product.queryVars,
      // netValues: product.netValues,
      brand: product.site,
    };
    props.root.dispatchOutputEvent('addProductToCart()', payload);
  }

  // Add product to Favorites
  function addToFavorites(event) {
    event.preventDefault();
    const data = { key: 'lock()', value: false };
    dispatch(data);
    const pTitle: HTMLElement = document.querySelector('.product-hero__title');
    const elNameEnglish: HTMLElement = document.querySelector('div.product-title');
    let url = product.url;
    if (product.queryVars) {
      url += product.queryVars;
    }
    const payload = {
      id: product.id,
      isWedgeLok: product.isWedgeLok,
      qty: product.selectedQuantity,
      name: pTitle ? pTitle.innerText : '',
      englishName: elNameEnglish ? elNameEnglish.innerText : '',
      image: `${window.location.origin}${product.image}`,
      listPrice: (product.list_price + '').replace('*', ''),
      yourPrice: product.price.title,
      availability: product.days,
      queryVars: product.queryVars,
      url: `${window.location.origin}${url}`,
      options: product.options,
      netValues: product.netValues,
      brand: product.site,
    };
    props.root.dispatchOutputEvent('addProductToFavorites()', payload);
  }

  // Add product to Request a sample product
  function addToRequest(event) {
    event.preventDefault();
    const data = { key: 'lock()', value: false };
    dispatch(data);
    const pTitle: HTMLElement = document.querySelector('.product-hero__title');
    const elNameEnglish: HTMLElement = document.querySelector('div.product-title');
    const payload = {
      id: product.id,
      isWedgeLok: product.isWedgeLok,
      qty: product.selectedQuantity,
      name: pTitle ? pTitle.innerText : '',
      englishName: elNameEnglish ? elNameEnglish.innerText : '',
      image: `${window.location.origin}/p/ENC_${product.id}/i`,
      listPrice: (product.list_price + '').replace('*', ''),
      yourPrice: product.price.title,
      availability: product.days,
      queryVars: product.queryVars,
      url: `${window.location.origin}/${lang}/p/ENC_${product.id}?${product.queryVars}`,
      options: product.options,
      netValues: product.netValues,
    };
    props.root.dispatchOutputEvent('addProductToRequest()', payload);
  }

  // UseEffect HOOK
  useEffect(() => {
    let subscription = null;
    const service = props.root.getPropertiesService();
    subscription = service.subscribe((data) => {
      dispatch(data);
    });
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  var dynamicText = {
    "blockLabels": {
      "usd": "Please note, that there is a USD 500 line-item requirement.",
      "quantity": "If you need another quantity, please reach out to your point of sale. You can find them ",
      "here": "here",
      "dot": ".",
    }
  };
  return (
    <div class={`purchase-block ${product.ready && 'purchase-block--ready'}`}>
      {product.market_info.market_status === 'obsolete' &&
        (window as any).drupalSettings.site.brand === 'schroff' ? (
        <div className="row row--catalog-obsolete">
          <span>
            {product.catalog_label ? product.catalog_label : t('Catalog#:')}
          </span>
          <span className="catalog-id">{product.id}</span>
          <p class="purchase-block__schroff-obsolete purchase-block__schroff-message c-red-med">
            {t('This catalog item is no longer available')}
          </p>
          {}
        </div>
        ) : ''}
      {product.market_info.sucessors_components && product.market_info.sucessors_components.length > 0 &&
        <ProductSuccessor
          t={t}
          successor={product.market_info.sucessors_components[0]}
          product={product}
        ></ProductSuccessor>
      }
      {product.market_info.market_status !== 'obsolete' ? (
        <Fragment>
          <ProductInfo t={t} product={product}></ProductInfo>
          <QuantitySelector
            t={t}
            product={product}
            selectQuantityHandler={selectQuantity}
          ></QuantitySelector>
          {product.isWedgeLok && (window as any).drupalSettings.site.brand === 'schroff' && (
            <div class="purchase-block__sales-locator wedge-lok-product"><span>{dynamicText.blockLabels.usd}{dynamicText.blockLabels.quantity}
              <a href={`/${lang}/solutions/schroff/applications/sales-and-distributor-locations`}> {dynamicText.blockLabels.here}</a>{dynamicText.blockLabels.dot}</span>
            </div>
          )}
          {product.user && (window as any).drupalSettings.site.brand === 'hoffman' &&
            eldon_check.sku &&
            (eldon_check.show_prices || eldon_check.show_stock) && (
            <SkuShippingInfo product={product}></SkuShippingInfo>
          )}
          {eldonAddToCartCheck && (window as any).drupalSettings.show_schroff_features && (
            <AddToCart
              addToCartHandler={addToCart}
              t={t}
              product={product}
            ></AddToCart>
          )}
          {product.isWedgeLok && (window as any).drupalSettings.site.brand ==='schroff' && (window as any).drupalSettings.show_schroff_features && (
            <AddToRequest
              AddToRequestHandler={addToRequest}
              t={t}
              product={product}
            ></AddToRequest>
          )}
          {product.user && (window as any).drupalSettings.show_schroff_features && (
            <AddToFavorites
              addToFavoritesHandler={addToFavorites}
              t={t}
              product={product}
            ></AddToFavorites>
          )}
        </Fragment>
      ) : ''}
      {product.market_info.market_status === 'obsolete' &&
        (window as any).drupalSettings.site.brand === 'hoffman' ? (
          <div>
            <p class="purchase-block__obsolete purchase-block__message">
              {t('This catalog item is no longer available')}
            </p>
            { }
          </div>
        ) : ''
      }
      {!product.market_info.sucessors_components && product.noPricing && !product.isWedgeLok &&
        <div>
          <a href={`/${lang}/solutions/schroff/applications/sales-and-distributor-locations`} class="button button--secondary w-full mt-4 purchase-block__contact-us">
            {t('Contact us')}
          </a>
        </div>
      }

      {product.showAvailability && brand == 'schroff' && (
        <a
          href="#availability-content"
          class="link link--alt purchase-block__availability"
        >
          <svg class="icon" aria-hidden="true" role="img">
            <use
              xlinkHref={`/themes/custom/particle/dist/app-drupal/assets/images/sprite.svg#sprite-shop`}
            ></use>
          </svg>
          {product.list_dist_label
            ? product.list_dist_label
            : t('Local Reseller')}
        </a>
      )}
    </div>
  );
}

export default RxJsApp;
