import { h } from 'preact';

export function ProductSuccessor({ t, successor, product }) {
  const brand = (window as any).drupalSettings.site.brand;
  return (
    <div className={ brand === 'schroff' ? "purchase-block__schroff-successor" : "purchase-block__successor"}>
      <p className={ brand === 'schroff' ? "purchase-block__schroff-message" : "purchase-block__message"}>
        {brand === 'schroff' &&
        product.market_info.market_status === 'obsolete'
          ? t('Item is obsolete. Click below for recommended replacement.')
          : brand === 'schroff' &&
          product.market_info.market_status === 'phaseout'
            ? <span><span className="c-red-med">{t('Please Note:')} </span>
              {t('This item is not recommended for new designs. Click the link below for the newer version.')}</span>
          : ''}
        {brand === 'hoffman' &&
        product.market_info.market_status === 'phaseout'
          ? Drupal.t(
              'Item is being phased out; soon to become obsolete or superseded.'
            )
          : brand === 'hoffman' &&
            product.market_info.market_status === 'obsolete'
          ? Drupal.t('Item is obsolete. Click below for recommended replacement.')
          : ''}
      </p>

      <div className={ brand === 'schroff' ? "purchase-block__schroff-successor-item" : "purchase-block__successor-item"}>
        {successor.image && (
          <img src={successor.image} alt={successor.name}></img>
        )}
        <a href={successor.url}>{successor.name}</a>
      </div>
    </div>
  );
}
