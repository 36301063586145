import 'protons';
import 'jquery-match-height';

// Module template
import './_grid.twig';
import './_grid-1-up.twig';
import './_grid-2-up.twig';
import './_grid-3-up.twig';
import './_grid-4-up.twig';

// Module styles
import './_grid.scss';

export const name = 'grid';

export function disable() {}

export function enable() {}

export default enable;
