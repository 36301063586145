/**
 * contact-block
 */

// eslint-disable-next-line no-unused-vars
import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_catalog-user-block.scss';

// Module template
import './_catalog-user-block.twig';

export const name = 'catalog-user-block';

export function disable() {}

export function enable() {}

export default enable;
