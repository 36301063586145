/**
 * Card Grid
 */

import './_card-grid.twig';
import './_card-grid.scss';

export const name = 'card-grid';

export function disable() {}

export function enable() {}

export default enable;
