/**
 * purchase-card
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_purchase-card.scss';

// Module template
import './_purchase-card.twig';

export const name = 'purchase-card';

export const defaults = {
  dummyClass: 'js-purchase-card-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context, { purchaseCard = {} }) {
  // Find our component within the DOM
  const $purchaseCard = $('.purchase-card', $context);
  // Bail if component does not exist
  if (!$purchaseCard.length) {
    return;
  }
  // Merge defaults with incoming settings
  const settings = {
    ...defaults,
    ...purchaseCard,
  };
  // An example of what could be done with this component
  $purchaseCard.addClass(settings.dummyClass);

  $purchaseCard.each((i, el) => {
    const $el = $(el);
    const overflow = $el
      .find('.purchase-card__attributes__list__overflow')
      .height();
    const heightClosed = $el.find('.purchase-card__img_wrapper').height();
    const heightOpen = heightClosed - overflow;
    $el
      .find('.purchase-card__img_wrapper')
      .attr('height-closed', heightClosed)
      .attr('height-open', heightOpen);
    const purchaseCardAttrExpand = $el.find(
      '.purchase-card__attributes__expand'
    );

    purchaseCardAttrExpand.each(function handleClick() {
      this.onclick = function onClick() {
        const $this = $(this);
        const isOpen = $el
          .find('.purchase-card__attributes__list__overflow')
          .data('is-open');

        const heightOverflow = isOpen ? 0 : overflow;
        const $parentContainer = $this.closest(
          '.purchase-card__wrapper-content'
        );

        $parentContainer
          .find('.purchase-card__attributes__list__overflow')
          .animate({ height: heightOverflow }, 300, 'linear')
          .data('is-open', !isOpen);

        $this.toggleClass('open', !isOpen);

        const $imgEl = $parentContainer.find('.purchase-card__img_wrapper');
        let imgHeight;
        if (isOpen) {
          imgHeight = $imgEl.attr('height-closed');
        } else {
          imgHeight = $imgEl.attr('height-open');
        }
        $imgEl.animate({ height: imgHeight }, 300, 'linear');
      };
    });
    $el.find('.purchase-card__attributes__list__overflow').height(0).show();
  });
}

export default enable;
