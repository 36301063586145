/**
 * sku-detail
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_sku-detail.scss';

// Module template
import './_sku-detail.twig';

import anchorScroll from 'anchor-scroll';

export const name = 'sku-detail';

export const defaults = {
  dummyClass: 'js-sku-detail-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  const $adminBarCheck = $('#toolbar-administration');
  if ($context === document) {
    const offsetValue = $adminBarCheck.length
      ? 0
      : -1 * $('.mega-menu').first().height();
    // Enabling anchors
    anchorScroll.init({
      updateUrl: true,
      offset: offsetValue - 120,
      ease: 'in-quad',
      duration: 400,
      selector: ".container-product-item a[href*='#']",
    });

    // Load the correct anchor tag if a url hash is present.
    // Note: this is used in tabs blocks in sku pages.
    $(document).ready(function drd() {
      if (window.location.hash) {
        const $clickElem = $(`a[href="${window.location.hash}"]`);
        if ($clickElem.length) {
          setTimeout(function cto() {
            $clickElem[0].click();
          }, 400);
        }
      }
    });
  }
}

export default enable;
