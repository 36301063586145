/**
 * status
 */

// Module dependencies
import 'protons';

// Module styles
import './_status-messages-claro.scss';

// Module template
import './_status-messages-claro.twig';

export const name = 'status-messages-claro';

export const defaults = {
  dummyClass: 'js-status-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
// eslint-disable-next-line no-unused-vars
export function enable($context) {}

export default enable;
