/**
 * Base css generation and global js logic.
 */

// IE11 polyfills
// See docs: https://github.com/msn0/mdn-polyfills
import 'mdn-polyfills/Array.prototype.forEach';
import 'mdn-polyfills/Node.prototype.append';
import 'mdn-polyfills/Node.prototype.remove';

import './_base.scss';
// Export global variables.
export default {
  // Demo only, remove in practice
  GLOBAL_CONSTANT: 'particle',
};
