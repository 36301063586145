/**
 * measurement-system-toggle
 */

// Module dependencies
import $ from 'jquery';
import Cookies from 'js-cookie';
import get from 'lodash.get';

// Module styles
import './_measurement-system-toggle.scss';

// Module template
import './_measurement-system-toggle.twig';

export const name = 'measurement-system-toggle';

export const defaults = {
  dummyClass: 'js-measurement-system-toggle-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Enables measurementSystemToggle component.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context, settings) {
  const module = {
    /**
     * Module name.
     */
    name: 'measurementSystemToggle',
    /**
     * Drupal environment flag.
     */
    drupal: !!window.Drupal && !!window.Drupal.behaviors,

    /**
     * Initialized flag.
     */
    init: false,
    /**
     * Drupal behavior to attach.
     */
    instance: {
      state: {
        /**
         * Current language.
         */
        currentLanguage: get(
          window,
          'drupalSettings.region_manager.current_language',
          'en'
        ),
        /**
         * Current country.
         */
        currentCountry: get(
          window,
          'drupalSettings.region_manager.current_country',
          'us'
        ),
        /**
         * Current measurement system.
         */
        currentSystem: undefined,
        /**
         * Queue of handlers to be called on measurement system changes.
         */
        handlers: [],
      },
      attach(context) {
        const self = this;
        self.dispatchReadyEvent();
        self.triggerInit(context);
        self.setOnChangeListener();
        $(document, context).ready(function fn() {
          self.setDefault();
        });
      },
      /**
       * Initialize the measurement system change triggers.
       *
       * @param {HTMLDocument|HTMLElement} context
       *   An element to attach behaviors to.
       */
      triggerInit(context) {
        const self = this;
        $('.measurement-system-toggle', context)
          .once('measurement-system-toggle')
          .click((e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            const system = $(e.target).data('measurement-system');
            if (system) {
              self.set(system);
            }
          });

        self.onChange(function fn(system) {
          $(
            `.measurement-system-toggle[data-measurement-system=${system.current}]`
          ).addClass('active');
          $(
            `.measurement-system-toggle[data-measurement-system=${system.previous}]`
          ).removeClass('active');
        });
      },
      /**
       * Sets the measurement system.
       *
       * @param {string} system
       *   The measurement system to set (imperial or metric).
       */
      set(system) {
        // eslint-disable-next-line no-param-reassign
        system = system.toLowerCase();
        const systemSwitch = {
          metric: 'imperial',
          imperial: 'metric',
        };

        if (systemSwitch[system] === undefined) {
          return;
        }

        // eslint-disable-next-line no-shadow
        const setCookie = (system) => {
          const self = this;
          const cookiePath = module.drupal
            ? `/${self.state.currentLanguage}-${self.state.currentCountry}`
            : '/';
          Cookies.set('preferred-measurement-system', system, {
            path: cookiePath,
          });
        };

        if (this.state.currentSystem !== system) {
          this.state.currentSystem = system;
          const event = new CustomEvent('measurement-system-changed', {
            detail: { system },
          });
          document.dispatchEvent(event);
          setCookie(system);
        }
      },
      /**
       * Sets the default measurement system.
       *
       * If preferred measurement system cookie is not present, it checks the
       * current country from region manager, if current country is US or Canada
       * it defaults to imperial measurement system, metric otherwise.
       */
      setDefault() {
        const self = this;
        let system = Cookies.get('preferred-measurement-system');
        if (system === undefined) {
          system = ['us', 'ca'].includes(self.state.currentCountry)
            ? 'imperial'
            : 'metric';
        }
        self.set(system);
      },
      /**
       * Adds the given function to be called on measurement system changes.
       *
       * @param {function} fn
       *   The function to call.
       */
      onChange(fn) {
        if (typeof fn === 'function') {
          this.state.handlers.push(fn);
        }
      },
      /**
       * Sets the `on change` listener worker.
       *
       * On measurement system changes, calls to each function in the order
       * they were added by onChange().
       */
      setOnChangeListener() {
        const self = this;
        document.addEventListener('measurement-system-changed', function f(e) {
          const systemSwitch = {
            metric: 'imperial',
            imperial: 'metric',
          };

          if (systemSwitch[e.detail.system] === undefined) {
            return;
          }

          const system = {
            current: e.detail.system,
            previous: systemSwitch[e.detail.system],
          };
          self.state.handlers.forEach((fn) => {
            fn(system);
          });
        });
      },
      /**
       * Gets current measurement system.
       *
       * @returns {string}
       *   The current measurement system.
       */
      currentSystem() {
        return this.state.currentSystem;
      },
      /**
       * Dispatch MeasurementSystemToggleReady event.
       *
       * Recommended snippet to register a handler:
       *
       * const handler = (system) => {
       *   // Your code that reacts to measurement system changes goes here.
       *   console.log(system.current);
       *   console.log(system.previous);
       * };
       * if (Drupal.behaviors.measurementSystemToggle) {
       *   Drupal.behaviors.measurementSystemToggle.onChange(handler);
       * } else {
       *   document.addEventListener('MeasurementSystemToggleReady',
       *     function fn(e) {
       *       e.detail.toggle.onChange(handler);
       *     }
       *   );
       * }
       */
      dispatchReadyEvent() {
        const self = this;
        const ev = new CustomEvent('MeasurementSystemToggleReady', {
          detail: { toggle: self },
        });
        document.dispatchEvent(ev);
      },
    },
  };
  if (!module.drupal) {
    window.Drupal = {
      behaviors: {},
    };
  }

  if (window.Drupal.behaviors[module.name] === undefined) {
    window.Drupal.behaviors[module.name] = module.instance;
  }

  if (module.drupal) {
    module.instance.attach($context[0], settings);
  } else {
    window.onload = () => {
      console.log(`${module.name} loaded.`);
      module.instance.attach(document, {});
      module.instance.onChange((system) => {
        console.log(
          'Call this console.log on measurement system change',
          system
        );
        $('pre.visualizer').text(
          `Measurement system changed! from: ${system.previous} to ${system.current}`
        );
      });
    };
  }
}

export default enable;
