/**
 * button-dropdown
 */

import $ from 'jquery';

// Module styles
import '../../01-atoms/tooltip/_tooltip.scss';
import './_download-specs.scss';

// Module template
import './_download-specs.twig';

export const name = 'download-specs';

// Max allowed rows for PDF generation.
const MAX_ROWS = 200;

// Message to show when Print This Page (Download Specifications) button is disabled.
const MESSAGE = 'Select filters below before printing this page';

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  const module = {
    /**
     * Module name.
     */
    name: 'downloadSpecs',
    /**
     * Drupal environment flag.
     */
    drupal: !!window.Drupal && !!window.Drupal.behaviors,

    /**
     * Initialized flag.
     */
    init: false,
    /**
     * Drupal behavior to attach.
     */
    instance: {
      attach(context) {
        // Find our component within the DOM
        const $downloadSpecs = $('.download-specs', context);
        // Bail if component does not exist
        if (!$downloadSpecs.length) {
          return;
        }

        $downloadSpecs.click(function fn(e) {
          if ($(this).hasClass('disabled')) {
            e.preventDefault();
          }
        });

        const handler = (system) => {
          let [href] = $downloadSpecs.attr('href').split('?');
          const params = [];
          params.push(`measure_system=${system.current}`);
          const ids = $downloadSpecs.data('ids');
          if (ids) {
            params.push(`ids=${ids}`);
          }
          href = `${href}?${params.join('&')}`;
          $downloadSpecs.attr('href', href);
        };
        if (Drupal.behaviors.measurementSystemToggle) {
          Drupal.behaviors.measurementSystemToggle.onChange(handler);
        } else {
          document.addEventListener('MeasurementSystemToggleReady', function fn(
            e
          ) {
            e.detail.toggle.onChange(handler);
          });
        }

        document.addEventListener('AGGridFirstDataRendered', function fn(e) {
          const agGrid = Drupal.behaviors.agGrid.instance(e.detail.instanceId);
          if (!agGrid || agGrid.gridOptions.metadata.gridType !== 'standard') {
            return;
          }

          const rows = agGrid.gridOptions.api.rowModel.rowsToDisplay;

          if (rows.length > MAX_ROWS) {
            $downloadSpecs.attr('data-tooltip', MESSAGE).addClass('disabled');
          }
        });

        document.addEventListener('AGGridFilterChanged', function fn(e) {
          const agGrid = Drupal.behaviors.agGrid.instance(e.detail.instanceId);
          if (!agGrid || agGrid.gridOptions.metadata.gridType !== 'standard') {
            return;
          }

          const rows = agGrid.gridOptions.api.rowModel.rowsToDisplay;
          if (rows.length > MAX_ROWS) {
            $downloadSpecs.attr('data-tooltip', MESSAGE).addClass('disabled');
          } else {
            const ids = rows.map((r) => r.data.sku_id).join(',');
            let [href] = $downloadSpecs.attr('href').split('?');
            const params = [];
            if (Drupal.behaviors && Drupal.behaviors.measurementSystemToggle) {
              params.push(
                `measure_system=${Drupal.behaviors.measurementSystemToggle.currentSystem()}`
              );
            }
            params.push(`ids=${ids}`);
            href = `${href}?${params.join('&')}`;
            $downloadSpecs
              .attr('data-ids', ids)
              .attr('href', href)
              .removeAttr('data-tooltip')
              .removeClass('disabled');
          }

          /* eslint-disable */
          // Lazy-loading of the facet images on change of the AGGridFilters.
          var checkImagesInterval;

          function applyLazyLoading() {
            const lazyLoadInstance = new LazyLoad({
              elements_selector: '.lazy-facet-image',
              threshold: 0,
            });
          }

          function checkImagesFormed() {
            const lazyImages = document.querySelectorAll('.lazy-facet-image');
            let allImagesLoaded = true;

            // Handled the case when an image is removed on change of the filter.
            lazyImages.forEach(image => {
              // Check if the image's src attribute is different from the data-src.
              if (image.getAttribute('src') !== image.getAttribute('data-src')) {
                allImagesLoaded = false;
                image.setAttribute('src', image.getAttribute('data-src'));
              }
            });

            if (allImagesLoaded) {
              clearInterval(checkImagesInterval);
              applyLazyLoading();
            }
          }

          checkImagesInterval = setInterval(checkImagesFormed, 250);
          /* eslint-enable */
        });
      },
    },
  };

  if (!module.drupal) {
    window.Drupal = {
      behaviors: {},
    };
  }

  if (window.Drupal.behaviors[module.name] === undefined) {
    window.Drupal.behaviors[module.name] = module.instance;
  }

  if (module.drupal) {
    module.instance.attach($context[0], {});
  } else {
    window.onload = () => {
      console.log(`${module.name} loaded.`);
      module.instance.attach(document, {});
    };
  }
}

export default enable;
