import { h } from 'preact';
import { ComponentsTable } from './ComponentsTable';

export function ProductComponents({ t, closeHandler, index, products }) {
  const product = products[index];
  const region_manager = (window as any).drupalSettings.region_manager;
  const lang =
    region_manager.current_language + '-' + region_manager.current_country;
  return (
    <div class="product-components" id="product-components">
      <div class="product-components__header">
        <a class="product-components__close-button" onClick={closeHandler}>
          ×
        </a>
        <a class="product-components-anchor"></a>
        <h1 class="product-components__title">{`${product.name}`}</h1>
      </div>
      <ComponentsTable
        productList={product.components}
        t={t}
        product={product}
      />
      <p class="product-components__close-container">
        <p class="product-components__buttons-panel">
          <a
            href={`/${lang}${product.configuratorCopyURL}`}
            class="product-components__button"
          >
            {t('clone')}
          </a>
          <a
            href={`/${lang}${product.configuratorModifyURL}`}
            class="product-components__button"
          >
            {t('modify')}
          </a>
        </p>
        <a class="product-components__close-link" onClick={closeHandler}>
          {t('close')}
        </a>
      </p>
    </div>
  );
}
