
// Returns the Quantity options available according to MOQ (minimum order quantity) and DLS (delivery lot size).
function Quantity(price) {
  let DLS = parseInt(price.DLS);
  let MOQ = parseInt(price.MOQ);
  DLS = isNaN(DLS) ? 0 : DLS;
  MOQ = isNaN(MOQ) ? 0 : MOQ;
  let quantity, increment = 0;

  // If MOQ is greater than DLS, then start the quantity dropdown with the MOQ value and increase in multiples of DLS.
  if (MOQ > DLS) {
    quantity = MOQ;
    increment = DLS;
  }

  // If MOQ is less than or equal to DLS, then start the quantity dropdown with the DLS value and increase in multiples of DLS.
  if (MOQ <= DLS) {
    quantity = DLS;
    increment = DLS;
  }

  // If MOQ is greater than DLS and DLS is 0, then start the quantity dropdown the MOQ value and increase by 1.
  if (MOQ > DLS && DLS === 0) {
    quantity = MOQ;
    increment = 1;
  }

  // If DLS is less than or equal to 1 and MOQ is less than or equal to 1, then start the quantity dropdown with the MOQ value and increase by 1.
  if (DLS <= 1 && MOQ <= 1) {
    quantity = 1;
    increment = 1;
  }
  // If DLS is greater than 1 and MOQ is less than or equal to 1, then start the quantity dropdown with the DLS value and increase with multiples of DLS.
  if (DLS > 1 && MOQ <= 1) {
    quantity = DLS;
    increment = DLS;
  }

  let options = [];
  do {
    options.push(quantity);
    if (increment < 1) {
      break;
    }
    quantity += increment;
  } while (quantity <= 100);

  if(MOQ == 100) {
    options = [];
    quantity = 100;
    increment = 10;
    do {
      options.push(quantity);
      quantity += increment;
    } while (quantity <= 500);
  }

  return options;
}

export default Quantity;
