/**
 * product-category-vertical
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_product-category-vertical.scss';

// Module template
import './_product-category-vertical.twig';
import './product/_product.twig';
import './sku/_sku.twig';

export const name = 'product-category-vertical';

export const defaults = {
  dummyClass: 'js-product-category-vertical-card-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
export function disable() {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context, { productCategory = {} }) {
  // Find our component within the DOM
  const $productCategory = $('.product-category-vertical-card', $context);
  // Bail if component does not exist
  if (!$productCategory.length) {
    return;
  }

  $productCategory.each((i, el) => {
    if (!$(el).hasClass('product-category-vertical-card__expand--active')) {
      $(el).on('click', '.product-category-vertical-card__expand', () => {
        $(el)
          .find('.product-category-vertical-card__body')
          .toggleClass('display-all');
      });
      $(el).addClass('product-category-vertical-card__expand--active');
    }
  });

  // Merge defaults with incoming settings
  const settings = {
    ...defaults,
    ...productCategory,
  };
  // An example of what could be done with this component
  $productCategory.addClass(settings.dummyClass);
}

export default enable;
