/**
 * Templates
 */

// Module Dependencies
import 'protons';

// Module template
import './_default.twig';
import './_place-holder.twig';
import './full-width.twig';
import './with-sidebar.twig';

// Module styles
import './_place-holder.scss';

export const name = 'templates';

export function disable() {}

export function enable() {}

export default enable;
