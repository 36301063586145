/**
 * notification-banner
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_notification-banner.scss';

// Module template
import './_notification-banner.twig';

export const name = 'notification-banner';

export const defaults = {
  dummyClass: 'js-notification-banner-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  // Find our component within the DOM
  const $notificationBanner = $('.notification-banner', $context);
  const $desktopHeader = $('.header', $context);

  // Bail if component does not exist
  if (!$notificationBanner.length) {
    return;
  }

  const checkHeaderHeight = () => {
    let height;
    if (window.matchMedia('(min-width: 920px)').matches) {
      height = $desktopHeader.outerHeight();
    } else {
      height = 0;
    }
    return height;
  };

  $(window).on('resize', () => {
    $notificationBanner.css({
      top: checkHeaderHeight,
      maxWidth: $('body').width(),
    });
  });

  $(window).trigger('resize');
  $notificationBanner.each((i, el) => {
    const timeoutParent = $('.notifications-container').hasClass(
      'qty-update-message'
    )
      ? 1000
      : 4000;
    $(el).css({ maxWidth: $('body').width() });
    $(el).fadeIn();
    $('.loader-wrapper').removeClass('ajax-progress ajax-progress-fullscreen');
    $(el).on('click', '.notification-banner__close', () => {
      $(el).fadeOut();
      setTimeout(() => {
        $(el).remove();
      }, 1000);
    });
    setTimeout(() => {
      $(el).fadeOut('slow');
      $(el).parent().remove();
      setTimeout(() => {
        $('.notifications-container').removeClass('qty-update-message');
      }, 1000);
    }, timeoutParent);
  });
}

export default enable;
