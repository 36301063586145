/**
 * region-nav
 */

// Module dependencies
import 'protons';

// Module styles
import './_region-nav.scss';

// Module template
import './_region-nav.twig';

export const name = 'region-nav';

export const defaults = {
  dummyClass: 'js-region-nav-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  const ctx = document;
  const submenuToggles = ctx.getElementsByClassName(
    'js-region-toggle',
    $context
  );

  function toggleSubList(e) {
    e.stopPropagation();
    e.preventDefault();
    const el = e.currentTarget;
    const menuItem = el.parentElement.parentElement;

    if (menuItem.classList.contains('region-nav__current')) {
      menuItem.parentElement.classList.toggle('region-nav--open');
    } else {
      menuItem.classList.toggle('region-nav__region--open');
    }
  }

  // Expose sub menu on click.
  for (let i = 0; i < submenuToggles.length; i += 1) {
    submenuToggles[i].addEventListener('click', toggleSubList);
  }

  const regionNavToggle = ctx.getElementsByClassName('js-all-regions-toggle');
  for (let i = 0; i < regionNavToggle.length; i += 1) {
    // To-Do investigate why these listeners were bound more than once on some pages.
    if (!regionNavToggle[i].classList.contains('toggler')) {
      regionNavToggle[i].classList.add('toggler');
      regionNavToggle[i].addEventListener('click', () => {
        const regionNav = ctx.querySelector('.region-nav');
        regionNav.classList.toggle('region-nav--open');
        document.body.classList.toggle('js-scroll-lock');
      });
    }
  }
}

export default enable;
