/**
 * product-category-narratives
 */

// Module dependencies
import 'protons';

// Module styles
import './_product-category-narratives.scss';

// Module template
import './_product-category-narratives.twig';

export const name = 'product-category-narratives';

export function disable() {}

export function enable() {}

export default enable;
