/**
 * Card
 */

import $ from 'jquery';
import 'slick-carousel';

// Module dependencies
import 'protons';
import 'atoms/image';
import 'atoms/button';

// Module template
import './_card.twig';
import './card-horizontal/_card-horizontal.twig';
import './card-product/_product-detail-card.twig';
import './related-product-card/_related-product-card.twig';
import './card-catalog/_card-catalog.twig';
import './card-case-study/_card-case-study.twig';
import './card-nvent-softwares/_nvent-softwares-card.twig';
import './card-nvent-softwares/_nvent-softwares-view.twig';

// Module styles
import './_card.scss';
import './card-horizontal/_card-horizontal.scss';
import './card-product/_product-detail-card.scss';
import './related-product-card/_related-product-card.scss';
import './card-catalog/_card-catalog.scss';
import './card-nvent-softwares/_nvent-softwares-card.scss';

export const name = 'nvent-softwares-card';

export function disable() {}

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */

export function enable($context) {
  const $sliderContainer = $('.ns-rows .views-row');
  $sliderContainer.each(function () {
    const $currentSlider = $(this);
    $(
      $currentSlider.find('.ns-card__popup-slider .ps-image-wrapper'),
      $context
    ).slick({
      lazyLoad: 'ondemand',
      slidesToShow: 1,
      dots: true,
      arrows: true,
      prevArrow:
        '<a class="ps-prev ps-slide-arrow ps-carousel-handle" title="Previous" rel="nofollow"><span>❮</span></a>',
      nextArrow:
        '<a class="ps-next ps-slide-arrow ps-carousel-handle" title="Next" rel="nofollow"><span>❯</span></a>',
      appendArrows: $($(this).find('.ps-slick-slider-nav'), $context),
      appendDots: $($(this).find('.ps-slick-slider-dots'), $context),
      pauseOnFocus: false,
      pauseOnHover: false,
      infinite: true,
      accessibility: true,
      autoplay: true,
      autoplaySpeed: 4000,
      dotsClass: 'bullets',
      draggable: true,
      speed: 300,
      useTransform: false,
    });

    // Set infinite and autoplay setting true after 10 second.
    setTimeout(function () {
      $currentSlider
        .find('.slider-container')
        .slick('slickSetOption', 'infinite', true, true);
      $currentSlider
        .find('.slider-container')
        .slick('slickSetOption', 'autoplay', true, true);
    }, 10000);
  });

  $('.ps-image-slider')
    .find('.ps-activity-handle')
    .on('click', function () {
      if ($(this).hasClass('ps-pause-slide')) {
        $(this)
          .parent('.ps-slick-slider-dots')
          .parent('.ps-image-slider')
          .find('.ps-image-wrapper')
          .slick('slickPause');
        $(this).removeClass('ps-pause-slide');
      } else {
        $(this).addClass('ps-pause-slide');
        $(this)
          .parent('.ps-slick-slider-dots')
          .parent('.ps-image-slider')
          .find('.ps-image-wrapper')
          .slick('slickPlay');
      }
    });

  $('.ns-rows .views-row').each(function () {
    $(this)
      .find('#openDialog')
      .click(function (e) {
        e.preventDefault();
        $(this).siblings('.ns-card__popup').show();
      });
  });

  $('.ns-rows .views-row').each(function () {
    const dialog = $(this).find('#dialog');
    dialog.click(function (e) {
      if ($(e.target).is(dialog)) {
        dialog.hide();
      }
    });
  });

  $('.ns-filters legend').click(function (e) {
    e.stopPropagation();
    $('.ns-filters .form-item--checkboxes').toggle();
    $('.ns-filters').toggleClass('open');
  });

  $('.ns-rows .views-row').each(function () {
    $(this)
      .find('.close-button')
      .on('click', function () {
        $('.ns-card__popup').hide();
      });
  });

  $(document).click(function (e) {
    if (!$(e.target).closest('.ns-filters .fieldgroup').length) {
      $('.ns-filters .form-item--checkboxes').hide();
      $('.ns-filters').removeClass('open');
    }
    const $select = $('.ns-filters .form-item__dropdown select');
    if (!$(e.target).closest($select).length) {
      $select.blur();
    }
  });

  const $select = $('.ns-filters select');
  $select.on('focus', function () {
    $(this).closest('.form-item__dropdown').addClass('open');
  });

  $select.on('blur', function () {
    $(this).closest('.form-item__dropdown').removeClass('open');
  });

  $select.on('change', function () {
    $(this).closest('.form-item__dropdown').removeClass('open');
  });

  $('.ns-filters #close-facets').click(function () {
    $('.ns-filters').hide();
  });

  $('.ns-filter-button').click(function () {
    $('.ns-filters').show();
  });
}
export default enable;
